import {useMutation} from '@apollo/react-hooks'
import {
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core'
import DOMPurify from 'dompurify'
import React, {useState} from 'react'
import {DELETE_QUESTION_MICROLEARNING} from '../../../../../../graphql/mutations/microlearning'
import DeletePopup from '../../../../dashboard/shared-components/DeletePopup'
import CreateQuestion from './CreateQuestion'

export default function ManageQuestion({
  style,
  data,
  microlearningId,
  refetch,
  isError,
}) {
  const [openAdd, setOpenAdd] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState(null)

  const [mutationDeleteQuestion] = useMutation(DELETE_QUESTION_MICROLEARNING)

  const handleOpenAdd = () => {
    setOpenAdd(true)
  }

  const handleEditDelete = (e, isEdit, val) => {
    e.stopPropagation()
    setSelected(val)
    if (isEdit) {
      setOpenAdd(true)
    } else {
      setOpenDelete(true)
    }
  }

  const onDelete = () => {
    mutationDeleteQuestion({
      variables: {question_id: selected.id},
      context: {
        headers: {
          'X-Hasura-Role': 'organization-staff',
        },
      },
    })
      .then(() => {
        refetch()
      })
      .catch(e => e)
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        className="normal-case mb-28"
        onClick={handleOpenAdd}
      >
        Tambahkan Pertanyaan
      </Button>
      {isError && data.length === 0 && (
        <Typography style={{color: 'red'}}>
          Tolong tambahkan soal dan jawaban
        </Typography>
      )}
      {openAdd && (
        <CreateQuestion
          microlearningId={microlearningId}
          style={style}
          setOpenAdd={setOpenAdd}
          refetch={refetch}
          selected={selected}
          setSelected={setSelected}
        />
      )}
      {data &&
        data.map((res, i) => (
          <ExpansionPanel key={i} style={style.expansion}>
            <ExpansionPanelSummary classes={{content: 'items-center'}}>
              {/* <Icon style={style.grey} className="mr-16">
                menu
              </Icon> */}
              <div
                className="flex justify-between w-full items-center"
                style={{paddingRight: 0}}
              >
                <div className="flex items-center w-full">
                  <Typography
                    className="font-semibold mr-12"
                    style={{width: 100}}
                  >
                    Pertanyaan {i + 1} :
                  </Typography>
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(res.question),
                    }}
                  ></div>
                </div>
                <div className="flex">
                  <IconButton
                    className="mr-6"
                    onClick={e => handleEditDelete(e, true, res)}
                  >
                    <Icon style={style.grey}>create</Icon>
                  </IconButton>
                  <IconButton onClick={e => handleEditDelete(e, false, res)}>
                    <Icon style={style.grey}>delete</Icon>
                  </IconButton>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="block">
              {res.microlearning_question_answers.map(opt => {
                return res.question_type === 0 ? (
                  <div className="flex items-center ml-12" key={opt.id}>
                    <Radio checked={opt.is_correct} />
                    <Typography>{opt.answer}</Typography>
                  </div>
                ) : (
                  <div className="flex items-center ml-12">
                    <Checkbox checked={opt.is_correct} />
                    <Typography>{opt.answer}</Typography>
                  </div>
                )
              })}
              {/* untuk short answer onhold */}
              {/* <TextField
                variant="outlined"
                multiline
                rows="4"
                fullWidth
                value="this is the answer"
                className="bg-white"
              /> */}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={onDelete}
        name="question"
      />
    </>
  )
}
