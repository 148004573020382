import React from 'react'
import {
  AppBar,
  MuiThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core'
import connect from 'react-redux/es/connect/connect'
import {NavLink, withRouter} from 'react-router-dom'
import _ from 'lodash'
import history from 'history.js'
import global from 'app/global-variable'

import {TabSeeAll} from 'app/main/learnings/dashboard/search/search-tab'
import {TabSeeAllMyLearning} from 'app/main/learnings/dashboard/my-learning/tab'
import {LearningContributorTabs} from 'app/main/learnings/dashboard/learning-contributor/tabs/LearningContributorTabs'
import {WishlistTab} from 'app/main/learnings/wishlist/components/WishlistTab'

const styles = (theme) => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
  hoverAdjustment: {
    zIndex: 1,
  },
})

const SubToolbarLayout2 = ({classes, settings, toolbarTheme}) => {
  const userData = global.data_user
  const location = history.location.pathname
  let pathname = ''

  if (location.split('/')[1] === 'learning-contributor') {
    pathname = 'learning-contributor'
  } else {
    pathname = location
  }

  let btnInstructor = (
    <NavLink to="/instructor/become" style={{color: 'black'}}>
      Become Learning Contributor
    </NavLink>
  )

  const _renderComponent = (active) => {
    switch (active) {
      case '/learning-profile':
        return (
          <div className="flex flex-row w-full ">
            <Typography className="font-600 text-center w-full">
              Learning Profile
            </Typography>
          </div>
        )
      case '/competence-dictionary':
        return (
          <div className="flex flex-row w-full ">
            <Typography className="font-600 text-center w-full">
              Competence
            </Typography>
          </div>
        )
      default:
        return (
          <div className="flex flex-row">
            <Typography style={{fontWeight: 'bold'}} className="mr-16">
              {btnInstructor}
            </Typography>
            <Typography style={{fontWeight: 'bold'}}>
              <NavLink
                to={{
                  pathname: '/my-learning/courses',
                  /* search: '?tab=0',
                  state: {tab: 0}, */
                }}
                style={{color: 'black'}}
              >
                My Learning
              </NavLink>
            </Typography>
          </div>
        )
    }
  }

  if (_.isEmpty(userData)) {
  } else {
    const findRole = userData.roles.find((role) => role === 'instructor')
    if (findRole) {
      btnInstructor = (
        <NavLink to="/instructor/dashboard" style={{color: 'black'}}>
          Learning Contributor Dashboard
        </NavLink>
      )
    } else {
    }
  }

  return (
    <div className={classes.hoverAdjustment}>
      <MuiThemeProvider theme={toolbarTheme}>
        <AppBar
          id="fuse-toolbar"
          className={
            pathname.split('/')[1] === 'tabs-all' ||
            pathname.split('/')[1] === 'my-learning' ||
            pathname.split('/')[1] === 'wishlist' ||
            pathname === 'learning-contributor'
              ? 'flex items-center justify-right relative z-10 px-160'
              : 'flex items-end justify-right relative z-10 px-160 pt-12 pb-12'
          }
          style={{backgroundColor: '#f6f8f9'}}
        >
          {pathname === '/learning-profile' ? (
            <div className="flex flex-row w-full ">
              <Typography className="font-600 text-center w-full">
                Learning Profile
              </Typography>
            </div>
          ) : pathname.split('/')[1] === 'micro-learning' ||
            pathname.split('/')[1] === 'daily-question' ? (
            <div className="flex flex-row w-full ">
              <Typography className="font-600 text-center w-full">
                Micro Learning
              </Typography>
            </div>
          ) : pathname.split('/')[1] === 'tabs-all' ? (
            <TabSeeAll />
          ) : pathname === 'learning-contributor' ? (
            <LearningContributorTabs />
          ) : pathname.split('/')[1] === 'my-learning' ? (
            <TabSeeAllMyLearning />
          ) : pathname.split('/')[1] === 'wishlist' ? (
            <WishlistTab />
          ) : (
            <div className="flex flex-row">
              {/* <Typography style={{fontWeight: 'bold', cursor: 'pointer'}} onClick={()=>window.location.href = '/ranking'}>
                 <NavLink
                  to={{
                    pathname: '/ranking',                    
                  }}
                  style={{color: 'black'}}
                > 
                  Ranking
                </NavLink> 
              </Typography>
              <Typography
                style={{marginRight: 15, marginLeft: 15}}
                className="mr-16"
              >
                |
              </Typography> */}
              <Typography style={{fontWeight: 'bold'}} className="mr-16">
                {btnInstructor}
              </Typography>
              <Typography style={{fontWeight: 'bold'}}>
                <NavLink
                  to={{
                    pathname: '/my-learning/courses',
                  }}
                  style={{color: 'black'}}
                >
                  My Learning
                </NavLink>
              </Typography>
            </div>
          )}
        </AppBar>
      </MuiThemeProvider>
    </div>
  )
}

function mapStateToProps({fuse}) {
  return {
    settings: fuse.settings.current,
    toolbarTheme: fuse.settings.toolbarTheme,
  }
}

export default withStyles(styles, {withTheme: true})(
  withRouter(connect(mapStateToProps)(SubToolbarLayout2))
)
