import React from 'react'
import {
  AppBar,
  Hidden,
  MuiThemeProvider,
  Toolbar,
  withStyles,
} from '@material-ui/core'
import {FuseShortcutsTimelineLearning} from '@fuse'
import connect from 'react-redux/es/connect/connect'
import {withRouter} from 'react-router-dom'
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton'
// import UserMenu from 'app/fuse-layouts/shared-components/UserMenu'
import ToolbarMenuSet2 from 'app/fuse-layouts/shared-components/ToolbarMenuSet2'

const styles = (theme) => ({
  separator: {
    width: 1,
    height: 40,
    backgroundColor: theme.palette.divider,
  },
  toolbar: {
    padding: 10,
  },
})

const ToolbarLayout2 = ({classes, settings, toolbarTheme}) => {
  const layoutConfig = settings.layout.config

  return (
    <MuiThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className="flex relative z-10 h-60 p-0"
        style={{backgroundColor: '#ffffff'}}
        elevation={0}
      >
        <Toolbar>
          {layoutConfig.navbar.display &&
            layoutConfig.navbar.position === 'left' && (
              <Hidden lgUp>
                <NavbarMobileToggleButton className="w-64 h-60 p-0" />
                <div className={classes.separator} />
              </Hidden>
            )}

          <div className="flex flex-1">
            {/* <Hidden mdDown> */}
            <FuseShortcutsTimelineLearning className="px-16 py-12 ml-40" />
            {/* </Hidden> */}
          </div>

          <div className="flex flex-1 justify-end content-end items-end w-full">
            <ToolbarMenuSet2 />

            {/* <UserMenu /> */}

            {/* <Hidden lgUp> */}

            {/* <div className={classes.separator}/> */}

            {/* <ChatPanelToggleButton/> */}
            {/* </Hidden> */}

            {/* <div className={classes.separator}/> */}

            {/* <QuickPanelToggleButton/> */}
          </div>

          {layoutConfig.navbar.display &&
            layoutConfig.navbar.position === 'right' && (
              <Hidden lgUp>
                <NavbarMobileToggleButton />
              </Hidden>
            )}
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  )
}

function mapStateToProps({fuse}) {
  return {
    settings: fuse.settings.current,
    toolbarTheme: fuse.settings.toolbarTheme,
  }
}

export default withStyles(styles, {withTheme: true})(
  withRouter(connect(mapStateToProps)(ToolbarLayout2))
)
