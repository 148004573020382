import * as Actions from '../actions'

const initialState = {
  event: null,
  events: [],
  events_nearby: null,
}

const eventReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_EVENTS: {
      return {
        ...state,
        events: action.payload,
      }
    }
    case Actions.GET_ALL_EVENTS_NEARBY: {
      return {
        ...state,
        events_nearby: action.payload,
      }
    }
    case Actions.GET_EVENT_DETAIL: {
      return {
        ...state,
        event: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default eventReducer
