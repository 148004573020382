import gql from 'graphql-tag'

export const GET_DETAIL_CROSS_MENTORING = gql`
  query($userId: uuid!, $offset: Int, $limit: Int) {
    global_users(where: {id: {_eq: $userId}}) {
      id
      name
      learning_contributor_ratings {
        total_rating_avg
        total_catalog_count
        total_rating_count
        total_learner_count
      }
      avatar
      contributor_label
      learning_contributor_ratings {
        total_rating_avg
        total_catalog_count
        total_rating_count
        total_learner_count
        classroom_rating_avg
        classroom_rating_count
        classroom_learner_count
        classroom_count
        courses_count
        courses_rating_avg
        courses_rating_count
        course_learner_count
        books_count
        book_rating_avg
        book_rating_count
        book_learner_count
      }
      people_work_placements {
        company_profile {
          brand_name
          legal_name
        }
        company_job_profile {
          position
          title
        }
      }
      classrooms_aggregate(
        where: {classroom_members: {is_admin: {_eq: true}}}
      ) {
        nodes {
          classroom_reviews_aggregate {
            aggregate {
              avg {
                star
              }
              count
            }
          }
        }
      }
      classrooms(
        where: {classroom_members: {is_admin: {_eq: true}}}
        offset: $offset
        limit: $limit
      ) {
        id
        avatar_url
        name
        description
        price
        rules
        academy_course_category {
          code
          name
        }
        global_user {
          avatar
          id
          name
        }
        classroom_reviews_aggregate {
          aggregate {
            avg {
              star
            }
          }
        }
      }
    }
  }
`
