import * as Actions from '../actions'

const initialState = {
  courseById: null,
  courseBySlug: '',
  data: '',
  quiz: '',
  priceTiers: null,
  isSave: false,
  dataPractice: [],
  dataEvent: '',
  dataBook: '',
  questions: [],
  lesson: null,

  courseReview: [],
  courseDiscussion: null,

  courseStudent: null,
  quizSubmission: null,
  quizSubmissionByLessonIdStudentId: [],
}

const courseReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COURSE: {
      return {
        ...action.payload,
      }
    }
    case Actions.GET_COURSE_BY_ID: {
      return {
        ...state,
        courseById: action.payload,
      }
    }
    case Actions.GET_COURSE_BY_SLUG: {
      return {
        ...state,
        courseBySlug: action.payload,
      }
    }
    case Actions.GET_COURSE_REVIEW: {
      return {
        ...state,
        courseReview: action.payload,
      }
    }
    case Actions.GET_COURSE_DISCUSSION: {
      return {
        ...state,
        courseDiscussion: action.payload,
      }
    }
    case Actions.GET_COURSE_STUDENT: {
      return {
        ...state,
        courseStudent: action.payload,
      }
    }
    case Actions.GET_LESSON_BY_ID: {
      return {
        ...state,
        lesson: action.payload,
      }
    }
    case Actions.GET_QUESTIONS_BY_LESSON_ID: {
      return {
        ...state,
        questions: action.payload,
      }
    }
    case Actions.GET_QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID: {
      return {
        ...state,
        quizSubmissionByLessonIdStudentId: action.payload,
      }
    }
    case Actions.SAVE_COURSE: {
      return {
        ...action.payload,
      }
    }
    case Actions.SAVE_GRADING_MANUAL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case Actions.GET_WIDGETS:
      return {
        ...state,
        data: action.payload,
      }
    case Actions.GET_DASHBOARD_STATISTICS:
      return {
        ...state,
        statistics: {...action.payload},
      }

    case Actions.UPDATE_COURSE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case Actions.UPDATE_COURSE_PROGRESS: {
      return {
        //...action.payload
        update_course_progress: action.payload,
      }
    }
    case Actions.GET_QUIZ:
      return {
        ...state,
        quiz: action.payload.data,
      }
    case Actions.GET_SUBMISSION_QUIZ:
      return {
        ...state,
        quizSubmission: action.payload,
      }
    case Actions.SAVE_QUIZ:
      return {
        ...state,
      }
    case Actions.UPDATE_QUIZ_QUESTION:
      return {
        ...state,
      }
    case Actions.REMOVE_QUIZ:
      return {
        ...state,
      }
    case Actions.SAVE_MANAGE_PRACTICE:
      return {
        ...state,
        isSave: true,
      }
    case Actions.SAVE_MANAGE_EVENT:
      return {
        ...state,
      }
    case Actions.DELETE_EVENT:
      return {
        ...state,
      }
    case Actions.SET_DATA_PRACTICE:
      return {
        ...state,
        dataPractice: action.payload,
      }
    case Actions.SET_DATA_EVENT:
      return {
        ...state,
        dataEvent: action.payload,
      }
    case Actions.SET_DATA_BOOK:
      return {
        ...state,
        dataBook: action.payload,
      }
    default: {
      return state
    }
  }
}

export default courseReducer
