import {
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {selectProps} from '../../../../../../utils/helpers'
import {useMutation} from '@apollo/react-hooks'
import {
  ADD_QUESTION_MICROLEARNING,
  UPDATE_QUESTION_MICROLEARNING,
} from '../../../../../../graphql/mutations/microlearning'
import DOMPurify from 'dompurify'
import ValidatedTextField from '../../../shared-components/global/ValidatedTextField'

const editorConfiguration = {
  placeholder: 'Tambah Konten',
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

export default function CreateQuestion({
  refetch,
  style,
  setOpenAdd,
  microlearningId,
  selected,
  setSelected,
}) {
  const initialStateAnswer = [
    {
      answer: '',
      is_correct: true,
    },
    {
      answer: '',
      is_correct: false,
    },
    {
      answer: '',
      is_correct: false,
    },
    {
      answer: '',
      is_correct: false,
    },
  ]
  const [question, setQuestion] = useState('')
  const [questionType, setType] = useState('')
  const [stateAnswer, setAnswer] = useState(initialStateAnswer)

  useEffect(() => {
    // logic untuk fill data edit
    if (selected) {
      setQuestion(selected.question)
      setType(selected.question_type === 0 ? 'mChoice' : 'mAnswer')
      setAnswer(selected.microlearning_question_answers)
    }
  }, [selected])

  const handleType = e => {
    setType(e.target.value)
    setAnswer(initialStateAnswer)
  }

  const handleMoreAnswer = () => {
    setAnswer([
      ...stateAnswer,
      {
        answer: '',
        is_correct: false,
      },
    ])
  }

  const handleChangeAnswer = (e, i) => {
    stateAnswer[i].answer = e.target.value
    setAnswer([...stateAnswer])
  }

  const handleRadio = newCorrectIndex => {
    const prevCorrectIndex = stateAnswer.findIndex(i => i.is_correct)
    if (prevCorrectIndex !== -1) {
      stateAnswer[prevCorrectIndex].is_correct = false
    }
    stateAnswer[newCorrectIndex].is_correct = true
    setAnswer([...stateAnswer])
  }

  const onCancel = () => {
    setOpenAdd(false)
    setAnswer(initialStateAnswer)
    setSelected(null)
  }

  const handleCheckbox = (e, i) => {
    stateAnswer[i].is_correct = e.target.checked
    setAnswer([...stateAnswer])
  }

  const [mutationAddQuestion] = useMutation(ADD_QUESTION_MICROLEARNING)
  const [mutationEditQuestion] = useMutation(UPDATE_QUESTION_MICROLEARNING)

  const onSave = () => {
    if (selected) {
      const objects = {
        question_id: selected.id,
        question,
        questionType: questionType === 'mChoice' ? 0 : 1,
        answers: stateAnswer.map(res => {
          delete res.__typename
          return {
            ...res,
            microlearning_question_id: selected.id,
          }
        }),
      }

      mutationEditQuestion({
        variables: objects,
        context: {
          headers: {
            'X-Hasura-Role': 'organization-staff',
          },
        },
      })
        .then(() => {
          onCancel()
          refetch()
        })
        .catch(() => {})
    } else {
      const objects = [
        {
          question,
          microlearning_id: microlearningId,
          question_type: questionType === 'mChoice' ? 0 : 1,
          microlearning_question_answers: {
            data: stateAnswer,
          },
        },
      ]

      mutationAddQuestion({
        variables: {
          objects,
        },
        context: {
          headers: {
            'X-Hasura-Role': 'organization-staff',
          },
        },
      })
        .then(() => {
          onCancel()
          refetch()
        })
        .catch(() => {})
    }
  }

  const renderAnswer = () => (
    <div className="mb-6">
      <Typography variant="body2" className="mb-16">
        Jawaban
      </Typography>

      {questionType === 'mChoice' ? (
        stateAnswer.map((ans, i) => (
          <div
            key={i}
            className="flex items-center bg-white p-6 mb-16"
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 5,
            }}
          >
            <Radio checked={ans.is_correct} onChange={() => handleRadio(i)} />
            <ValidatedTextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah Jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            />
          </div>
        ))
      ) : questionType === 'mAnswer' ? (
        stateAnswer.map((ans, i) => (
          <div
            key={i}
            className="flex items-center bg-white p-6 mb-16"
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 5,
            }}
          >
            <Checkbox
              checked={ans.is_correct}
              onChange={e => handleCheckbox(e, i)}
            />
            <ValidatedTextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah Jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            />
          </div>
        ))
      ) : questionType === 'sAnswer' ? (
        <ValidatedTextField
          variant="outlined"
          multiline
          rows="4"
          fullWidth
          value="this is the answer"
          className="bg-white mb-16"
        />
      ) : null}
      {stateAnswer.length < 5 && (
        <Typography
          variant="body2"
          style={{color: '#039BE5', cursor: 'pointer'}}
          onClick={handleMoreAnswer}
        >
          + Tambah jawaban lain
        </Typography>
      )}
    </div>
  )

  const disabledButton =
    !questionType ||
    !question ||
    stateAnswer.some(s => !s.answer) ||
    stateAnswer.every(s => !s.is_correct)

  return (
    <Paper style={style.expansion} className="p-24">
      <Typography className="font-semibold mb-20">
        {selected ? 'Ubah' : 'Tambahkan'} Pertanyaan 
      </Typography>
      <div className="mb-20 w-1/3">
        <Typography variant="body2">Jenis Pertanyaan</Typography>

        <TextField
          variant="outlined"
          value={questionType}
          onChange={handleType}
          fullWidth
          margin="normal"
          select
          className="bg-white"
          SelectProps={selectProps(questionType)}
        >
          <MenuItem value="" disabled>
            Pilih Jenis
          </MenuItem>
          <MenuItem value="mChoice">Pilihan Ganda</MenuItem>
          <MenuItem value="mAnswer">Jawaban Ganda</MenuItem>
          {/* <MenuItem value="sAnswer">Short Answer</MenuItem> */}
        </TextField>
      </div>
      <div className="mb-20">
        <Typography variant="body2" className="mb-16">
          Pertanyaan
        </Typography>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={DOMPurify.sanitize(question)}
          // eslint-disable-next-line
          onChange={(event, editor) => {
            const data = editor.getData()
            setQuestion(data)
          }}
        />
        <div style={style.customEditorFooter} />
      </div>
      {questionType && renderAnswer()}
      <div className="flex justify-end">
        <Button size="large" className="normal-case mr-12" onClick={onCancel}>
          Batal
        </Button>
        <Button
          size="large"
          variant="contained"
          className="normal-case"
          color="secondary"
          onClick={onSave}
          disabled={disabledButton}
        >
          {selected ? 'Ubah' : 'Simpan'}
        </Button>
      </div>
    </Paper>
  )
}
