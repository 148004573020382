import React from 'react'
import {
  Avatar,
  Paper,
  Typography,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {LEARNING_CONTRIBUTOR_LIST_COMMENTS} from 'app/graphql/queries/index'
import moment from 'moment'

const styles = {
  skyBlueColor: {
    color: '#039be5',
  },
  viewMore: {
    color: '#014a62',
    borderColor: '#014a62',
    width: '50%',
    height: '4rem',
    fontWeight: 'bold',
  },
}

const CommentComponent = props => {
  const {
    data: listComments,
    loading: loadingComments,
    error: errorComments,
  } = useQuery(LEARNING_CONTRIBUTOR_LIST_COMMENTS, {
    variables: {
      offset: 0,
      limit: 10,
      microlearningId: props.location.state.microLearning.id,
    },
  })

  if (loadingComments) {
    return <CircularProgress />
  } else if (errorComments) {
    return <div>{JSON.stringify(errorComments)}</div>
  }

  return (
    <Paper className="w-full" style={{minHeight: '70vh'}}>
      <Typography
        className="my-20 mx-16 font-bold text-blue-dark text-16"
        style={styles}
      >
        Komentar
      </Typography>
      <Divider />
      <div>
        {listComments.microlearning_comments.map((comment, i) => (
          <div key={i} className="px-16 flex flex-col">
            <div className="flex flex-1 flex-row p-8 py-20 px-16">
              <Avatar
                src={
                  comment.global_user.avatar ||
                  'assets/images/courses/course-default-thumbnail.jpg'
                }
                className="w-48 h-48"
              />
              <div className="flex flex-col ml-16">
                <Typography>{comment.global_user.name}</Typography>
                {/* <Typography className="text-grey">Product Owner</Typography> */}
              </div>
              <div className="flex flex-col w-full items-end">
                <Typography className="text-grey mt-10 ml-16">
                  {moment(comment.date_added).fromNow()}
                </Typography>
              </div>
            </div>
            <Typography className="my-24">{comment.content}</Typography>
          </div>
        ))}
      </div>
    </Paper>
  )
}

export default CommentComponent
