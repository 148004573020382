import gql from 'graphql-tag'

export const GET_RADAR_DATA = gql`
  query($position: Int) {
    competency_categories(
      where: {
        competency_dictionaries: {
          competency_version_dictionaries: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {id: {_eq: $position}}
            }
          }
        }
      }
    ) {
      id
      name
      date_added
      competency_dictionaries(
        where: {
          competency_version_dictionaries: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {id: {_eq: $position}}
            }
          }
        }
      ) {
        competency_version_dictionaries(
          where: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {id: {_eq: $position}}
            }
          }
        ) {
          competency_model_items_aggregate(
            where: {
              competency_version_dictionary: {
                competency_version: {status: {_eq: "ACTIVE"}}
                competency_model_items: {
                  company_job_profile: {id: {_eq: $position}}
                }
              }
            }
          ) {
            aggregate {
              avg {
                level
              }
            }
          }
          competency_individuals_aggregate(
            where: {
              competency_version_dictionary: {
                competency_version: {status: {_eq: "ACTIVE"}}
                competency_model_items: {
                  company_job_profile: {id: {_eq: $position}}
                }
              }
            }
          ) {
            aggregate {
              avg {
                level
              }
            }
          }
        }
      }
    }
  }
`

export const GET_DICTIONARY_DATA = gql`
  query($placement: Int) {
    competency_categories(
      where: {
        competency_dictionaries: {
          competency_version_dictionaries: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {
                people_work_placements: {id: {_eq: $placement}}
              }
            }
          }
        }
      }
    ) {
      id
      name
      competency_dictionaries(
        where: {
          competency_version_dictionaries: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {
                people_work_placements: {id: {_eq: $placement}}
              }
            }
          }
        }
      ) {
        name
        description
        date_added
        competency_version_dictionaries(
          where: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {
                people_work_placements: {id: {_eq: $placement}}
              }
            }
          }
        ) {
          competency_model_items_aggregate(
            where: {
              competency_version_dictionary: {
                competency_version: {status: {_eq: "ACTIVE"}}
                competency_model_items: {
                  company_job_profile: {
                    people_work_placements: {id: {_eq: $placement}}
                  }
                }
              }
            }
          ) {
            aggregate {
              avg {
                level
              }
            }
          }
          competency_individuals_aggregate(
            where: {
              competency_version_dictionary: {
                competency_version: {status: {_eq: "ACTIVE"}}
                competency_model_items: {
                  company_job_profile: {
                    people_work_placements: {id: {_eq: $placement}}
                  }
                }
              }
            }
          ) {
            aggregate {
              avg {
                level
              }
            }
          }
        }
      }
    }
  }
`
