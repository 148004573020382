import axios from 'axios'
import global from 'app/global-variable'
import history from 'history.js'
import _ from '@lodash'

export const GET_ALL_EVENTS = '[ACADEMY APP] GET ALL EVENTS'
export const GET_ALL_EVENTS_NEARBY = '[ACADEMY APP] GET ALL EVENTS NEARBY'
export const GET_EVENT_DETAIL = '[ACADEMY APP] GET EVENT DETAIL'

const userData = global.data_user

export function getEvents(limit, page) {
  console.log('page', page)
  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  const request = axios.get(
    global.api.dev + '/event/schedule/get?limit=' + limit + '&page=' + page,
    {
      headers: {
        Authorization: 'Bearer ' + userData.oauth.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_ALL_EVENTS,
        payload: response.data,
      })
    )
}

export function getEventsNearby(limit, page, latitude, longitude) {
  /* console.log('getEventsNearby latitude', latitude)
  console.log(' getEventsNearbylongitude', longitude) */
  console.log('page', page)
  const request = axios.get(
    global.api.dev +
      '/event/schedule/get?lng=' +
      longitude +
      '&lat=' +
      latitude +
      '&orders[0][0]=distance&limit=' +
      limit +
      '&page=' +
      page,
    {
      headers: {
        Authorization: 'Bearer ' + userData.oauth.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: GET_ALL_EVENTS_NEARBY,
        payload: response.data,
      })
    })
}

export function getEventById(eventId) {
  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  const request = axios.get(global.api.dev + '/event/schedule/' + eventId, {
    headers: {
      Authorization: 'Bearer ' + userData.oauth.access_token,
    },
    withCredentials:true,
  })

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_DETAIL,
        payload: response.data,
      })
    )
}

export function getEventBySlug(eventSlug) {
  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  const request = axios.get(
    global.api.dev + '/event/schedule/get/' + eventSlug,
    {
      headers: {
        Authorization: 'Bearer ' + userData.oauth.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_DETAIL,
        payload: response.data,
      })
    )
}
