import React, {useState, useEffect} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Tabs, Tab} from '@material-ui/core'
import {Link} from 'react-router-dom'

const styles = () => ({
  layoutRoot: {},
  tabsIndicator: {
    backgroundColor: '#38aae6',
  },
})

const TabSeeAllMyLearning = (props) => {
  const {classes} = props
  const path = window.location.pathname.split('/')[2]

  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    switch (path) {
      case 'courses':
        return setTabValue(0)
      // pracitce test di hide
      // case 'practice-test':
      //   return setTabValue(1)
      // case 'book':
      //   return setTabValue(2)
      // case 'social-learning':
      //   return setTabValue(3)

      case 'book':
        return setTabValue(1)
      case 'social-learning':
        return setTabValue(2)
      case 'micro-learning':
        return setTabValue(3)
      default:
        setTabValue(0)
    }
  }, [path, tabValue])

  const handleChange = (value) => {
    setTabValue(value)
  }

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        classes={{indicator: classes.tabsIndicator}}
        centered
      >
        <Tab
          label="Course"
          component={Link}
          to="/my-learning/courses"
          className="capitalize"
        />
        {/* <Tab
          label="Practice Test"
          component={Link}
          to="/my-learning/practice-test"
          className="capitalize"
        /> */}
        <Tab
          label="Book"
          component={Link}
          to="/my-learning/book"
          className="capitalize"
        />
        <Tab
          label="Social Learning"
          component={Link}
          to="/my-learning/social-learning"
          className="capitalize"
        />
        <Tab
          label="Micro Learning"
          component={Link}
          to="/my-learning/micro-learning"
          className="capitalize"
        />
      </Tabs>
    </div>
  )
}

export default withStyles(styles, {withTheme: true})(TabSeeAllMyLearning)
