import React, {Component} from 'react'
import {
  Avatar,
  Button,
  Card,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import {connect} from 'react-redux'
import * as authActions from 'app/auth/store/actions'
import {bindActionCreators} from 'redux'
import {Link} from 'react-router-dom'
import reducer from 'app/auth/store/reducers'
import withReducer from 'app/store/withReducer'
import {withRouter} from 'react-router-dom'
import ReactHover from 'react-hover'
import '../../../styles/menu.css'
import _ from '@lodash'
import history from 'history.js'
import {createCookie, convertToRupiah} from 'app/utils/helpers'
import global from 'app/global-variable'

const options = {
  followCursor: false,
}

const profileStyle = {
  width: '24px',
  height: '24px',
}

class UserMenu extends Component {
  state = {
    userMenu: null, // to handle drop down user menu
    userData: global.data_user, // get from cookie
    userDataRedux: null, // data from user.student
    user: null, // props.user response
  }

  userMenuClick = (event) => {
    this.setState({userMenu: event.currentTarget})
  }

  userMenuClose = () => {
    createCookie('userData', '', -1)
    setTimeout(() => {
      window.location = global.sso_url
    }, 500)
  }

  componentDidMount() {
    this.props.getStudent()
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.user && !state.user) {
      props.getStudent()
    } else {
      return {
        user: props.user,
        userDataRedux: props.user.student,
      }
    }
  }

  hcAdmin = () => {
    window.open(global.hc_dashboard_url)
  }

  render() {
    const {userDataRedux, user} = this.state
    const {avatar} = global && global.data_user

    if (!user) {
      return <CircularProgress />
    }
    return (
      <React.Fragment>
        <ReactHover options={options}>
          <ReactHover.Trigger type="trigger">
            {user.student ? (
              user.student.avatar !== '' ? (
                <Avatar
                  style={profileStyle}
                  className="flex flex-row"
                  alt={user.student.name}
                  src={user.student.avatar || avatar}
                />
              ) : (
                <Avatar
                  style={profileStyle}
                  className="flex flex-row"
                  alt="user photo"
                  src={avatar || 'assets/images/avatars/profile.jpg'}
                />
              )
            ) : (
              <Avatar
                style={profileStyle}
                className="flex flex-row"
                alt="user photo"
                src={avatar || 'assets/images/avatars/profile.jpg'}
              />
            )}
            {/* </Button> */}
          </ReactHover.Trigger>
          <ReactHover.Hover
            type="hover"
            className="w-288 h-288"
            style={{transform: 'translate(-80%, -3%)'}}
          >
            <Card style={{transform: 'translate(-80%, -3%)'}}>
              <div>
                <MenuItem
                  component={Link}
                  to={{
                    pathname: '/learning-profile',
                    state: {
                      active: 'learning-profile',
                    },
                  }}
                  className="h-68 w-288"
                >
                  <div className="flex flex-row">
                    {user.student ? (
                      <div className="flex flex-row justify-between w-216">
                        <div className="flex flex-row ">
                          {user.student ? (
                            user.student.avatar !== '' ? (
                              <Avatar
                                className="flex flex-row"
                                alt={user.student.name}
                                src={user.student.avatar}
                              />
                            ) : (
                              <Avatar
                                className="flex flex-row"
                                alt="user photo"
                                src="assets/images/avatars/profile.jpg"
                              />
                            )
                          ) : (
                            <Avatar
                              className="flex flex-row"
                              alt="user photo"
                              src="assets/images/avatars/profile.jpg"
                            />
                          )}
                          <div className="flex flex-col">
                            <ListItemText
                              className="ml-4 w-128"
                              primary={userDataRedux ? userDataRedux.name : '-'}
                              secondary={
                                global.data_user.roles.find(
                                  (role) => role === 'instructor'
                                )
                                  ? global.wording.instructorCap
                                  : global.wording.learnerCap
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row">
                        {user.student ? (
                          user.student.avatar !== '' ? (
                            <Avatar
                              className="flex flex-row"
                              alt={user.student.name}
                              src={user.student.avatar}
                            />
                          ) : (
                            <Avatar
                              className="flex flex-row"
                              alt="user photo"
                              src={
                                (global && global.data_user.avatar) ||
                                'assets/images/avatars/profile.jpg'
                              }
                            />
                          )
                        ) : (
                          <Avatar
                            className="flex flex-row"
                            alt="user photo"
                            src={
                              (global && global.data_user.avatar) ||
                              'assets/images/avatars/profile.jpg'
                            }
                          />
                        )}
                        <div className="flex flex-1 flex-col">
                          <ListItemText
                            className="ml-4 w-128"
                            primary={
                              userDataRedux
                                ? userDataRedux.name
                                : (global && global.data_user.name) || '-'
                            }
                            secondary={
                              global.data_user.roles.find(
                                (role) => role === 'instructor'
                              )
                                ? global.wording.instructorCap
                                : global.wording.learnerCap
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </MenuItem>
                {/* <MenuItem component={Link} to="/balance"> */}
                <MenuItem>
                  <a href={`${global.vendor_url}/transaction`}>
                    <div className="flex flex-1 flex-row">
                      <Typography variant="subtitle1">Balance</Typography>
                      <Typography
                        className="ml-92"
                        variant="subtitle1"
                        style={{fontWeight: 'bold', color: '#014a62'}}
                      >
                        {userDataRedux && userDataRedux.latestBalance
                          ? convertToRupiah(userDataRedux.latestBalance)
                          : (global &&
                              global.data_user &&
                              global.data_user.latestBalance &&
                              convertToRupiah(
                                global.data_user.latestBalance
                              )) ||
                            '-'}
                      </Typography>
                    </div>
                  </a>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to="/my-learning/courses">
                  <div className="flex flex-1 flex-row">
                    <Typography variant="subtitle1">My Learning</Typography>
                  </div>
                </MenuItem>
                <MenuItem component={Link} to="/my-certificate">
                  <div className="flex flex-1 flex-row">
                    <Typography variant="subtitle1">My Certificate</Typography>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="flex flex-1 flex-row">
                    <a
                      href={`${global.vendor_url}/transaction/Purchase%20History`}
                      target="_blank"
                    >
                      <Typography variant="subtitle1">
                        Purchase History
                      </Typography>
                    </a>
                  </div>
                </MenuItem>

                <a href="https://support.wlb.co.id/knowledge-base">
                  <MenuItem>
                    <Typography variant="subtitle1">Helps</Typography>
                  </MenuItem>
                </a>

                <MenuItem onClick={this.userMenuClose}>
                  <ListItemIcon>
                    <Icon>exit_to_app</Icon>
                  </ListItemIcon>

                  <ListItemText className="pl-0" primary="Logout" />
                </MenuItem>
              </div>
            </Card>
          </ReactHover.Hover>
        </ReactHover>
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logoutUser,
      getStudent: authActions.getStudentProfile,
    },
    dispatch
  )
}

function mapStateToProps({data}) {
  return {
    user: data.user,
  }
}

export default withReducer(
  'data',
  reducer
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu)))
