import React from 'react'

const GradesComponent = () => {
  return (
    <div>
      <h4>GradesComponent</h4>
    </div>
  )
}

export default GradesComponent
