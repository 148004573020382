import * as Actions from '../actions'

const initialState = {
  data: [],
}

const classroomReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CLASSROOM: {
      return {
        ...state,
        myClassroom: action.payload,
      }
    }
    case Actions.GET_ALL_CLASSROOM: {
      return {
        ...state,
        classrooms: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default classroomReducer
