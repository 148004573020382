import React from 'react'
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  InputBase,
  Icon,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = (theme) => ({
  margin: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  skyBlueColor: {
    color: '#039be5',
  },
  rangeInputRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  rangeInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1.4rem',
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#000',
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

const StarLabel = (props) => {
  const {averageRating, classes} = props
  const starArray = []
  const fullStars = parseInt(averageRating)
  for (let i = 0; i < fullStars; i++) {
    starArray.push('star')
  }

  const halfStars = averageRating % 1 > 0 ? 1 : 0
  if (halfStars) {
    starArray.push('star_half')
  }

  for (let i = 0; i < 5 - (fullStars + halfStars); i++) {
    starArray.push('star_border')
  }

  return (
    <div className="flex flex-1 flex-row content-start justify-start items-center mr-4">
      {starArray.map((star, index) => {
        return (
          <Icon key={index} className={classes.skyBlueColor} fontSize="small">
            {star}
          </Icon>
        )
      })}
    </div>
  )
}

const SeeMoreBtn = ({onClick}) => {
  return (
    <a href="/#" onClick={onClick} className="mt-12">
      See more
    </a>
  )
}

const SubCategoryList = (props) => {
  const {
    summary,
    subSummary,
    onChangeSubPanel,
    subExpanded,
    panelId,
    subDetails,
    onChangeCheckbox,
  } = props

  return (
    <div className="pr-24">
      <ExpansionPanel
        elevation={0}
        expanded={subExpanded[panelId]}
        onChange={() => onChangeSubPanel(panelId)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="font-bold">{subSummary}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormGroup>
            {subDetails.map((detail, index) => {
              return (
                <FormControlLabel
                  key={index}
                  name={summary.toLowerCase()}
                  control={
                    <Checkbox value={detail.id} onChange={onChangeCheckbox} />
                  }
                  label={
                    <div className="flex flex-1 flex-row">
                      <Typography>{detail.name}</Typography>
                      {/* <Typography className="text-grey">
                        &nbsp;
                        {detail.foundLength || 0}
                      </Typography> */}
                    </div>
                  }
                />
              )
            })}
          </FormGroup>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

const OptionList = (props) => {
  const {
    summary,
    isRating,
    data,
    onChangeCheckbox,
    onChangeRadio,
    value,
  } = props

  const ratingValue = value && Number(value.ratings)

  if (isRating) {
    return (
      <FormControlLabel
        name={summary.toLowerCase()}
        control={
          <Radio
            value={data.value}
            onChange={onChangeRadio}
            checked={data.value === ratingValue}
          />
        }
        label={
          <div className="flex flex-1 flex-row">
            <StarLabel averageRating={data.value} {...props} />
            <Typography>{data.label}</Typography>
            {/* <Typography className="text-grey">
            &nbsp;
            {data.foundLength || 0}
            </Typography> */}
          </div>
        }
      />
    )
  } else {
    return (
      <FormControlLabel
        name={summary.toLowerCase()}
        control={<Checkbox value={data.value} onChange={onChangeCheckbox} />}
        label={
          <div className="flex flex-1 flex-row">
            <Typography>{data.label}</Typography>
            {/* <Typography className="text-grey">
            &nbsp;
            {data.foundLength || 0}
            </Typography> */}
          </div>
        }
      />
    )
  }
}

const ExpansionFilter = (props) => {
  const {
    classes,
    expanded,
    onChangePanel,
    summary,
    details,
    moreOptions,
    rangeInputNumber,
    hasSubCategories,
  } = props

  let newDetails = details
  if (moreOptions) {
    newDetails = details.slice(0, moreOptions.maxLength)
  }

  const renderRangeInputNumber = () => {
    const {onChangeNumberMin, onChangeNumberMax} = rangeInputNumber

    return (
      <>
        <FormControl className={classes.margin}>
          <InputBase
            type="number"
            id="priceMin"
            name="priceMin"
            placeholder="Min"
            classes={{
              root: classes.rangeInputRoot,
              input: classes.rangeInput,
            }}
            fullWidth
            onChange={onChangeNumberMin}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <InputBase
            type="number"
            id="priceMax"
            name="priceMax"
            placeholder="Max"
            classes={{
              root: classes.rangeInputRoot,
              input: classes.rangeInput,
            }}
            fullWidth
            onChange={onChangeNumberMax}
          />
        </FormControl>
      </>
    )
  }

  return (
    <ExpansionPanel elevation={0} expanded={expanded} onChange={onChangePanel}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="font-extrabold">{summary}</Typography>
      </ExpansionPanelSummary>
      {hasSubCategories ? (
        newDetails.map((detail) => {
          return (
            <SubCategoryList
              key={detail.value}
              panelId={detail.value}
              subSummary={detail.label}
              subDetails={detail.options}
              {...props}
            />
          )
        })
      ) : (
        <ExpansionPanelDetails>
          <FormGroup>
            {newDetails.map((detail, index) => {
              return <OptionList key={index} data={detail} {...props} />
            })}
            {moreOptions &&
              !moreOptions.isHidden &&
              newDetails.length >= moreOptions.maxLength && (
                <SeeMoreBtn onClick={moreOptions.onClick} />
              )}
            {rangeInputNumber &&
              rangeInputNumber.isOpen &&
              renderRangeInputNumber()}
          </FormGroup>
        </ExpansionPanelDetails>
      )}
    </ExpansionPanel>
  )
}

export default withStyles(styles, {withTheme: true})(ExpansionFilter)
