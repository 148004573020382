import gql from 'graphql-tag'

export const GET_MICROLEARNING_BY_ID = gql`
  query($id: uuid!) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      global_user {
        id
        name
        avatar
      }
      icon_url
      name
      description
      subtitle
      slug
      price
      academy_course_category {
        id
        name
        parent: academy_course_category {
          id
          name
        }
      }
      global_language {
        id
        name
        language_code
      }
      date_added
      status
      level
      topic
      banner_url
      price
    }
  }
`

export const GET_MICROLEARNING_CREATOR = gql`
  query($id: uuid!) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      global_user {
        id
        name
        avatar
        contributor_label
        people_work_placements {
          id
          company_job_profile {
            id
            position
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
        learning_contributor_ratings {
          total_rating_avg
          total_learner_count
          total_catalog_count
          total_rating_count
        }
      }
    }
  }
`
