import gql from 'graphql-tag'

export const BOOK_TYPES = gql`
  query {
    academy_book_types {
      id
      name
    }
  }
`

export const SEARCH_BOOK = gql`
  query(
    $offset: Int
    $limit: Int
    $order: [academy_books_order_by!]
    $query: String
    $pricing: Int_comparison_exp
    $rating: float8_comparison_exp
    $typeIds: [Int!]
    $durations: [academy_books_bool_exp]
  ) {
    academy_books(
      offset: $offset
      limit: $limit
      order_by: $order
      where: {
        title: {_ilike: $query}
        pricing_idr: $pricing
        average_rating: $rating
        academy_book_type: {id: {_in: $typeIds}}
        _or: $durations
      }
    ) {
      id
      isbn
      title
      pricing_idr
      thumbnail
      book_type
      duration_seconds
      average_rating
      author: global_user {
        id
        name
      }
    }
  }
`
