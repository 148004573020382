import gql from 'graphql-tag'

export const GET_MY_MENTOR = gql`
  query($userId: uuid!, $limit: Int, $offset: Int) {
    global_users(
      offset: $offset
      limit: $limit
      where: {
        id: {_neq: $userId}
        classroom_members: {
          classroom: {classroom_members: {member_user_id: {_eq: $userId}}}
          _or: [
            {is_admin: {_eq: true}, classroom: {is_individual: {_eq: false}}}
            {is_individual_mentor: {_eq: true}}
          ]
        }
      }
    ) {
      id
      name
      avatar
      people_work_placements {
        company_job_profile {
          position
          title
        }
        company_profile {
          brand_name
          legal_name
        }
      }
      contributor_label
    }
  }
`

export const GET_MENTORS = gql`
  query($offset: Int, $limit: Int) {
    global_users(
      offset: $offset
      limit: $limit
      where: {
        _or: [
          {
            classroom_members: {
              is_admin: {_eq: true}
              classroom: {versioning_id: {_is_null: false}}
            }
          }
        ]
      }
    ) {
      classrooms_aggregate(
        where: {
          classroom_members: {
            is_admin: {_eq: true}
            classroom: {versioning_id: {_is_null: false}}
          }
        }
      ) {
        aggregate {
          # is_cross_mentor > 1 == true
          is_cross_mentor: count
        }
        nodes {
          classroom_reviews_aggregate {
            aggregate {
              avg {
                star
              }
              count
            }
          }
        }
      }
      id
      name
      avatar
      academy_courses_aggregate {
        aggregate {
          count
          avg {
            average_rating
          }
          sum {
            count_enrollment
          }
        }
      }
      people_work_placements {
        company_job_profile {
          position
          title
        }
        company_profile {
          brand_name
          legal_name
        }
      }
    }
  }
`

export const SEARCH_MENTOR = gql`
  query($offset: Int, $limit: Int, $searchQuery: String) {
    global_users(
      offset: $offset
      limit: $limit
      where: {name: {_ilike: $searchQuery}}
    ) {
      id
      name
      avatar
      contributor_label
      people_work_placements {
        company_job_profile {
          position
          title
        }
        company_profile {
          brand_name
          legal_name
        }
      }
    }
  }
`
