import gql from 'graphql-tag'

export const GET_MY_BOOK = gql`
  query(
    $offset: Int
    $limit: Int
    $userId: uuid
    $progress: Int_comparison_exp
    $search: String
    $categoryIds: [Int!]
    $orderBy: [academy_books_order_by!]
    $type: Int
  ) {
    academy_books(
      where: {
        academy_book_owners: {user_id: {_eq: $userId}, progress: $progress}
        title: {_ilike: $search}
        category: {_in: $categoryIds}
        book_type: {_eq: $type}
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      isbn
      title
      type: academy_book_type {
        id
      }
      academy_book_owners {
        progress
      }
      academy_course_category {
        id
        name
      }
      average_rating
      author: global_user {
        id
        name
        people_work_placements(limit: 1, order_by: {date_added: desc}) {
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
          }
        }
      }
    }
  }
`
