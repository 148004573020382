import React, {useState} from 'react'
import {
  Button,
  AppBar,
  MuiThemeProvider,
  Toolbar,
  List,
  ListItem,
  Typography,
  TextField,
} from '@material-ui/core'
import connect from 'react-redux/es/connect/connect'
import {Link, NavLink} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import global from 'app/global-variable'
import axios from 'axios'

const isInstructor =
  Object.keys(global.data_user).length != 0
    ? global.data_user.roles.find((role) => role === 'instructor')
    : null

const StyledLi = withStyles({
  root: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
})(ListItem)

const FooterLayout2 = ({footerTheme}) => {
  const [email, setEmail] = useState('')
  const subscribe = () => {
    axios
      .post(
        'https://wlb.us18.list-manage.com/subscribe/post?u=6e1b8fdca0d84f40442a3db77&id=85a5600ab5',
        {
          email: email,
        }
      )
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  return (
    <MuiThemeProvider theme={footerTheme}>
      {/* <AppBar
        id="fuse-footer"
        className="relative z-10"
        style={{backgroundColor: '#f6f8f9'}}
        elevation={0}
      >
        <Toolbar className="max-w-2xl w-full mx-auto flex items-start">
          <div
            className="w-1/4"
            style={{
              justifyContent: 'center',
              flexDirection: 'column',
              alignSelf: 'center',
            }}
          >
            <NavLink to="/">
              <img
                src={'assets/images/logos/wlb-combined-green.svg'}
                className="h-48"
                alt="wlb-logo"
              />
            </NavLink>
          </div>

          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>About</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#about"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>
                    About Worklife &amp; Beyond
                  </Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#careers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>Careers</Typography>
                </a>
              </StyledLi>
              <StyledLi>
                {isInstructor ? (
                  <Link className="font-medium" to="/instructor/dashboard">
                    <Typography style={{color: '#014a62'}}>
                      Instructor Dashboard
                    </Typography>
                  </Link>
                ) : (
                  <Link className="font-medium" to="/instructor/become">
                    <Typography style={{color: '#014a62'}}>
                      Become an Instructor
                    </Typography>
                  </Link>
                )}
              </StyledLi>
            </List>
          </div>
          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>Product</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Academic Solution
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Business Solution
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Government Solution
                </Typography>
              </StyledLi>
            </List>
          </div>
          {/* <div>
                        <List className="flex flex-col">
                            <StyledLi>
                                <Typography style={{color:"#014a62"}}>
                                    <b>
                                        Language
                                    </b>
                                </Typography>
                            </StyledLi>
                            <StyledLi>
                                <Typography style={{color:"#014a62"}}>Indonesia</Typography>
                            </StyledLi>
                            <StyledLi>
                                <Typography style={{color:"#014a62"}}>English</Typography>
                            </StyledLi>

                        </List>
                    </div> 
          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>Language</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>Indonesia</Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>English</Typography>
              </StyledLi>
            </List>
          </div>
          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>Connect With Us</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Email Address
                </Typography>
              </StyledLi>
              <StyledLi>
                <div id="mc_embed_signup">
                  <form
                    action="https://wlb.us18.list-manage.com/subscribe/post?u=6e1b8fdca0d84f40442a3db77&id=85a5600ab5"
                    method="post"
                    target="_blank"
                    className="flex flex-row"
                  >
                    <input
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      name="EMAIL"
                      placeholder="Your email address"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />

                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        color: '#fff',
                        marginLeft: '.8rem',
                        background: '#039be5',
                        fontSize: '1rem',
                      }}
                      onClick={subscribe}
                    >
                      SUBSCRIBE
                    </Button>
                  </form>
                </div>
              </StyledLi>
              <List className="flex flex-row">
                <StyledLi>
                  <a
                    href="https://www.facebook.com/worklifeandbeyond/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={'assets/images/logos/fb-icon.svg'}
                      style={{width: '2em', height: '2em'}}
                      alt="fb-icon"
                    />
                  </a>
                </StyledLi>
                <StyledLi>
                  <a
                    href="https://www.linkedin.com/company/worklifebeyond"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={'assets/images/logos/linked-icon.svg'}
                      style={{width: '2em', height: '2em'}}
                      alt="linked-in-icon"
                    />
                  </a>
                </StyledLi>
                <StyledLi></StyledLi>
              </List>
              <StyledLi>
                <br />
              </StyledLi>
            </List>
          </div>

        </Toolbar>
      </AppBar> */}
      <AppBar
        id="fuse-footer"
        className="relative z-10"
        style={{backgroundColor: '#fff', color: '#014a62'}}
        elevation="0"
      >
        <Toolbar className="px-72 py-0 flex items-center">
          <div className="flex flex-1">
            © {new Date().getFullYear()} PT. Kreasi inovasi Digital. All Rights
            Reserved
          </div>

          <div>
            <List className="flex flex-row">
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>Home</Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>Service</Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>Term</Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/terms/#privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62', width: '100px'}}>
                    Privacy Policy
                  </Typography>
                </a>
              </StyledLi>
            </List>
          </div>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  )
}

function mapStateToProps({fuse}) {
  return {
    footerTheme: fuse.settings.footerTheme,
  }
}

export default connect(mapStateToProps)(FooterLayout2)
