import React, {Component} from 'react'
import {Typography, Button} from '@material-ui/core'
import global from './global-variable'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {error: null, errorInfo: null}
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo && global.environment !== 'development') {
      return (window.location.href = '/sww')
    }
    return this.props.children
  }
}
