import gql from 'graphql-tag'

export const GET_INSTRUCTOR_BIOGRAPHY = gql`
  query($userId: uuid) {
    global_users(where: {id: {_eq: $userId}}) {
      id
      biography
    }
  }
`
