import React from 'react'
import {Card, Typography, Divider, Icon} from '@material-ui/core'
import {withRouter, NavLink} from 'react-router-dom'

const styles = {
  grey: {
    paddingRight: 0,
    color: '#95989a',
    fontWeight: '600',
    lineHeight: '1.7',
    marginLeft: '.8rem',
    display: 'block',
  },
  greyIcon: {color: '#95989a'},
  tealFont: {
    color: '#003d51',
    fontWeight: '600',
  },
  active: {
    backgroundColor: '#f7f8f9',
    borderLeft: '4px solid #039be5',
    paddingLeft: 16,
  },
}

const MicroLearningNavigation = props => {
  const navigationData = [
    {
      icon: 'dashboard',
      title: 'Dasbor',
      pathname: `/instructor/micro-learnings/dashboard`,
      active: 'micro-learnings-dashboard',
    },
    {
      icon: 'group',
      title: 'Pembelajar',
      pathname: `/instructor/micro-learnings/learner`,
      active: 'micro-learnings-learner',
    },
    {
      icon: 'check',
      title: 'Pembelajaran Mikro',
      pathname: `/instructor/micro-learnings/questions`,
      active: 'micro-learnings-questions',
    },
    {
      icon: 'rate_review',
      title: 'Tinjauan',
      pathname: `/instructor/micro-learnings/review`,
      active: 'micro-learnings-review',
    },
    {
      icon: 'comment',
      title: 'Komentar',
      pathname: `/instructor/micro-learnings/comment`,
      active: 'micro-learnings-comment',
    },
  ]
  return (
    <Card className="w-full rounded-8">
      <div>
        <div className="py-12 px-20">
          <div className="flex flex-row items-center py-8">
            <Typography
              variant="h6"
              className="font-bold ml-8 uppercase"
              style={styles.tealFont}
            >
              Aplikasi
            </Typography>
          </div>
        </div>

        <Divider className="mx-16" />
        {navigationData.map((nav, index) => {
          return (
            <div
              key={index}
              className="py-12 px-20 flex"
              style={
                props.location.pathname === nav.pathname ? styles.active : {}
              }
            >
              <div className="flex items-center">
                {nav.title === 'Micro Learning' ? (
                  <img
                    src="assets/images/logos/fact_check.svg"
                    alt=""
                    style={styles.greyIcon}
                  />
                ) : (
                  <Icon style={styles.greyIcon}>{nav.icon}</Icon>
                )}

                <NavLink
                  className="ml-8"
                  to={{
                    pathname: nav.pathname,
                    state: {
                      active: nav.active,
                      microLearning: props.location.state.microLearning,
                    },
                  }}
                  activeStyle={{color: '#014a62'}}
                  style={styles.grey}
                >
                  {nav.title}
                </NavLink>
              </div>
            </div>
          )
        })}

        {/* <div className="py-12 px-20 flex">
          <div className="flex items-center">
            <Icon style={styles.greyIcon}>attach_money</Icon>
            <NavLink
              className="ml-8"
              to={{
                pathname: `/instructor/micro-learnings/earnings`,
                state: {
                  active: 'micro-learnings-earnings',
                  microLearning: props.location.state.microLearning
                },
              }}
            >
              Earning
            </NavLink>
          </div>
        </div> */}

        {/* <div className="py-12 px-20 flex">
          <div className="flex items-center">
            <Icon style={styles.greyIcon}>star</Icon>
            <NavLink
              className="ml-8"
              to={{
                pathname: `/instructor/micro-learnings/grades`,
                state: {
                  active: 'micro-learnings-grades',
                  microLearning: props.location.state.microLearning
                },
              }}
            >
              Grades
            </NavLink>
          </div>
        </div> */}

        <Divider />

        <div className="py-12 px-20 flex">
          <div className="flex items-center">
            <Icon style={styles.greyIcon}>reply</Icon>
            <NavLink
              className="ml-8"
              style={styles.grey}
              to={`/instructor/micro-learning`}
            >
              Kembali
            </NavLink>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default withRouter(MicroLearningNavigation)
