import * as Actions from '../actions'

const initialState = {
  data: null,
  quiz: null,
}

const widgetsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_WIDGETS:
      return {
        ...state,
        data: {...action.payload},
      }
    case Actions.GET_DASHBOARD_STATISTICS:
      return {
        ...state,
        statistics: {...action.payload},
      }
    case Actions.GET_QUIZ:
      return {
        ...state,
        quiz: action.payload.data,
      }
    case Actions.SAVE_QUIZ:
      return {
        ...state,
      }
    case Actions.REMOVE_QUIZ:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default widgetsReducer
