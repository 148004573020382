import gql from 'graphql-tag'

export const GET_SUBSCRIBED_MICRO_LEARNING = gql`
  query(
    $userId: uuid!
    $orderBy: [microlearnings_order_by!]
    $offset: Int
    $limit: Int
  ) {
    finished_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: true}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    on_progress_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: false}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings_aggregate(
      where: {
        microlearning_members: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings(
      where: {
        microlearning_members: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
      }
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
      name
      icon_url
      microlearning_members(
        where: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
      ) {
        id
        progress
        score
      }
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          count
          avg {
            star
          }
        }
      }
      academy_course_category {
        id
        name
        parent: academy_course_category {
          id
          name
        }
      }
      global_language {
        id
        name
        language_code
      }
      date_added
      status
      level
      topic
      question_count
      type
      company_profile {
        id
        brand_name
        legal_name
      }
      global_user {
        id
        name
        avatar
      }
    }
  }
`
