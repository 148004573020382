/**
 * Authorization Roles

const authRoles = {
    admin    : ['admin'],
    staff    : ['admin', 'staff'],
    user     : ['admin', 'staff', 'user'],
    onlyGuest: ['guest']
};*/

const authRoles = {
  student: ['student', 'instructor'],
  administrator: ['administrator'],
}

export default authRoles
