import * as Actions from '../actions'

const initialState = {
  data: [],
  recommendation: [],
  trending: [],
  promoted: [],
  categories: [],
  courseCategories: [],
  cart: [],
  wishlists: [],
  course: null,
  myCourses: [],
  student: {
    latestBalance: 0,
  },
  searchText: '',
  categoryFilter: 'all',
  courseBySlug: null,
  instructorCourses: [],
  courseExperts: [],
  searchGlobalValue: '',
}

const coursesReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COURSES: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case Actions.GET_STUDENT: {
      return {
        ...state,
        student: action.payload,
      }
    }
    case Actions.GET_MY_COURSES: {
      return {
        ...state,
        myCourses: action.payload,
      }
    }
    case Actions.GET_RECOMMENDATION: {
      return {
        ...state,
        recommendation: action.payload,
      }
    }
    case Actions.GET_TRENDING: {
      return {
        ...state,
        trending: action.payload,
      }
    }
    case Actions.GET_PROMO: {
      return {
        ...state,
        promoted: action.payload,
      }
    }
    case Actions.IS_SHOWED: {
      return {
        ...state,
        coursesCategoryShowed: action.payload,
      }
    }
    case Actions.ADD_TO_CART: {
      return {
        ...state,
      }
    }
    case Actions.CHECKOUT: {
      return {
        ...state,
      }
    }
    case Actions.GET_CART: {
      return {
        ...state,
        cart: action.payload,
      }
    }
    case Actions.GET_COURSE_BY_SLUG: {
      return {
        ...state,
        // ...action.payload
        courseBySlug: action.payload,
        // courseBySlug: {name:"Muhammad Ilham Fadillah"}
      }
    }
    case Actions.GET_WISHLIST: {
      return {
        ...state,
        wishlists: action.payload,
      }
    }
    case Actions.GET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case Actions.GET_COURSE_CATEGORIES: {
      return {
        ...state,
        courseCategories: action.payload,
      }
    }
    case Actions.SET_ARCHIEVED: {
      return {
        ...state,
      }
    }
    case Actions.SET_COURSES_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      }
    }
    case Actions.SET_COURSES_CATEGORY_FILTER: {
      return {
        ...state,
        categoryFilter: action.category,
      }
    }
    case Actions.GET_COURSES_INSTRUCTOR: {
      return {
        ...state,
        instructorCourses: action.payload,
      }
    }
    case Actions.GET_COURSE_EXPERT: {
      return {
        ...state,
        courseExperts: action.payload,
      }
    }
    case Actions.SEARCH_GLOBAL_VALUE: {
      return {
        ...state,
        searchGlobalValue: action.value,
      }
    }
    default: {
      return state
    }
  }
}

export default coursesReducer
