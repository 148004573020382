import {FuseLoadable} from '@fuse'
import DashboardComponent from './detail-micro-learning/DetailComponent'

export const InstructorMicroLearningConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/instructor/micro-learnings/create',
      component: FuseLoadable({
        loader: () => import('./MicroLearningCreate'),
      }),
    },
    {
      path: '/instructor/micro-learnings/dashboard',
      component: DashboardComponent,
      state: 'micro-learnings-dashboard',
    },
    {
      path: '/instructor/micro-learnings/learner',
      component: DashboardComponent,
      state: 'micro-learnings-learner',
    },
    {
      path: '/instructor/micro-learnings/earnings',
      component: DashboardComponent,
      state: 'micro-learnings-earnings',
    },
    {
      path: '/instructor/micro-learnings/questions',
      component: DashboardComponent,
      state: 'micro-learnings-questions',
    },
    {
      path: '/instructor/micro-learnings/grades',
      component: DashboardComponent,
      state: 'micro-learnings-grades',
    },
    {
      path: '/instructor/micro-learnings/review',
      component: DashboardComponent,
      state: 'micro-learnings-review',
    },
    {
      path: '/instructor/micro-learnings/comment',
      component: DashboardComponent,
      state: 'micro-learnings-comment',
    },
    {
      path: '/instructor/micro-learnings/response/:id/:userId',
      component: FuseLoadable({
        loader: () =>
          import('./detail-micro-learning/micro-learning/EmpResponseComponent'),
      }),
    },
  ],
}
