import gql from 'graphql-tag'

export const GET_MICROLEARNING_COMMENTS = gql`
  query(
    $offset: Int
    $limit: Int
    $microlearningId: uuid
    $orderBy: [microlearning_comments_order_by!]
    $repliesOrderBy: [microlearning_comments_order_by!]
    $userId: uuid!
  ) {
    #microlearning_comments_aggregate(
    #  where: {microlearning_id: {_eq: $microlearningId}}
    #) {
    #  aggregate {
    #    count
    #  }
    #}
    microlearning_comments(
      order_by: $orderBy
      offset: $offset
      limit: $limit
      where: {
        microlearning_id: {_eq: $microlearningId}
        parent_id: {_is_null: true}
      }
    ) {
      id
      content
      likes: microlearning_comment_likes_aggregate {
        aggregate {
          count
        }
      }
      is_liked_by_me: microlearning_comment_likes_aggregate(
        where: {user_id: {_eq: $userId}}
      ) {
        aggregate {
          count
        }
      }
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      replies: microlearning_comments(order_by: $repliesOrderBy) {
        id
        content
        likes: microlearning_comment_likes_aggregate {
          aggregate {
            count
          }
        }
        is_liked_by_me: microlearning_comment_likes_aggregate(
          where: {user_id: {_eq: $userId}}
        ) {
          aggregate {
            count
          }
        }
        global_user {
          id
          name
          avatar
          people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
            company_job_profile {
              position
              title
            }
            company_profile {
              brand_name
              legal_name
            }
          }
        }
      }
    }
  }
`
