import {getCookie} from 'app/utils/helpers'

const {
  REACT_APP_API_URL,
  REACT_APP_JW_URL,
  REACT_APP_FILE_URL,
  REACT_APP_PLAYER_ID,
  REACT_APP_SENTRY,
  REACT_APP_COOKIE,
  REACT_APP_SSO,
  REACT_APP_CHAT,
  REACT_APP_HC_DASHBOARD,
  REACT_APP_GRAPHQL_URL,
  REACT_APP_GRAPHQL_URL_WS,
  REACT_APP_VENDOR,
  REACT_APP_SOSMED,
  REACT_APP_LANDING,
  REACT_APP_SOSMED_API_URL,
  REACT_APP_SENTRY_ENV,
  REACT_APP_NEW_LEARNING,
  NODE_ENV,
} = process.env

// .

export const userData = getCookie('userData')
  ? JSON.parse(getCookie('userData'))
  : {}
let access_token = ''
let company_id = ''
let user_id = ''
let chat_home_server = ''
let data_user = {}
let roles = []
let company_logo = ''
if (userData && userData.oauth) {
  company_id = userData.company_id
  access_token = userData.oauth.access_token
  user_id = userData.id
  chat_home_server = userData.chat.home_server
  data_user = userData
  roles = userData.roles
  company_logo = userData.company_logo
}

const global = {
  sentry_url: REACT_APP_SENTRY,
  api: {
    dev: REACT_APP_API_URL,
    sosmed: REACT_APP_SOSMED_API_URL,
  },
  jw: {
    get_dev: REACT_APP_JW_URL,
    player_id: REACT_APP_PLAYER_ID,
    // get_production: 'https://media.wlb.co.id',
    manifest: REACT_APP_FILE_URL,
    // manifestv2:
    //   'https://us-central1-wlb-prod.cloudfunctions.net/generate_video_manifest',
    // upload: 'https://jwplatform-upload.s3-accelerate.amazonaws.com',
  },
  file: {
    dev: REACT_APP_FILE_URL,
    // production: 'https://storage.wlb.co.id',
    // production_low_latency: 'https://storage-dot-wlb-prod.appspot.com/',
  },
  wording: {
    pages_auth_login:
      'We build our ecosystem integrated with Social Media Platform that connect Human Capital Management, Learning Management Systems & Many Services for easy Access',
    learning:
      'Experience flexible learning experience for better transfer knowledge with Gamification, integrated with on-boarding system.',
    engagement: 'Engagement',
    learnerCap: 'Pembelajar',
    learnerLow: 'pembelajar',
    instructorCap: 'Instructor',
    instructorLow: 'instructor',
  },
  app_cookie: REACT_APP_COOKIE,
  landing_url: REACT_APP_LANDING,
  sso_url: REACT_APP_SSO,
  vendor_url: REACT_APP_VENDOR,
  hc_dashboard_url: REACT_APP_HC_DASHBOARD,
  chat: REACT_APP_CHAT,
  chat_home_server: chat_home_server,
  access_token: access_token,
  company_id: company_id,
  user_id: user_id,
  data_user: data_user,
  roles: roles,
  graphqlUrl: REACT_APP_GRAPHQL_URL,
  graphqlWsUrl: REACT_APP_GRAPHQL_URL_WS,
  sosmed_url: REACT_APP_SOSMED,
  company_logo,
  sentry_env: REACT_APP_SENTRY_ENV,
  new_learning_url: REACT_APP_NEW_LEARNING,
  environment: NODE_ENV,
}

// module.exports = global
export default global
