import * as Actions from '../actions'
import {SET_ARCHIEVED} from '../../../../learnings/store/actions'

const initialState = {
  data: [],
  recommendation: [],
  trending: [],
  promoted: [],
  categories: [],
  courseCategories: [],
  cart: [],
  wishlist: [],
  myCourses: [],
  student: {
    latestBalance: 0,
  },
  searchText: '',
  categoryFilter: 'all',
  instructorCourses: [],

  courseExperts: [],
  purchaseHistory: [],
}

const coursesReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COURSES: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case Actions.GET_STUDENT: {
      return {
        ...state,
        student: action.payload,
      }
    }
    case Actions.GET_MY_COURSES: {
      return {
        ...state,
        myCourses: action.payload,
      }
    }
    case SET_ARCHIEVED: {
      const data = state.myCourses.map(item => {
        let newItem
        if (action.enrollmentId === item.id) {
          newItem = {...item, archived: action.archieveStatus}
        } else {
          newItem = {...item}
        }
        return newItem
      })
      return {...state, myCourses: data}
    }
    case Actions.GET_RECOMMENDATION: {
      return {
        ...state,
        recommendation: action.payload,
      }
    }
    case Actions.GET_TRENDING: {
      return {
        ...state,
        trending: action.payload,
      }
    }
    case Actions.GET_PROMO: {
      return {
        ...state,
        promoted: action.payload,
      }
    }
    case Actions.ADD_TO_CART: {
      return {
        ...state,
      }
    }
    case Actions.CHECKOUT: {
      return {
        ...state,
      }
    }
    case Actions.GET_CART: {
      // console.log("REDUCER GET CART", action.payload);
      return {
        ...state,
        cart: action.payload,
      }
    }
    case Actions.GET_WISHLIST: {
      return {
        ...state,
        wishlist: action.payload,
      }
    }
    case Actions.GET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case Actions.GET_COURSE_CATEGORIES: {
      return {
        ...state,
        courseCategories: action.payload.data,
      }
    }
    case Actions.SET_COURSES_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      }
    }
    case Actions.SET_COURSES_CATEGORY_FILTER: {
      return {
        ...state,
        categoryFilter: action.category,
      }
    }
    case Actions.GET_COURSES_INSTRUCTOR: {
      return {
        ...state,
        instructorCourses: action.payload,
      }
    }
    case Actions.GET_COURSE_EXPERT: {
      return {
        ...state,
        courseExperts: action.payload,
      }
    }
    case Actions.SAVE_RATING_REVIEW: {
      return {
        ...state,
      }
    }
    case Actions.GET_PURCHASE_HISTORY: {
      return {
        ...state,
        purchaseHistory: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default coursesReducer
