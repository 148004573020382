import gql from 'graphql-tag'

export const CART = gql`
  query {
    marketplace_user_carts(
      where: {item_table: {_eq: "academy_courses"}}
      order_by: [{id: asc}]
    ) {
      id
      item_id
      item_object
      item_vendor
      item_vendor_id
      item_quantity
      item_vendor_table
    }
  }
`
