export * from './getSubscribedMicroLearning.query'
export * from './getAllMicroLearning.query'
export * from './getDailyQuestion.query'
export * from './getMicroLearningByID.query'
export * from './getDataForManageMicro.query'
export * from './getMicroLearning.query'
export * from './getQuestionByMicroLearning.query'
export * from './getMicroLearningReviews.query'
export * from './getMicroLearningComment.query'
export * from './getBiographyInstructor.query'
