import React, {Component} from 'react' // .
import {
  withStyles,
  Divider,
  Icon,
  IconButton,
  Input,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  TextField,
  Popper,
  Card,
  List,
  ListItem,
  Collapse,
} from '@material-ui/core'
import * as Actions from 'app/main/learnings/store/actions'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {FuseUtils, FuseAnimateGroup} from '@fuse'
import {Link} from 'react-router-dom'
import amber from '@material-ui/core/colors/amber'
import classNames from 'classnames'
import _ from '@lodash'
import InputAdornment from '@material-ui/core/InputAdornment'
import axios from 'axios'
import global from 'app/global-variable'
import history from 'history.js'
import Suggestions from './Suggestions'
// import ExpandLess from '@material-ui/icons/ExpandLess'
// import ExpandMore from '@material-ui/icons/ExpandMore'
// import ReactHover from 'react-hover'

const options = {
  followCursor: false,
}

const propTypes = {}

const defaultProps = {
  variant: 'horizontal',
}

const styles = (theme) => ({
  root: {
    '&.horizontal': {},
    '&.vertical': {
      flexDirection: 'column',
    },
  },
  item: {
    textDecoration: 'none!important',
  },
  addIcon: {
    color: amber[600],
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  popper: {
    zIndex: 999,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 3em 3em 3em',
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '3em 3em 0 3em',
        borderColor: `${theme.palette.common.white} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '3em 3em 3em 0',
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '3em 0 3em 3em',
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
      },
    },
  },
})

class FuseShortcutsTimelineLearning extends Component {
  state = {
    addMenu: null,
    searchText: '',
    searchResults: null,
    flatNavigation: null,
    categoryMenu: null,
    categories: null,
    displayedSearch: false,
    courses: [],
    instructors: [],
    query: '',
    toogleSuggest: false,
    openCollapse: true,
    listItemTarget: null,
  }

  componentWillMount() {
    const userData = global.data_user
    if (_.isEmpty(userData)) {
      // history.push('/learning')
    } else {
      this.getCategories()
    }
  }

  componentDidMount() {
    const userData = global.data_user
    if (_.isEmpty(userData)) {
      // history.push('/learning')
    } else {
      this.flattenNavigation(this.props.navigation)
    }
  }

  categoryMenuClick = (event) => {
    //console.log('categoryMenuClick: ' + event.currentTarget)
    this.setState({categoryMenu: event.currentTarget})
  }

  categoryMenuClose = () => {
    //console.log("eventt:"+JSON.stringify(event))
    this.setState({categoryMenu: null})
  }

  searchCourseByCategoryTemp = (categoryId) => {
    this.props.searchCourseByCategory(categoryId)
    this.props.searchTrendingCourseByCategory(categoryId)
    this.props.searchPromotedCourseByCategory(categoryId)
    this.props.searchRecommendedCourseByCategory(categoryId)
    //this.props.setShowAllCoursesCategory(false)
    // history.push('/learning')
  }
  addMenuClick = (event) => {
    this.setState({addMenu: event.currentTarget})
  }

  addMenuClose = () => {
    this.setState({addMenu: null})
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.location, prevProps.location)) {
      this.flattenNavigation(this.props.navigation)
      //console.log('this.props.location', this.props.location)
    }

    //console.log('this.props.location',this.props.location)
    // if(this.props.location == '/learning'){
    //   this.setState({
    //     displayedSearch: true
    //   })
    // }
  }

  flattenNavigation(navigation) {
    this.setState({flatNavigation: FuseUtils.getFlatNavigation(navigation)})
  }

  search = (ev) => {
    const searchText = ev.target.value
    this.setState({searchText})
    if (searchText.length !== 0 && this.state.flatNavigation) {
      this.setState({
        searchResults: this.state.flatNavigation.filter((item) =>
          item.title.toLowerCase().includes(searchText)
        ),
      })
      return
    }
    this.setState({searchResults: null})
  }

  toggleInShortcuts = (id) => {
    let shortcuts = [...this.props.shortcuts]
    shortcuts = shortcuts.includes(id)
      ? shortcuts.filter((_id) => id !== _id)
      : [...shortcuts, id]
    this.props.updateUserShortcuts(shortcuts)
  }

  getCategories = () => {
    axios
      .get(global.api.dev + '/academy/category/get/', {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
        withCredentials:true
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            categories: response.data.data,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  componentWillReceiveProps(receivedProps) {
    //console.log(receivedProps)
  }

  handleInputChange = (e) => {
    const isTabsAll = window.location.pathname.includes('tabs-all')

    this.props.searchGlobalValue(e.target.value)
    this.setState(
      {
        query: e.target.value,
      },
      () => {
        if (this.state.query && this.state.query.length > 1) {
          if (this.state.query.length % 2 === 0) {
            this.props.getCourseExpert(this.state.query)

            setTimeout(() => {
              axios
                .get(
                  global.api.dev +
                    `/academy/course/get?search=${this.state.query}&limit=9`,
                  {
                    headers: {
                      Authorization: 'Bearer ' + global.access_token,
                    },
                  }
                )
                .then((response) => {
                  if (response.status === 200 && !isTabsAll) {
                    this.setState({
                      toogleSuggest: true,
                      courses: response.data.data,
                    })
                    let dataInstructors = []
                    if (!_.isEmpty(response.data.data)) {
                      response.data.data.map((val) => {
                        return dataInstructors.push(val.CreatorUser.id)
                      })
                    }
                    this.props.getCourseInstructor(dataInstructors)
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            }, 1000)
          }
        } else if (!this.state.query) {
          this.setState({toogleSuggest: false})
        } else {
          this.setState({toogleSuggest: false})
        }
      }
    )
  }

  handleClickCollapse = (listItemTargetParam, categoryId) => {
    //Pengaturan klik 2 kali pengokapsean Menu
    let currentListItemTargetParam = this.state.listItemTarget
    if (listItemTargetParam === currentListItemTargetParam) {
      this.setState(
        {
          listItemTarget: null,
        },
        () => this.searchCourseByCategoryTemp(categoryId)
      )
    } else {
      this.setState(
        {
          listItemTarget: listItemTargetParam,
        },
        () => this.searchCourseByCategoryTemp(categoryId)
      )
    }
  }

  handleInputLeave = () => {
    //console.log('leave')
    this.setState({
      toogleSuggest: false,
    })
  }

  render() {
    const {classes, shortcuts, navigation, variant, className} = this.props
    const {
      addMenu,
      searchText,
      searchResults,
      categoryMenu,
      categories,
      openCollapse,
      query,
      courses,
      toogleSuggest,
      listItemTarget,
    } = this.state

    const dataCourses = courses.slice(0, 3)
    let dataInstructors = []
    courses.map((val) => {
      return dataInstructors.push(val.CreatorUser.id)
    })

    const shortcutItems = shortcuts
      ? shortcuts.map((id) => FuseUtils.findById(navigation, id))
      : []

    const logoStyle = {
      width: '107px',
      height: 42,
    }

    function ShortcutMenuItem({item, onToggle}) {
      return (
        <Link to={item.url} className={classes.item}>
          <MenuItem key={item.id}>
            <ListItemIcon>
              {item.icon ? (
                <Icon>{item.icon}</Icon>
              ) : (
                <span className="text-20 font-bold uppercase text-center">
                  {item.title[0]}
                </span>
              )}
            </ListItemIcon>
            <ListItemText className="pl-0" primary={item.title} />
            <IconButton
              onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation()
                onToggle(item.id)
              }}
            >
              <Icon color="action">
                {shortcuts.includes(item.id) ? 'star' : 'star_border'}
              </Icon>
            </IconButton>
          </MenuItem>
        </Link>
      )
    }

    return (
      <div
        className={classNames(
          classes.root,
          variant,
          'flex flex-1',
          variant === 'vertical' && 'flex-no-grow flex-shrink',
          className
        )}
      >
        <FuseAnimateGroup
          enter={{
            animation: 'transition.expandIn',
          }}
          className={classNames(
            'flex flex-1',
            variant === 'vertical' && 'flex-col'
          )}
        >
          <Typography className="flex flex-1 mt-4 -ml-8" style={logoStyle}>
            <a href={global.sosmed_url}>
              <img
                src={
                  global.company_logo
                    ? global.company_logo
                    : 'assets/images/logos/wlb-combined-green.svg'
                }
                alt="logo"
                style={{height: 42}}
              />
            </a>
          </Typography>
        </FuseAnimateGroup>
        {/* HIDE WLB-3421 */}
        {/* <ReactHover options={options} className="flex flex-1 z-20">
          <ReactHover.Trigger type="trigger" className="w-224 z-20">
            <Typography
              className="flex flex-1 ml-12 mt-8"
              style={{color: '#014a62'}}
            >
              Categories
            </Typography>
          </ReactHover.Trigger>
          <ReactHover.Hover type="hover" className="w-320 z-20">
            <Card>
              <List>
                {categories &&
                  categories.map((category, index) => (
                    <div key={index}>
                      <ListItem
                        alignItems="flex-start"
                        key={index}
                        button
                        onClick={() => {
                          return this.handleClickCollapse(
                            'listitem-' + category.id,
                            category.id
                          )
                        }}
                      >
                        <ListItemText primary={category.name} />
                        {category.AcademyCourseCategories.length > 0 ? (
                          listItemTarget === 'listitem-' + category.id ? (
                            openCollapse ? (
                              <ExpandLess style={{color: 'black'}} />
                            ) : (
                              <ExpandMore style={{color: 'black'}} />
                            )
                          ) : openCollapse ? (
                            <ExpandMore style={{color: 'black'}} />
                          ) : (
                            <ExpandLess style={{color: 'black'}} />
                          )
                        ) : (
                          <div />
                        )}
                      </ListItem>
                      {listItemTarget === 'listitem-' + category.id ? (
                        <Collapse
                          in={this.state.openCollapse}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {category.AcademyCourseCategories.map(
                              subCategory => (
                                <ListItem
                                  button
                                  className={classes.nested}
                                  onClick={() =>
                                    this.searchCourseByCategoryTemp(
                                      subCategory.id
                                    )
                                  }
                                >
                                  <ListItemText primary={subCategory.name} />
                                </ListItem>
                              )
                            )}
                          </List>
                        </Collapse>
                      ) : (
                        <div />
                      )}
                    </div>
                  ))}
              </List>
            </Card>
          </ReactHover.Hover>
        </ReactHover> */}
        <div
          className="flex flex-1 flex-col w-full"
          onMouseLeave={this.handleInputLeave}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            className="ml-36"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon color="action" style={{width: '20px'}}>
                    search
                  </Icon>
                </InputAdornment>
              ),
              style: {
                width: '527px',
                height: '40px',
                backgroundColor: '#f7f8f9',
              },
            }}
            value={query}
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                history.push({
                  pathname: '/tabs-all/courses',
                  state: {keyword: query},
                })
            }}
            onChange={(e) => this.handleInputChange(e)}
            fullWidth
          />
          {toogleSuggest ? (
            <Suggestions
              courses={dataCourses}
              instructors={dataInstructors}
              keyword={query}
            />
          ) : (
            ''
          )}
        </div>
        <Popper
          anchorEl={categoryMenu}
          open={Boolean(categoryMenu)}
          placement="bottom-start"
          className={classes.popper}
        />

        <Menu
          id="add-menu"
          anchorEl={addMenu}
          open={Boolean(addMenu)}
          onClose={this.addMenuClose}
          classes={{
            paper: 'mt-48',
          }}
          onEntered={() => {
            this.searchInput.focus()
          }}
          onExited={() => {
            this.setState({searchText: ''})
          }}
        >
          <div className="p-16 pt-8">
            <Input
              inputRef={(ref) => (this.searchInput = ref)}
              value={searchText}
              onChange={this.search}
              placeholder="Search for an app or page"
              className=""
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </div>

          <Divider />

          {searchText.length !== 0 &&
            searchResults &&
            searchResults.map((item) => (
              <ShortcutMenuItem
                key={item.id}
                item={item}
                onToggle={() => this.toggleInShortcuts(item.id)}
              />
            ))}

          {searchText.length !== 0 && searchResults.length === 0 && (
            <Typography color="textSecondary" className="p-16 pb-8">
              No results..
            </Typography>
          )}

          {searchText.length === 0 &&
            shortcutItems.map(
              (item) =>
                item && (
                  <ShortcutMenuItem
                    key={item.id}
                    item={item}
                    onToggle={() => this.toggleInShortcuts(item.id)}
                  />
                )
            )}
        </Menu>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchCourseByCategory: Actions.getCourses,
      searchTrendingCourseByCategory: Actions.getCourseTrending,
      searchRecommendedCourseByCategory: Actions.getCourseRecommended,
      searchPromotedCourseByCategory: Actions.getCoursePromo,
      getCourseInstructor: Actions.getCourseInstructor,
      getCourseExpert: Actions.getCourseExpert,
      searchGlobalValue: Actions.searchGlobalValue,
      //,setShowAllCoursesCategory: Actions.showAllCoursesCategory
    },
    dispatch
  )
}

function mapStateToProps({fuse}) {
  return {
    navigation: fuse.navigation,
    /*shortcuts: auth.user.data.shortcuts,*/
  }
}

FuseShortcutsTimelineLearning.propTypes = propTypes
FuseShortcutsTimelineLearning.defaultProps = defaultProps

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FuseShortcutsTimelineLearning)
)
