import {FuseLoadable} from '@fuse'

export const InstructorDashboardAppConfig = {
  settings: {
    layout: {
      style: 'layout4',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
          folded: false,
          position: 'left',
        },
        toolbar: {
          display: true,
          style: 'fixed',
          position: 'below',
        },
        footer: {
          display: false,
          style: 'static',
          position: 'below',
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/instructor/dashboard',
      component: FuseLoadable({
        loader: () => import('./InstructorDashboardApp'),
      }),
    },
    {
      path: '/instructor/my_course',
      component: FuseLoadable({
        loader: () => import('./InstructorDashboardApp'),
      }),
    },
    {
      path: '/instructor/mycourse',
      component: FuseLoadable({
        loader: () => import('./InstructorMyCourse'),
      }),
    },
    {
      path: '/instructor/course-dashboard/:slug', //.
      component: FuseLoadable({
        loader: () => import('./course/dashboard/Dashboard'),
      }),
    },
    {
      path: '/instructor/course-dashboard-student/:slug', //.
      component: FuseLoadable({
        loader: () => import('./course/student/Student'),
      }),
    },
    {
      path: '/instructor/course-dashboard-review/:slug', //.
      component: FuseLoadable({
        loader: () => import('./course/review/Review'),
      }),
    },
    {
      path: '/instructor/course-dashboard-comment/:slug', //.
      component: FuseLoadable({
        loader: () => import('./course/comment/Comment'),
      }),
    },
    {
      path: '/instructor/course-dashboard-grade', //.
      component: FuseLoadable({
        loader: () => import('./course/quiz/grade/Grade'),
      }),
    },
    {
      path: '/instructor/course-dashboard-earning/:slug',
      component: FuseLoadable({
        loader: () => import('./course/dashboard/DashboardRevenueContainer'),
      }),
    },
    {
      path: '/instructor/earnings',
      component: FuseLoadable({
        loader: () => import('./InstructorEarnings'),
      }),
    },
    {
      path: '/instructor/student',
      component: FuseLoadable({
        loader: () => import('./InstructorStudent'),
      }),
    },
    {
      path: '/instructor/chat',
      component: FuseLoadable({
        loader: () => import('./InstructorChat'),
      }),
    },
    {
      path: '/instructor/tools',
      component: FuseLoadable({
        loader: () => import('./InstructorTools'),
      }),
    },
    {
      path: '/instructor/create-event',
      component: FuseLoadable({
        loader: () => import('./InstructorCreateEvent'),
        // loader: () => import("./InstructorResources")
      }),
    },
    {
      path: '/instructor/create-video',
      component: FuseLoadable({
        loader: () => import('./InstructorCreateVideo'),
        // loader: () => import("./InstructorResources")
      }),
    },
    {
      path: '/instructor/my_book',
      component: FuseLoadable({
        loader: () => import('./MyBook/MyBook'),
      }),
    },
    {
      path: '/instructor/manage_book/:isbn',
      component: FuseLoadable({
        loader: () => import('./MyBook/BookDetailsDashboard'),
      }),
    },
    {
      path: '/mybook/book-buyer',
      component: FuseLoadable({
        loader: () => import('./MyBook/BookDetailsBuyer'),
      }),
    },
    {
      path: '/mybook/book-earnings',
      component: FuseLoadable({
        loader: () => import('./MyBook/BookDetailsEarnings'),
      }),
    },
    {
      path: '/instructor/book_review/:isbn',
      component: FuseLoadable({
        loader: () => import('./MyBook/BookDetailstReview'),
      }),
    },
    {
      path: '/instructor/event_review/:slug',
      component: FuseLoadable({
        loader: () => import('./event/InstructorEventReview'),
      }),
    },
    {
      path: '/instructor/event-review',
      component: FuseLoadable({
        loader: () => import('./event/InstructorEventReview'),
      }),
    },
    {
      path: '/instructor/my_video',
      component: FuseLoadable({
        loader: () => import('./video/InstructorVideo'),
      }),
    },
    {
      path: '/instructor/my_event',
      component: FuseLoadable({
        loader: () => import('./event/EventList'),
      }),
    },
    {
      path: '/instructor/event',
      component: FuseLoadable({
        loader: () => import('./event/EventList'),
      }),
    },
    /* {
      path: '/instructor/manage_event/:slug',
      component: FuseLoadable({
        loader: () => import('./event/InstructorEventDashboard'),
      }),
    }, */
    {
      path: '/instructor/event-dashboard',
      component: FuseLoadable({
        loader: () => import('./event/Event'),
      }),
    },
    {
      path: '/instructor/event-attendance',
      component: FuseLoadable({
        loader: () => import('./event/Event'),
      }),
    },
    {
      path: '/instructor/event-earning',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeEarning'),
      }),
    },
    {
      path: '/instructor/event-attendance',
      component: FuseLoadable({
        loader: () => import('./event/InstructorEventAttendance'),
      }),
    },
    {
      path: '/instructor/event-earning',
      component: FuseLoadable({
        loader: () => import('./event/InstructorEventEarning'),
      }),
    },
    {
      path: '/instructor/create-book',
      component: FuseLoadable({
        loader: () => import('./InstructorCreateBook'),
      }),
    },
    {
      path: '/instructor/create-ebook',
      component: FuseLoadable({
        loader: () => import('./InstructorCreateEbook'),
      }),
    },
    {
      path: '/instructor/create-audiobook',
      component: FuseLoadable({
        loader: () => import('./InstructorCreateAudioBook'),
      }),
    },
    {
      path: '/instructor/practice',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeTest'),
      }),
    },
    {
      path: '/instructor/practice-dashboard/:slug',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeDashboard'),
      }),
    },
    {
      path: '/instructor/practice-student',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeStudent'),
      }),
    },
    {
      path: '/instructor/practice-earning/:slug',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeEarning'),
      }),
    },
    {
      path: '/instructor/practice-review/:slug',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeReview'),
      }),
    },
    {
      path: '/instructor/practice-test/:slug',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeDetail'),
      }),
    },
    {
      path: '/instructor/practice-landing-page/:slug',
      component: FuseLoadable({
        loader: () => import('./practice/manage/InstructorManagePractice'),
      }),
    },
    {
      path: '/instructor/practice-price-coupons/:slug',
      component: FuseLoadable({
        loader: () =>
          import('./practice/manage/InstructorManagePracticePriceCoupons'),
      }),
    },
    {
      path: '/instructor/practice-questions/:slug',
      component: FuseLoadable({
        loader: () =>
          import('./practice/manage/InstructorManagePracticeQuestion'),
      }),
    },
    {
      path: '/instructor/practice-grades/:slug',
      component: FuseLoadable({
        loader: () => import('./practice/InstructorPracticeGrades'),
      }),
    },
    {
      path: '/instructor/resources',
      component: FuseLoadable({
        loader: () => import('./InstructorResources'),
      }),
    },
    {
      path: '/instructor/micro-learning',
      component: FuseLoadable({
        loader: () => import('./MicroLearning/MicroLearningList'),
      }),
    },
  ],
}
