import * as Actions from '../actions'

const initialState = {
  success: false,
  error: {
    username: null,
    password: null,
  },
}

const login = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN: {
      return {
        ...state,
        userData: action.payload,
      }
    }
    case Actions.USER_LOGGED_OUT: {
      return initialState
    }
    case Actions.FORGOT_PASSWORD: {
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default login
