import React from 'react'
import {
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormGroup,
  Radio,
  Checkbox,
} from '@material-ui/core'
import DOMPurify from 'dompurify'

const Questions = props => {
  return (
    <>
      {props.data.map((question, index) => (
        <div className="flex flex-col mb-12">
          <div className="flex items-center">
            <Typography variant="body1" className="mr-12">
              {index + 1}.
            </Typography>
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.question),
              }}
            />
          </div>

          <FormControl component="fieldset">
            {question.question_type === 0 ? (
              <RadioGroup
                aria-label={`question-${index + 1}`}
                defaultValue="true"
                name="radio-buttons-group"
                aria-readonly={true}
              >
                {question.microlearning_question_answers.map(
                  (answer, index) => (
                    <FormControlLabel
                      value={JSON.stringify(answer.is_correct)}
                      control={<Radio disabled={true} />}
                      label={answer.answer}
                      className="text-grey-dark"
                      key={index}
                    />
                  )
                )}
              </RadioGroup>
            ) : (
              <FormGroup>
                {question.microlearning_question_answers.map(
                  (answer, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={answer.is_correct}
                          name={answer.answer}
                          disabled={true}
                        />
                      }
                      label={answer.answer}
                      className="text-grey-dark"
                      key={index}
                    />
                  )
                )}
              </FormGroup>
            )}
          </FormControl>
        </div>
      ))}
    </>
  )
}
const MicroLearningQuestion = props => {
  return (
    <div className="mx-16 my-32">
      <Questions
        data={props.location.state.microLearning.microlearning_questions}
      />
    </div>
  )
}

export default MicroLearningQuestion
