import React, {useState} from 'react'
import {
  Chip,
  Icon,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import {selectProps} from '../../../../../../utils/helpers'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_GLOBAL_LANGUAGES,
  GET_SUB_AND_CATEGORIES,
} from '../../../../../../graphql/queries/microlearning'
import global from '../../../../../../global-variable'
import Axios from 'axios'
import {LEVEL_SKILL} from 'app/constants'
import ValidatedTextField from '../../../shared-components/global/ValidatedTextField'

export default function ManageLanding({
  isError,
  style,
  value,
  handleChange,
  setValue,
  isSlugExist,
  setAlert,
}) {
  const [addTopic, setAddTopic] = useState(false)
  const [tempTopic, setTempTopic] = useState('')
  const [loadingUpload, setLoading] = useState(false)

  const {data: dataLanguages} = useQuery(GET_GLOBAL_LANGUAGES)
  const {data: dataCategories} = useQuery(GET_SUB_AND_CATEGORIES)

  const handleTopic = () => {
    setAddTopic(!addTopic)
    setTempTopic('')
  }

  const onSubmitTopic = () => {
    const newTopic = value.topic
    newTopic.push(tempTopic)
    setValue({...value, topic: newTopic})
    handleTopic()
  }

  const handleDeleteTopic = top => {
    const newTopic = value.topic.filter(t => t !== top)
    setValue({...value, topic: newTopic})
  }

  const handleChangeFile = async e => {
    const tempFile = Array.from(e.target.files)
    const val = tempFile[0]
    if (val.size > 1000 * 1000 * 25) {
      setAlert({
        open: true,
        message: "File size can't be more than 25MB",
      })
      setTimeout(() => {
        setAlert({
          open: false,
          message: '',
        })
      }, 2000)
    } else {
      const formData = new FormData()
      formData.append('file', val)
      try {
        const response = await Axios.post(global.file.dev, formData, {
          onUploadProgress: () => {
            // const loads = Math.round(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // )
            setLoading(true)
          },

          headers: {
            Authorization: 'Bearer ' + global.access_token,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials:true,
        })
        setValue({...value, banner_url: response.data.url})
        setLoading(false)
      } catch (e) {
        return e
      }
    }
  }

  return (
    <>
      <div className="mb-20">
        <Typography variant="body2">Judul Pembelajaran Mikro*</Typography>
        <ValidatedTextField
          variant="outlined"
          margin="normal"
          placeholder="Tambah judul pembelajaran mikro"
          fullWidth
          value={value.name}
          onChange={handleChange('name')}
          error={isError && !value.name}
          helperText={isError && !value.name && 'Bagian ini diperlukan'}
        />
      </div>
      <div className="mb-20">
        <Typography variant="body2">Subjudul Pembelajaran Mikro</Typography>
        <ValidatedTextField
          variant="outlined"
          margin="normal"
          placeholder="Tambah subjudul pembelajaran mikro"
          fullWidth
          value={value.subtitle}
          onChange={handleChange('subtitle')}
        />
      </div>
      <div className="mb-20">
        <Typography variant="body2">Penanda Pembelajaran Mikro*</Typography>
        <ValidatedTextField
          variant="outlined"
          margin="normal"
          placeholder="Tambah penanda pembelajaran mikro"
          fullWidth
          value={value.slug}
          onChange={handleChange('slug')}
          error={isError && !value.slug}
        />
        <Typography
          variant="body2"
          style={{
            color: isSlugExist || !value.slug ? 'red' : '#4CAF50',
          }}
          className="mt-6"
        >
          {isSlugExist
            ? 'Penanda sudah ada'
            : (isError && !value.slug) || !value.slug
            ? 'Bagian ini diperlukan'
            : 'Ketersediaan Penanda'}
        </Typography>
      </div>
      <div className="mb-20">
        <Typography variant="body2">Keterangan</Typography>
        <ValidatedTextField
          variant="outlined"
          margin="normal"
          placeholder="Tambahkan keterangan pembelajaran mikro "
          fullWidth
          multiline
          rows="5"
          value={value.description}
          onChange={handleChange('description')}
        />
      </div>
      <div className="mb-20 w-1/3">
        <Typography variant="body2">Bahasa*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          value={value.language_id}
          onChange={handleChange('language_id')}
          SelectProps={selectProps(value.language_id)}
          fullWidth
          error={isError && !value.language_id}
          helperText={isError && !value.language_id && 'Bagian ini diperlukan'}
        >
          <MenuItem value={null}>Pilih Bahasa</MenuItem>
          {dataLanguages &&
            dataLanguages.global_languages.map(d => (
              <MenuItem key={d.id} value={d.id}>
                {d.name}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className="mb-20 w-1/3">
        <Typography variant="body2">Level*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          fullWidth
          value={value.level}
          onChange={handleChange('level')}
          SelectProps={selectProps(value.level)}
          error={isError && !value.level}
          helperText={isError && !value.level && 'Bagian ini diperlukan'}
        >
          <MenuItem value={null} disabled>
            Pilih Level
          </MenuItem>
          <MenuItem value={1}>{LEVEL_SKILL.beginner}</MenuItem>
          <MenuItem value={2}>{LEVEL_SKILL.intermediate}</MenuItem>
          <MenuItem value={3}>{LEVEL_SKILL.advanced}</MenuItem>
        </TextField>
      </div>
      <div className="mb-20 w-1/3">
        <Typography variant="body2">Kategori*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          fullWidth
          value={value.category_id}
          onChange={handleChange('category_id')}
          SelectProps={selectProps(value.category_id)}
          error={isError && !value.category_id}
          helperText={isError && !value.category_id && 'Bagian ini diperlukan'}
        >
          <MenuItem value={null} disabled>
            Pilih Kategori
          </MenuItem>
          {dataCategories &&
            dataCategories.academy_course_categories.map(d => (
              <MenuItem key={d.id} value={d.id}>
                {d.name}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className="mb-20 w-1/3">
        <Typography variant="body2">Sub Kategori*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          fullWidth
          value={value.subcategory}
          onChange={handleChange('subcategory')}
          SelectProps={selectProps(value.subcategory)}
          error={isError && !value.subcategory}
          helperText={isError && !value.subcategory && 'Bagian ini diperlukan'}
        >
          <MenuItem value={null} disabled>
            Pilih Sub Kategori
          </MenuItem>
          {dataCategories &&
            value.category_id &&
            dataCategories.academy_course_categories
              .filter(d => d.id === value.category_id)[0]
              .subcategory.map(d => (
                <MenuItem key={d.id} value={d.id}>
                  {d.name}
                </MenuItem>
              ))}
        </TextField>
      </div>
      <div className="mb-28">
        <Typography variant="body2">Topik*</Typography>
        <div className="flex flex-wrap items-center">
          {value.topic &&
            value.topic.map((top, i) => {
              return (
                <Chip
                  key={i}
                  className="mt-16 mr-16"
                  label={top}
                  style={style.chipSkyBlue}
                  color="secondary"
                  onDelete={() => handleDeleteTopic(top)}
                />
              )
            })}
          {addTopic ? (
            <>
              <ValidatedTextField
                variant="outlined"
                placeholder="Tambah topik"
                margin="normal"
                value={tempTopic}
                onChange={e => setTempTopic(e.target.value)}
              />
              <IconButton
                style={tempTopic ? style.iconSkyBlue : style.iconDisabled}
                size="small"
                onClick={onSubmitTopic}
                className="mx-16"
                disabled={!tempTopic}
              >
                <Icon>check</Icon>
              </IconButton>
              <IconButton
                style={style.iconRed}
                size="small"
                onClick={handleTopic}
              >
                <Icon>close</Icon>
              </IconButton>
            </>
          ) : (
            <IconButton
              style={style.iconSkyBlue}
              size="small"
              onClick={handleTopic}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
        </div>
        {isError && value.topic.length === 0 && (
          <Typography
            variant="caption"
            style={{
              color: 'red',
            }}
            className="mt-12"
          >
            Bagian ini diperlukan
          </Typography>
        )}
      </div>
      <div className="mb-20">
        <Typography variant="body2" className="mb-16">
          Gambar Pembelajaran Mikro
        </Typography>
        <Typography variant="body2" style={style.grey} gutterBottom>
          Tipe Berkas: jpg, jpeg, png
        </Typography>
        <Typography variant="body2" style={style.grey} gutterBottom>
          Ukuran Maksimal 25MB
        </Typography>
        {value.banner_url ? (
          <div className="flex mt-40 items-start">
            <img src={value.banner_url} alt="attch" style={style.uploadedImg} />
            <IconButton onClick={() => setValue({...value, banner_url: ''})}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        ) : (
          <div style={style.addContainer}>
            <Icon
              style={{
                ...style.grey,
                fontSize: 40,
                marginBottomn: 11,
              }}
            >
              add
            </Icon>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Tambah gambar pembelajaran mikro
            </Typography>
            <input
              id="uploadImage"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              // className="hidden"
              style={style.input}
              onChange={handleChangeFile}
            />
          </div>
        )}
        {loadingUpload && (
          <div style={{width: 400}} className="text-center">
            <LinearProgress variant="indeterminate" className="mt-16 mb-8" />
            <Typography variant="body2">Uploading image ...</Typography>
          </div>
        )}
      </div>
    </>
  )
}
