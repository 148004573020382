import gql from 'graphql-tag'

export const GET_CONTRIBUTOR_INSTRUCTOR = gql`
  query(
    $all_offset: Int
    $all_limit: Int
    $mentor_limit: Int
    $mentor_offset: Int
    $instructor_offset: Int
    $instructor_limit: Int
    $coach_limit: Int
    $coach_offset: Int
    $expert_limit: Int
    $expert_offset: Int
    $trainer_offset: Int
    $trainer_limit: Int
  ) {
    all: learning_contributor_ratings(
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $all_limit
      offset: $all_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          title: name
          thumbnail: avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
    mentors: learning_contributor_ratings(
      where: {global_user: {contributor_label: {_eq: "MENTOR"}}}
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $mentor_limit
      offset: $mentor_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          title: name
          thumbnail: avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
    instructors: learning_contributor_ratings(
      where: {global_user: {contributor_label: {_eq: "INSTRUCTOR"}}}
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $instructor_limit
      offset: $instructor_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          title: name
          thumbnail: avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
    coaches: learning_contributor_ratings(
      where: {global_user: {contributor_label: {_eq: "COACH"}}}
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $coach_limit
      offset: $coach_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          title: name
          thumbnail: avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
    experts: learning_contributor_ratings(
      where: {global_user: {contributor_label: {_eq: "EXPERT"}}}
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $expert_limit
      offset: $expert_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          name
          avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
    trainer: learning_contributor_ratings(
      where: {global_user: {contributor_label: {_eq: "TRAINER"}}}
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $trainer_limit
      offset: $trainer_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          name
          avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
  }
`

export const GET_CONTRIBUTOR_MENTOR = gql`
  query {
    global_users(
      where: {contributor_label: {_eq: "MENTOR"}}
      order_by: [
        {academy_courses_aggregate: {sum: {count_enrollment: desc_nulls_last}}}
      ]
    ) {
      id
      name
      avatar
      academy_courses_aggregate {
        aggregate {
          count
          avg {
            average_rating
          }
          sum {
            count_enrollment
          }
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_COACH = gql`
  query {
    global_users(
      where: {contributor_label: {_eq: "COACH"}}
      order_by: [
        {academy_courses_aggregate: {sum: {count_enrollment: desc_nulls_last}}}
      ]
    ) {
      id
      name
      avatar
      academy_courses_aggregate {
        aggregate {
          count
          avg {
            average_rating
          }
          sum {
            count_enrollment
          }
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_EXPERT = gql`
  query {
    global_users(
      where: {contributor_label: {_eq: "EXPERT"}}
      order_by: [
        {academy_courses_aggregate: {sum: {count_enrollment: desc_nulls_last}}}
      ]
    ) {
      id
      name
      avatar
      academy_courses_aggregate {
        aggregate {
          count
          avg {
            average_rating
          }
          sum {
            count_enrollment
          }
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_TRAINER = gql`
  query {
    global_users(
      where: {contributor_label: {_eq: "TRAINER"}}
      order_by: [
        {academy_courses_aggregate: {sum: {count_enrollment: desc_nulls_last}}}
      ]
    ) {
      id
      name
      avatar
      academy_courses_aggregate {
        aggregate {
          count
          avg {
            average_rating
          }
          sum {
            count_enrollment
          }
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_CROSS_MENTORING = gql`
  query {
    global_users(
      where: {
        classroom_members: {
          is_admin: {_eq: true}
          classroom: {versioning_id: {_is_null: false}}
        }
      }
    ) {
      id
      name
      avatar
      people_work_placements {
        company_profile {
          brand_name
          legal_name
        }
        company_job_profile {
          position
          title
        }
      }
      # Data review perorangan
      classrooms_aggregate(
        where: {classroom_members: {is_admin: {_eq: true}}}
      ) {
        nodes {
          classroom_reviews_aggregate {
            aggregate {
              avg {
                star
              }
              count
            }
          }
        }
      }
      # Data per classroom
      classrooms(where: {classroom_members: {is_admin: {_eq: true}}}) {
        id
        name
        classroom_reviews_aggregate {
          aggregate {
            avg {
              star
            }
            count
          }
        }
      }
    }
  }
`

export const GET_COUNT_CONTRIBUTOR = gql`
  query {
    all_count: learning_contributor_ratings_aggregate {
      aggregate {
        count
      }
    }
    coach_count: learning_contributor_ratings_aggregate(
      where: {global_user: {contributor_label: {_eq: "COACH"}}}
    ) {
      aggregate {
        count
      }
    }
    mentor_count: learning_contributor_ratings_aggregate(
      where: {global_user: {contributor_label: {_eq: "MENTOR"}}}
    ) {
      aggregate {
        count
      }
    }
    instructor_count: learning_contributor_ratings_aggregate(
      where: {global_user: {contributor_label: {_eq: "INSTRUCTOR"}}}
    ) {
      aggregate {
        count
      }
    }
    trainer_count: learning_contributor_ratings_aggregate(
      where: {global_user: {contributor_label: {_eq: "TRAINER"}}}
    ) {
      aggregate {
        count
      }
    }
    expert_count: learning_contributor_ratings_aggregate(
      where: {global_user: {contributor_label: {_eq: "EXPERT"}}}
    ) {
      aggregate {
        count
      }
    }
  }
`
