import gql from 'graphql-tag'

export const GET_WISHLIST_EVENT = gql`
  query($limit: Int, $offset: Int, $query: String) {
    event_wishlists(
      offset: $offset
      limit: $limit
      where: {event_schedule: {title: {_ilike: $query}}}
    ) {
      id
      event_schedule {
        id
        type
        category
        title
        slug
        thumbnail
        description
        location_info
        pricing
        datestart
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`
