import React, {useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Tabs, Tab} from '@material-ui/core'

const styles = () => ({
  tabsIndicator: {
    backgroundColor: '#38aae6',
  },
})

const LearningContributorTabs = (props) => {
  const {classes} = props

  const [tabValue, setTabValue] = useState(0)

  return (
    <Tabs
      value={tabValue}
      // eslint-disable-next-line
      onChange={(_, value) => setTabValue(value)}
      classes={{indicator: classes.tabsIndicator}}
      centered
    >
      <Tab
        label="Detail Learning Contributor"
        className="capitalize text-black"
      />
    </Tabs>
  )
}

export default withStyles(styles)(LearningContributorTabs)
