import React from 'react'
import {Button, Typography, Tooltip} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import Navigation from '../MicroLearningNavigation'
import Dashboard from './dashboard/Dashboard'
import Learner from './learner/LearnerComponent'
import Earning from './earning/EarningComponent'
import Grades from './grades/GradesComponent'
import MicroLearning from './micro-learning/MicroLearningComponent'
import Review from './review/ReviewComponent'
import Comment from './comment/ChatComponent'

const btnManageStyle = {
  backgroundColor: '#039BE5',
  color: '#fff',
  textTransform: 'none',
}

const DashboardComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }
  const {location} = props

  const renderComponent = activeState => {
    switch (activeState) {
      case 'micro-learnings-dashboard':
        return <Dashboard {...props} />
      case 'micro-learnings-learner':
        return <Learner {...props} />
      case 'micro-learnings-earnings':
        return <Earning {...props} />
      case 'micro-learnings-questions':
        return <MicroLearning {...props} />
      case 'micro-learnings-grades':
        return <Grades {...props} />
      case 'micro-learnings-review':
        return <Review {...props} />
      case 'micro-learnings-comment':
        return <Comment {...props} />
      default:
        return <Dashboard {...props} />
    }
  }

  const btnDisabled =
    location.state.microLearning.status === 'PENDING' ||
    location.state.microLearning.status === 'ACTIVE'

  return (
    <div className="w-full" id="main-container">
      <div
        className="md:px-64 px-16 py-16 flex flex-row items-center fixed"
        style={{backgroundColor: '#fff', width: '-webkit-fill-available'}}
      >
        <div className="w-1/4">
          <img
            src={'assets/images/logos/wlb-combined-green.svg'}
            alt="wlb-logo"
            className="w-128"
          />
        </div>

        <div className="w-full text-center">
          <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
            {location.state.microLearning.name}
          </Typography>
        </div>

        <div className="w-1/4 text-right">
          {btnDisabled ? (
            <Tooltip
              title="Manage micro learning is available for status draft only"
              placement="top"
            >
              <Button
                variant="contained"
                color="secondary"
                style={btnManageStyle}
                className="cursor-not-allowed"
              >
                Kelola
              </Button>
            </Tooltip>
          ) : (
            <Button
              component={Link}
              to={
                '/instructor/micro-learnings/manage/' + state.microLearning.id
              }
              variant="contained"
              color="secondary"
              style={btnManageStyle}
            >
              Kelola
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-row mt-60">
        <div className="flex ml-48 w-1/4">
          <div className="flex flex-wrap w-full md:w-320 pt-16">
            <div className="mb-32 w-full ">
              <div className="w-full p-16">
                <Navigation
                  // handleSelectedLesson={this.handleSelectedLesson}
                  {...props}
                  path={props.match.path}
                  // quizSubmission={submissions}
                  // slug={slug}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full mt-32 mx-40 flex-col">
          {renderComponent(active, state)}
        </div>
      </div>
    </div>
  )
}

export default withRouter(DashboardComponent)
