import React, {useEffect, useState} from 'react'
import {Divider, Paper} from '@material-ui/core'

import ExpansionFilter from './ExpansionFilter'

export default function SearchFilter(props) {
  const {
    categories,
    topics,
    levels,
    languages,
    ratings,
    prices,
    durations,
    types,
    onChange,
    hasSubCategories,
    isOpenRangePrice,
    value,
  } = props

  const [expanded, setExpanded] = useState({
    category: true,
    topic: true,
    level: true,
    language: true,
    price: true,
    ratings: true,
    duration: true,
    type: true,
  })
  const [subExpanded, setSubExpanded] = useState({})
  const [moreOptions, setMoreOptions] = useState({
    category: {isHidden: false, maxLength: 4},
    topic: {isHidden: false, maxLength: 4},
  })

  useEffect(() => {
    if (hasSubCategories && categories.length > 0) {
      const objCategory = {}
      categories.forEach((category) => {
        objCategory[category.value] = false
      })
      setSubExpanded(objCategory)
    }
  }, [categories])

  const handlePanel = (panel) => {
    setExpanded((prevState) => ({...prevState, [panel]: !expanded[panel]}))
  }

  const handleSubPanel = (subPanel) => {
    setSubExpanded((prevState) => ({
      ...prevState,
      [subPanel]: !expanded[subPanel],
    }))
  }

  const handleSeeMore = (e, panel, maxLength) => {
    e.preventDefault()
    setMoreOptions((prevState) => ({
      ...prevState,
      [panel]: {isHidden: true, maxLength},
    }))
  }

  return (
    <Paper>
      {categories && (
        <>
          <ExpansionFilter
            expanded={expanded.category}
            subExpanded={subExpanded}
            onChangePanel={() => handlePanel('category')}
            onChangeSubPanel={handleSubPanel}
            summary="Category"
            details={categories}
            onChangeCheckbox={onChange}
            moreOptions={{
              onClick: (e) => handleSeeMore(e, 'category', categories.length),
              isHidden: moreOptions.category.isHidden,
              maxLength: moreOptions.category.maxLength,
            }}
            hasSubCategories={hasSubCategories}
          />
          <Divider />
        </>
      )}
      {topics && (
        <>
          <ExpansionFilter
            expanded={expanded.topic}
            onChangePanel={() => handlePanel('topic')}
            summary="Topic"
            details={topics}
            onChangeCheckbox={onChange}
            moreOptions={{
              onClick: (e) => handleSeeMore(e, 'topic', topics.length),
              isHidden: moreOptions.topic.isHidden,
              maxLength: moreOptions.topic.maxLength,
            }}
          />
          <Divider />
        </>
      )}
      {levels && (
        <>
          <ExpansionFilter
            expanded={expanded.level}
            onChangePanel={() => handlePanel('level')}
            summary="Level"
            details={levels}
            onChangeCheckbox={onChange}
          />
          <Divider />
        </>
      )}
      {languages && (
        <>
          <ExpansionFilter
            expanded={expanded.language}
            onChangePanel={() => handlePanel('language')}
            summary="Language"
            details={languages}
            onChangeCheckbox={onChange}
          />
          <Divider />
        </>
      )}
      {prices && (
        <>
          <ExpansionFilter
            expanded={expanded.price}
            onChangePanel={() => handlePanel('price')}
            summary="Price"
            details={prices}
            onChangeCheckbox={onChange}
            rangeInputNumber={{
              isOpen: isOpenRangePrice,
              onChangeNumberMin: onChange,
              onChangeNumberMax: onChange,
            }}
          />
          <Divider />
        </>
      )}
      {types && (
        <>
          <ExpansionFilter
            expanded={expanded.type}
            onChangePanel={() => handlePanel('type')}
            summary="Type"
            details={types}
            onChangeCheckbox={onChange}
          />
          <Divider />
        </>
      )}
      {ratings && (
        <>
          <ExpansionFilter
            expanded={expanded.ratings}
            onChangePanel={() => handlePanel('ratings')}
            summary="Ratings"
            isRating
            details={ratings}
            onChangeRadio={onChange}
            value={value}
          />
          <Divider />
        </>
      )}
      {durations && (
        <>
          <ExpansionFilter
            expanded={expanded.duration}
            onChangePanel={() => handlePanel('duration')}
            summary="Duration"
            details={durations}
            onChangeCheckbox={onChange}
          />
        </>
      )}
    </Paper>
  )
}
