import React from 'react'
import {Paper, Typography, Divider, Tabs, Tab} from '@material-ui/core'
import Question from './MicroLearningQuestion'
import Response from './MicroLearningResponse'

const styles = {
  tealFont: {
    color: '#003d51',
  },
}

const MicroLearningComponent = props => {
  const [tabValue, setTabValue] = React.useState(0)
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Paper className="w-full mb-28" style={{minHeight: '70vh'}}>
      <div className="flex flex-col">
        <Typography
          className="my-20 mx-16 font-bold text-blue-dark text-16"
          style={styles}
        >
          Pembelajaran Mikro
        </Typography>
        <Divider />
        <div>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            className="mb-12"
            style={{background: '#eff2f4'}}
          >
            <Tab label="Pertanyaan " style={{textTransform: 'none'}} />
            <Tab label="Respon" style={{textTransform: 'none'}} />
          </Tabs>
          {tabValue === 0 ? <Question {...props} /> : <Response {...props} />}
        </div>
      </div>
    </Paper>
  )
}

export default MicroLearningComponent
