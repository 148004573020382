import gql from 'graphql-tag'

export const GET_SOCIAL_LEARNING = gql`
  query(
    $userId: uuid!
    $onlyFinished: Boolean
    $keyword: String
    $price: Int_comparison_exp
    $categoryIds: [Int!]
    $orderBy: [classrooms_order_by!]
    $offset: Int
    $limit: Int
  ) {
    classrooms(
      order_by: $orderBy
      where: {
        price: $price
        classroom_members: {member_user_id: {_eq: $userId}}
        is_finished: {_eq: $onlyFinished}
        name: {_ilike: $keyword}
        category_id: {_in: $categoryIds}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      is_finished
      academy_course_category {
        code
        name
      }
      #Classroom Owner
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          # Review count
          count
          avg {
            # Average Review
            star
          }
        }
      }
      learning_contributors: classroom_members(
        where: {
          _or: [{is_individual_mentor: {_eq: true}}, {is_mentor: {_eq: true}}]
        }
      ) {
        global_user {
          id
          name
          avatar
        }
      }
      members: classroom_members(
        where: {
          is_mentor: {_eq: false}
          is_individual_mentor: {_eq: false}
          is_admin: {_eq: false}
        }
      ) {
        id
        user: global_user {
          id
          avatar
          name
        }
      }
    }
  }
`
