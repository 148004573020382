import axios from 'axios'
import {axiosInstanceBearer} from 'app/services/restApiService'
import global from 'app/global-variable'
import _ from 'lodash'
import history from 'history.js'
import moment from 'moment/moment'

export const GET_WIDGETS = '[ANALYTICS DASHBOARD APP] GET WIDGETS'
export const GET_DASHBOARD_STATISTICS =
  '[ANALYTICS DASHBOARD APP] GET DASHBOARD STATISTICS'
export const GET_DASHBOARD_INSTRUCTOR_COURSES =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD COURSES'
export const GET_DASHBOARD_INSTRUCTOR_LESSONS =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD LESSONS'
export const GET_DASHBOARD_INSTRUCTOR_ENROLLMENTS =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD ENROLLMENTS'
export const GET_DASHBOARD_INSTRUCTOR_USERS =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD USERS'
export const GET_DASHBOARD_INSTRUCTOR_OVERALL_RATINGS =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD OVERALL RATINGS'
export const GET_DASHBOARD_INSTRUCTOR_TOP_COURSES =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD TOP COURSES'
export const GET_DASHBOARD_INSTRUCTOR_LATEST_COMMENTS =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD LATEST COMMENTS'
export const GET_DASHBOARD_INSTRUCTOR_ENROLLMENTS_CHART =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD ENROLLMENTS CHART'
export const GET_DASHBOARD_INSTRUCTOR_REVENUES_CHART =
  '[INSTRUCTOR DASHBOARD] GET DASHBOARD REVENUES CHART'
export const GET_QUIZ = '[INSTRUCTOR DASHBOARD] GET QUIZ'
export const SAVE_QUIZ = '[INSTRUCTOR DASHBOARD] SAVE QUIZ'
export const SAVE_GRADING_MANUAL = '[INSTRUCTOR DASHBOARD] SAVE_GRADING_MANUAL'
export const REMOVE_QUIZ = '[INSTRUCTOR DASHBOARD] REMOVE QUIZ'
export const GET_EVENT_BY_SLUG = '[INSTRUCTOR DASHBOARD] GET EVENT BY SLUG'
export const GET_EVENT_REVIEWS = '[INSTRUCTOR DASHBOARD] GET EVENT REVIEWS'
export const GET_BOOK_BY_ISBN = '[INSTRUCTOR DASHBOARD] GET_BOOK_BY_ISBN'
export const GET_BOOK_REVIEWS = '[INSTRUCTOR DASHBOARD] GET BOOK REVIEWS'
export const SET_USER_DATA = '[USER] SET DATA'
export const GET_QUESTIONS_BY_LESSON_ID =
  '[INSTRUCTOR MANAGE COURSE] GET QUESTIONS BY LESSON ID'
export const GET_QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID =
  '[INSTRUCTOR MANAGE COURSE] GET QUESTIONS SUBMISSION BY LESSON ID STUDENT ID'

export function getWidgets() {
  const request = axios.get('/api/analytics-dashboard-app/widgets',{
    withCredentials: true
  })

  return dispatch =>
    request.then(response => {
      dispatch({
        type: GET_WIDGETS,
        payload: response.data,
      })
      dispatch({
        type: SET_USER_DATA,
        payload: global.data_user.chat,
      })
    })
}

export function getDashboardStatistics() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/dashboard/get',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_STATISTICS,
        payload: response.data,
      })
    )
}

export function getDashboardInstructorCourses() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/dashboard/course',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_COURSES,
        payload: response.data,
      })
    )
}

export function getDashboardInstructorLessons() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/dashboard/lesson',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_LESSONS,
        payload: response.data,
      })
    )
}

export function getDashboardInstructorEnrollments() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token, //a
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/dashboard/enrollment',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_ENROLLMENTS,
        payload: response.data,
      })
    )
}

export function getDashboardInstructorUsers() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/dashboard/user',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_USERS,
        payload: response.data,
      })
    )
}

export function getDashboardInstructorOverallRatings() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/instructor/' + global.data_user.id,
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_OVERALL_RATINGS,
        payload: response.data,
      })
    )
}

export function getDashboardInstructorTopCourses(seeLess) {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}
  let queryCompletion = null
  seeLess
    ? (queryCompletion =
        '/course?orders[0][0]=averageRating&orders[0][1]=desc&limit=5')
    : (queryCompletion = '/course?orders[0][0]=averageRating&orders[0][1]=desc')

  const request = axiosInstanceBearer.get(
    global.api.dev +
      '/academy/instructor/' +
      global.data_user.id +
      queryCompletion,
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response => {
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_TOP_COURSES,
        payload: response.data,
      })
    })
}

export function getDashboardInstructorLatestComments() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/dashboard/discussion?startdate=2019-02-01',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response => {
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_LATEST_COMMENTS,
        payload: response.data,
      })
    })
}

export function getDashboardInstructorEnrollmentsChart(filter) {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev +
      '/academy/dashboard/dailyEnrollment?startdate=' +
      moment()
        .subtract(filter === 'week' ? 7 : 30, 'd')
        .format('YYYY-MM-DD') +
      '&enddate=' +
      moment().format('YYYY-MM-DD'),
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_ENROLLMENTS_CHART,
        payload: response.data,
      })
    )
}

export function getDashboardInstructorRevenuesChart(filter) {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev +
      '/academy/dashboard/dailyRevenue?startdate=' +
      moment()
        .subtract(filter === 'week' ? 7 : 30, 'd')
        .format('YYYY-MM-DD') +
      '&enddate=' +
      moment().format('YYYY-MM-DD'),
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_INSTRUCTOR_REVENUES_CHART,
        payload: response.data,
      })
    )
}

/* export function getQuiz() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  console.log('getQuiz')

  const bodyParameters = {}

  const request = axios.get(
    global.api.dev + '/academy/question/get',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_QUIZ,
        payload: response.data,
      })
    )
} */

export function getQuestionsByLessonId(lessonId) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/lesson/' + lessonId + '/question',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_QUESTIONS_BY_LESSON_ID,
        payload: response.data,
      })
    })
}

export function getQuizSubmissionByLessonIdStudentId(lessonId, studentId) {
  const request = axiosInstanceBearer.get(
    global.api.dev +
      '/academy/lesson/' +
      lessonId +
      '/question?student=' +
      studentId,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID,
        payload: response.data,
      })
    })
}

export function saveGradingManual(data) {
  const request = axios.post(
    global.api.dev +
      '/academy/lesson/' +
      data.lessonId +
      '/question/' +
      data.questionId +
      '/grade',
    {
      grade: data.grade,
      userId: data.userId,
      instructorComment: data.comment,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch({
        type: SAVE_GRADING_MANUAL,
        payload: response.data,
      })

      return dispatch(
        getQuizSubmissionByLessonIdStudentId(data.lessonId, data.userId)
      )
    })
}

export function getBookByISBN(isbn) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/book/' + isbn,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_BOOK_BY_ISBN,
        payload: response.data,
      })
    })
}

export function getBookReviews(isbn) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/book/' + isbn + '/review/get',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_BOOK_REVIEWS,
        payload: response.data,
      })
    })
}

export function getEventBySlug(slug) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/event/schedule/get/' + slug,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response) {
        return dispatch({
          type: GET_EVENT_BY_SLUG,
          payload: response.data,
        })
      }
    })
}

export function getEventReviews(event) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/event/schedule/' + event + '/review/get',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_EVENT_REVIEWS,
        payload: response.data,
      })
    })
}

export function saveQuiz(dataPost) {
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/question/create',
    dataPost,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        let disp = dispatch({
          type: SAVE_QUIZ,
        })

        dispatch(getQuestionsByLessonId(dataPost.lesson))

        return disp
      } else {
        dispatch({message: 'Error ' + response.status})
      }
    })
}

export function removeQuiz(questionId, lessonId) {
  const request = axiosInstanceBearer.delete(
    global.api.dev + '/academy/question/' + questionId + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )
  return dispatch =>
    request.then(response => {
      let disp = dispatch({
        type: REMOVE_QUIZ,
      })

      dispatch(getQuestionsByLessonId(lessonId))

      return disp
    })
}
