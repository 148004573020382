import * as Actions from '../actions'

const initialState = {
  courseBySlug: '',
  courseComments: '',
  statistics: null,
  data: '',
  quiz: '',
  questions: [],
}

const courseReducer = function (state = initialState, action) {
  // console.log('course.reducer.js')
  switch (action.type) {
    case Actions.GET_COURSE: {
      return {
        ...action.payload,
      }
    }
    case Actions.GET_COURSE_BY_SLUG: {
      return {
        ...state,
        courseBySlug: action.payload,
      }
    }
    case Actions.GET_COMMENTS: {
      return {
        ...state,
        courseComments: action.payload,
      }
    }
    case Actions.SAVE_COURSE: {
      return {
        ...action.payload,
      }
    }
    case Actions.GET_WIDGETS:
      return {
        ...state,
        data: action.payload,
      }
    case Actions.GET_DASHBOARD_STATISTICS:
      return {
        ...state,
        statistics: {...action.payload},
      }
    case Actions.UPDATE_COURSE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case Actions.UPDATE_COURSE_PROGRESS: {
      return {
        // ...action.payload
        update_course_progress: action.payload,
      }
    }
    case Actions.GET_QUIZ:
      return {
        ...state,
        quiz: action.payload.data,
      }
    case Actions.GET_QUESTIONS_BY_LESSON_ID: {
      return {
        ...state,
        questions: action.payload,
      }
    }
    case Actions.SAVE_QUIZ:
      return {
        ...state,
      }
    case Actions.REMOVE_QUIZ:
      return {
        ...state,
      }
    default: {
      return state
    }
  }
}

export default courseReducer
