import {FuseLoadable} from '@fuse'

const EventConfig = {
  settings: {
    layout: {
      style: 'layout4',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
          folded: false,
          position: 'left',
        },
        toolbar: {
          display: true,
          style: 'fixed',
          position: 'below',
        },
        footer: {
          display: false,
          style: 'static',
          position: 'below',
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/instructor/manage-event/:slug/dashboard',
      component: FuseLoadable({
        loader: () => import('./Event'),
      }),
    },
    {
      path: '/instructor/manage-event/:slug/review',
      component: FuseLoadable({
        loader: () => import('./Event'),
      }),
    },
    {
      path: '/instructor/control-event/:slug/landing',
      component: FuseLoadable({
        loader: () => import('./Control'),
      }),
    },
    {
      path: '/instructor/control-event/:slug/price-coupons',
      component: FuseLoadable({
        loader: () => import('./Control'),
      }),
    },
  ],
}

export default EventConfig
