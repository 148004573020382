import axios from 'axios'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable'
import {reactLocalStorage} from 'reactjs-localstorage'

export const LOGIN = '[ACADEMY APP] LOGIN'
export const FORGOT_PASSWORD = '[ACADEMY APP] FORGOT_PASSWORD'
export const UPDATE_COURSE = '[ACADEMY APP] UPDATE COURSE'
export const USER_LOGGED_OUT = '[ACADEMY APP] LOGGED OUT'
export const SET_USER_DATA = '[USER] SET DATA'

export function login(data) {
  return (dispatch) => {
    let payload
    const request = axios.post(global.api.dev + '/global/accounts/login', data, {
      withCredentials: true
    })

    /*const request = fetch(global.api.dev + '/global/accounts/login', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: {
        email: 'emilhamep@gmail.com',
        password: '123456!a',
      },
    })*/

    /*const request = superagent
      .post(global.api.dev + '/global/accounts/login')
      .send(data)*/

    if (data.email === '' || data.password === '') {
      return dispatch(
        showMessage({message: 'The email or password field cannot be empty.'})
      )
    }

    request
      .then((response) => {
        payload = response.data
        response.data.role = 'student'
        reactLocalStorage.setObject('userData', response.data)

        return axios.get(global.api.dev + '/global/chat/get', {
          headers: {
            Authorization: 'Bearer ' + payload.oauth.access_token,
          },
          withCredentials: true
        })
      })
      .then((res) => {
        reactLocalStorage.setObject('userChat', res.data)
        dispatch({
          type: SET_USER_DATA,
          payload: payload,
        })

        //history.push('/learning')
        window.location.href = '/learning'
        return dispatch(showMessage({message: 'Welcome ' + payload.name}))
      })
      .catch((error) => {
        //console.log(error)
        return dispatch(showMessage({message: 'Incorrect email or password'}))
      })
  }
}

export function logoutUser() {
  return (dispatch, getState) => {
    dispatch({
      type: USER_LOGGED_OUT,
    })

    reactLocalStorage.setObject('userData', null) //Sementara ada karena didalam programnya menggunakan ini untuk REST API

    // history.push('/')
  }
}

export function forgot(data) {
  return (dispatch) => {
    let payload
    const request = axios.post(
      global.api.dev + '/global/accounts/forgotpwd',
      data,{
        withCredentials: true
      }
    )

    if (data.email === '') {
      return dispatch(showMessage({message: 'Kolom email tidak boleh kosong'}))
    }

    request
      .then((res) => {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: payload,
        })

        return dispatch(
          showMessage({
            message: 'Please check your email for more information.',
          })
        )
      })
      .catch((error) => {
        //console.log(error)
        return dispatch(showMessage({message: 'Incorrect email or password'}))
      })
  }
}
