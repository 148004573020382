import gql from 'graphql-tag'

export const GET_INDIVIDUAL_CLASSROOM = gql`
  query($limit: Int, $offset: Int) {
    classrooms(
      limit: $limit
      offset: $offset
      where: {is_individual: {_eq: true}}
    ) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      is_finished
      learning_contributors: classroom_members(
        where: {
          _or: [{is_individual_mentor: {_eq: true}}, {is_mentor: {_eq: true}}]
        }
      ) {
        global_user {
          id
          name
          avatar
        }
      }
      members: classroom_members(
        where: {
          is_mentor: {_eq: false}
          is_individual_mentor: {_eq: false}
          is_admin: {_eq: false}
        }
      ) {
        id
        global_user {
          id
          avatar
          name
        }
      }
      academy_course_category {
        code
        name
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
    }
  }
`

export const GET_INDIVIDUAL_CLASSROOM_MENTOR = gql`
  query($limit: Int, $offset: Int) {
    classrooms(
      limit: $limit
      offset: $offset
      where: {is_individual: {_eq: true}}
    ) {
      id
      avatar_url
      name
      description
      rules
      is_finished
      mentor: classroom_members(where: {is_individual_mentor: {_eq: true}}) {
        is_individual_mentor
        is_admin
        global_user {
          id
          name
          avatar
        }
      }
      owner: classroom_members(where: {is_individual_mentor: {_eq: false}}) {
        is_individual_mentor
        is_admin
        global_user {
          id
          name
          avatar
        }
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
    }
  }
`
