import React from 'react'
import {Divider, Paper, Typography, Icon} from '@material-ui/core'

const styles = {
  tealFont: {
    color: '#003d51',
  },
}

const iconStyle = {
  fontSize: 180,
  color: '#003d51',
}

const DashboardRating = props => {
  return (
    <Paper>
      <div className="flex flex-col">
        <Typography
          className="my-20 mx-16 font-bold text-blue-dark text-16"
          style={styles}
        >
          Peringkat
        </Typography>
        <Divider className="mx-16" />
        <div className="p-16">
          <Typography className="font-bold text-48 my-12">
            {props.location.state.microLearning.microlearning_reviews_aggregate
              .aggregate.avg.star
              ? props.location.state.microLearning.microlearning_reviews_aggregate.aggregate.avg.star.toFixed(
                  1
                )
              : 0}
          </Typography>
          <div className="w-full flex justify-center">
            <Icon style={iconStyle}>show_chart</Icon>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default DashboardRating
