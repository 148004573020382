import React, {useState} from 'react'
import {
  Card,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Icon,
  Typography,
  Divider,
  Avatar,
} from '@material-ui/core'
import moment from 'moment'
import {FuseAnimate} from '@fuse'
import {convertToRupiah} from 'app/utils/helpers'

const RecentTransactionComponent = (props) => {
  // const {classes} = props
  const [searchKeyword, setSearcKeyword] = useState('')

  const renderStatus = (val) => {
    let color
    if (val.toLowerCase() === 'enrolled') {
      color = '#039BE5'
    } else if (val.toLowerCase() === 'completed') {
      color = '#FFA000'
    } else {
      color = '#4CAF50'
    }
    return (
      <Typography variant="body1" style={{color: color}}>
        {val}
      </Typography>
    )
  }

  const learnerList = [
    {
      id: 0,
      name: 'Daniel Mananta',
      fees: 450000,
      revenue: 5000000,
    },
    {
      id: 1,
      name: 'Alice Halland',
      fees: 150000,
      revenue: 220000,
    },
  ]
  return (
    <Card className="w-full rounded-8 mb-28">
      <Typography
        // variant="h5"
        // component="h5"
        className="my-20 mx-16 font-bold text-blue-dark text-16"
        // style={{color: '#003d51'}}
      >
        Recent Transaction
      </Typography>
      <Divider className="mx-16" />
      <div className="flex flex-col sm:flex sm:flex-row p-24 justify-between items-center">
        <FormControl>
          <Select
          // value={sortingValue}
          // onChange={this.handleSelectSorting}
          // className={classes.selectSorting}
          >
            <MenuItem value="latest">
              <em>Latest</em>
            </MenuItem>
            <MenuItem value="oldest">
              <em>Oldest</em>
            </MenuItem>
          </Select>
        </FormControl>

        <div>
          <FormControl>
            <TextField
              variant="outlined"
              placeholder="Search learner"
              className="mr-90 ml-80"
              onChange={(e) => setSearcKeyword(e.target.value)}
              value={searchKeyword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon color="action">search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
      </div>
      <Divider className="mx-24" />
      <div className="flex flex-wrap px-24">
        <table className="simple clickable">
          <thead>
            <tr style={{color: '#a9a8a8'}}>
              {/* <th>
                <Checkbox onChange="" value="" />
              </th> */}
              <th />
              <th>Learner</th>
              <th>Transaction Date</th>
              <th className="text-center">Fees</th>
              <th className="text-center">Revenue</th>
            </tr>
          </thead>
          <tbody>
            {
              learnerList
                .filter((data) =>
                  searchKeyword
                    ? data.name.toLowerCase().includes(searchKeyword)
                    : data
                )
                .map((data, index) => (
                  <tr key={index}>
                    {/* <td>
                    <Checkbox onChange="" value="" />
                  </td> */}
                    <td className="text-left w-96">
                      <Avatar />
                    </td>
                    <td>
                      <Typography className="ml-12">{data.name}</Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        {moment().format('ll')}
                      </Typography>
                    </td>
                    <td className="text-center">
                      <Typography variant="body1">
                        {convertToRupiah(data.fees)}
                      </Typography>
                    </td>
                    <td className="text-center">
                      {convertToRupiah(data.revenue)}
                    </td>
                  </tr>
                ))
              // .sort((a, b) => (sortingValue === 'latest' ? 1 : -1))
            }
          </tbody>
        </table>
      </div>
    </Card>
  )
}

export default RecentTransactionComponent
