import axios from 'axios'
import {axiosInstanceBearer} from 'app/services/restApiService'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable'
import _ from 'lodash'

export const GET_COURSE = '[ACADEMY APP] GET COURSE'
export const GET_COURSE_BY_ID = '[ACADEMY APP] GET COURSE BY ID'
export const GET_COURSE_BY_SLUG = '[ACADEMY APP] GET COURSE BY SLUG'
export const GET_COURSE_REVIEW = '[ACADEMY APP] GET COURSE REVIEW'
export const GET_COURSE_DISCUSSION = '[ACADEMY APP] GET COURSE DISCUSSION'
export const GET_COURSE_STUDENT = '[ACADEMY APP] GET COURSE STUDENT'
export const GET_LESSON_BY_ID = '[ACADEMY APP] GET LESSON BY ID'
export const GET_QUESTIONS_BY_LESSON_ID =
  '[INSTRUCTOR MANAGE COURSE] GET QUESTIONS BY LESSON ID'
export const SAVE_COURSE = '[ACADEMY APP] SAVE COURSE'
export const UPDATE_COURSE_PROGRESS = '[ACADEMY APP] UPDATE COURSE PROGRESS'
export const UPDATE_COURSE = '[ACADEMY APP] UPDATE COURSE'
export const UPDATE_LESSON = '[ACADEMY APP] UPDATE LESSON'

export const GET_WIDGETS = '[PROJECT DASHBOARD APP] GET WIDGETS'
export const GET_QUIZ = '[INSTRUCTOR DASHBOARD] GET QUIZ'
export const SAVE_QUIZ = '[INSTRUCTOR DASHBOARD] SAVE QUIZ'
export const UPDATE_QUIZ_QUESTION = '[INSTRUCTOR MANA QUIZ] UPDATE QUESTION'
export const REMOVE_QUIZ = '[INSTRUCTOR DASHBOARD] REMOVE QUIZ'
export const GET_DASHBOARD_STATISTICS =
  '[ANALYTICS DASHBOARD APP] GET DASHBOARD STATISTICS'
export const GET_SUBMISSION_QUIZ = '[INSTRUCTOR DASHBOARD] GET SUBMISSION QUIZ'

export const SAVE_MANAGE_PRACTICE =
  '[INSTRUCTOR DASHBOARD] SAVE MANAGE PRACTICE'
export const SET_DATA_PRACTICE = '[INSTRUCTOR DASHBOARD] SET DATA PRACTICE'
export const SET_DATA_EVENT = '[INSTRUCTOR DASHBOARD] SET DATA EVENT'
export const SET_DATA_BOOK = '[INSTRUCTOR DASHBOARD] SET DATA BOOK'
export const SAVE_MANAGE_EVENT = '[INSTRUCTOR DASHBOARD] SAVE MANAGE EVENT'
export const SAVE_MANAGE_BOOK = '[INSTRUCTOR DASHBOARD] SAVE MANAGE BOOK'
export const DELETE_EVENT = '[INSTRUCTOR DASHBOARD] DELETE EVENT'
export const DELETE_BOOK = '[INSTRUCTOR DASHBOARD] DELETE BOOK'

const userData = global.data_user

export function updateCourse(data) {
  const request = axiosInstanceBearer.patch(
    global.api.dev + '/academy/course/' + data.id + '/update/',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(() => {
      dispatch(showMessage({message: 'Course Saved'}))

      // return dispatch({
      //   type: UPDATE_COURSE,
      //   payload: response ? response.data : '',
      // })
      return dispatch(getCourseBySlug(data.slug))
    })
}

export function updateCourseProgress(data) {
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/enrollment/' + data.courseId + '/progress/',
    {
      courseId: data.courseId,
      lessonId: data.lessonId,
      grade: data.grade,
      status: data.status,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: UPDATE_COURSE_PROGRESS,
        payload: response.data,
      })
    })
}

export function updateLesson(data) {
  const request = axiosInstanceBearer.patch(
    global.api.dev + '/academy/lesson/' + data.id + '/update/',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return () =>
    request.then(() => {
      /* return dispatch(showMessage({message: 'Lesson Updated'}))

       return dispatch({
        type: UPDATE_LESSON,
      }) */
    })
}

export function getCourseById(courseId) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/' + courseId,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_BY_ID,
        payload: response.data,
      })
    })
}

export function getCourseBySlug(slug) {
  /* const request = axios.get(
    global.api.dev + '/academy/course/get/' + typeof slug === 'object'
      ? null
      : slug, */
  const request = axiosInstanceBearer.get(
    `${global.api.dev}/academy/course/get/${
      typeof slug === 'object' ? '' : slug
    }`,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(response => {
      if (response) {
        return dispatch({
          type: GET_COURSE_BY_SLUG,
          payload: response.data,
        })
      }
    })
}

export function getCourseStudent(courseId) {
  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/' + courseId + '/student',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_STUDENT,
        payload: response.data,
      })
    })
}

export function getCourseQuizSubmission(courseId) {
  if (_.isEmpty(global.data_user)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/' + courseId + '/submission',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_SUBMISSION_QUIZ,
        payload: response.data,
      })
    })
}

export function getCourseReview(courseId) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/' + courseId + '/review',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_REVIEW,
        payload: response.data,
      })
    })
}

export function getQuestionsByLessonId(lessonId) {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/lesson/' + lessonId + '/question',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_QUESTIONS_BY_LESSON_ID,
        payload: response.data,
      })
    })
}

export function getLessonById(lessonId) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/lesson/' + lessonId,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_LESSON_BY_ID,
        payload: response.data,
      })
    })
}

export function getDashboardStatistics() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.data_user.oauth.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/dashboard/get',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_DASHBOARD_STATISTICS,
        payload: response.data,
      })
    )
}

/* export function getQuiz() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.data_user.oauth.access_token,
    },
  }

  const bodyParameters = {}

  const request = axios.get(
    global.api.dev + '/academy/question/get',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_QUIZ,
        payload: response.data,
      })
    )
} */

export function saveQuiz(dataPost) {
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/question/create',
    dataPost,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: SAVE_QUIZ,
        })

        dispatch(getQuestionsByLessonId(dataPost.lesson))

        return disp
      } else {
        dispatch({message: 'Error ' + response.status})
      }
    })
}

export function updateQuizQuestion(lessonId, questionId, questionText) {
  const data = {
    fulltext: questionText,
    lesson: lessonId,
  }

  const request = axiosInstanceBearer.patch(
    global.api.dev + '/academy/question/' + questionId + '/update',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )
  return dispatch =>
    request.then(() => {
      const disp = dispatch({
        type: UPDATE_QUIZ_QUESTION,
      })

      dispatch(getQuestionsByLessonId(lessonId))

      return disp
    })
}

export function removeQuiz(questionId, lessonId) {
  const request = axiosInstanceBearer.delete(
    global.api.dev + '/academy/question/' + questionId + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )
  return dispatch =>
    request.then(() => {
      const disp = dispatch({
        type: REMOVE_QUIZ,
      })

      dispatch(getQuestionsByLessonId(lessonId))

      return disp
    })
}

// Fuse Demo
export function getCourse(params) {
  const request = axios.get('/api/academy-app/course', {params},{
    withCredentials: true
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_COURSE,
        payload: response.data,
      })
    )
}

export function saveCourse(data) {
  const request = axios.post('/api/academy-app/course/save', data,{
    withCredentials: true
  })

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Course Saved'}))

      return dispatch({
        type: SAVE_COURSE,
        payload: response.data,
      })
    })
}

export function getWidgets() {
  const request = axios.get('/api/project-dashboard-app/widgets',{
    withCredentials: true
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_WIDGETS,
        payload: response.data,
      })
    )
}

export function getCourseDiscussion(courseId) {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/' + courseId + '/discussion',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch({
        type: GET_COURSE_DISCUSSION,
        payload: response.data.data,
      })
    })
}

export function saveManagePractice(dataPost) {
  const data = {
    title: dataPost.practiceTitle,
    subtitle: dataPost.practiceSubtitle,
    description: dataPost.practiceDescription,
    language: dataPost.practiceLanguage,
    level: dataPost.practiceLevel,
    category: dataPost.practiceCategory,
    thumbnail: dataPost.practiceThumbnail,
  }

  const request = axiosInstanceBearer.patch(
    global.api.dev + '/academy/course/' + dataPost.practiceId + '/update',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Practice Saved'}))
      return dispatch({
        type: SAVE_MANAGE_PRACTICE,
        payload: response.data,
      })
    })
}

export function saveManageEvent(data) {
  if (!data.title) {
    return showMessage({message: "Title can't be empty"})
  } else if (!data.description) {
    return showMessage({message: "Description can't be empty"})
  } else if (!data.slug) {
    return showMessage({message: "Slug can't be empty"})
  } else if (!data.category) {
    return showMessage({
      message: "Category & Sub Category can't be empty",
    })
  } else if (!data.type) {
    return showMessage({
      message: " Type can't be empty",
    })
  } else if (!data.dateStart) {
    return showMessage({
      message: " Date can't be empty",
    })
  } else if (!data.from) {
    return showMessage({
      message: " Time can't be empty",
    })
  } else if (!data.url) {
    /* else if (!data.location) {
    return showMessage({
      message: " Location  can't be empty",
    })
  } */
    return showMessage({
      message: " Link can't be empty",
    })
  }
  const request = axiosInstanceBearer.patch(
    global.api.dev + '/event/schedule/' + data.id + '/update',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Event Updated'}))
      if (response)
        return dispatch({
          type: SAVE_MANAGE_EVENT,
          payload: response.data,
        })
    })
}

export function saveManageBook(data) {
  /* if (!data.title) {
    return showMessage({message: "Title  can't be empty"})
  } else if (!data.description) {
    return showMessage({message: "Description  can't be empty"})
  } else if (!data.slug) {
    return showMessage({message: "Slug  can't be empty"})
  } else if (!data.category) {
    return showMessage({message: "Category & Sub Category  can't be empty"})
  } else if (!data.type) {
    return showMessage({
      message: " Type  can't be empty",
    })
  } else if (!data.dateStart) {
    return showMessage({
      message: " Date  can't be empty",
    })
  } else if (!data.from) {
    return showMessage({
      message: " Time  can't be empty",
    })
  } else if (!data.url) {
  
    return showMessage({
      message: " Link  can't be empty",
    })
  } */
  const request = axiosInstanceBearer.patch(
    global.api.dev + '/academy/book/' + data.slug + '/update',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Book Updated'}))
      if (response)
        return dispatch({
          type: SAVE_MANAGE_BOOK,
          payload: response.data,
        })
    })
}

export function setDataManagePractice(data) {
  return {
    type: SET_DATA_PRACTICE,
    payload: data,
  }
}

export function setDataManageEvent(data) {
  return {
    type: SET_DATA_EVENT,
    payload: data,
  }
}

export function setDataManageBook(data) {
  return {
    type: SET_DATA_BOOK,
    payload: data,
  }
}

export function deleteEvent(data) {
  const request = axiosInstanceBearer.delete(
    global.api.dev + '/event/schedule/' + data.id + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Event Deleted'}))
      if (response)
        return dispatch({
          type: DELETE_EVENT,
          payload: response.data,
        })
    })
}

export function deleteBook(isbn) {
  const request = axiosInstanceBearer.delete(
    global.api.dev + `/academy/book/${isbn}/remove`,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Book Deleted'}))
      if (response)
        return dispatch({
          type: DELETE_BOOK,
          payload: response.data,
        })
    })
}
