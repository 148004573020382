export const MaterialUINavigation = [
  {
    id: 'appBar',
    title: 'App Bar',
    type: 'item',
    url: '/components/material-ui/app-bar',
  },
  {
    id: 'autocomplete',
    title: 'Autocomplete',
    type: 'item',
    url: '/components/material-ui/autocomplete',
  },
  {
    id: 'avatars',
    title: 'Avatars',
    type: 'item',
    url: '/components/material-ui/avatars',
  },
  {
    id: 'badges',
    title: 'Badges',
    type: 'item',
    url: '/components/material-ui/badges',
  },
  {
    id: 'bottomNavigation',
    title: 'Bottom Navigation',
    type: 'item',
    url: '/components/material-ui/bottom-navigation',
  },
  {
    id: 'buttons',
    title: 'Buttons',
    type: 'item',
    url: '/components/material-ui/buttons',
  },
  {
    id: 'cards',
    title: 'Cards',
    type: 'item',
    url: '/components/material-ui/cards',
  },
  {
    id: 'chips',
    title: 'Chips',
    type: 'item',
    url: '/components/material-ui/chips',
  },
  {
    id: 'dialogs',
    title: 'Dialogs',
    type: 'item',
    url: '/components/material-ui/dialogs',
  },
  {
    id: 'dividers',
    title: 'Dividers',
    type: 'item',
    url: '/components/material-ui/dividers',
  },
  {
    id: 'drawers',
    title: 'Drawers',
    type: 'item',
    url: '/components/material-ui/drawers',
  },
  {
    id: 'expansionPanels',
    title: 'Expansion Panels',
    type: 'item',
    url: '/components/material-ui/expansion-panels',
  },
  {
    id: 'gridList',
    title: 'Grid List',
    type: 'item',
    url: '/components/material-ui/grid-list',
  },
  {
    id: 'lists',
    title: 'Lists',
    type: 'item',
    url: '/components/material-ui/lists',
  },
  {
    id: 'menus',
    title: 'Menus',
    type: 'item',
    url: '/components/material-ui/menus',
  },
  {
    id: 'paper',
    title: 'Paper',
    type: 'item',
    url: '/components/material-ui/paper',
  },
  {
    id: 'pickers',
    title: 'Pickers',
    type: 'item',
    url: '/components/material-ui/pickers',
  },
  {
    id: 'progress',
    title: 'Progress',
    type: 'item',
    url: '/components/material-ui/progress',
  },
  {
    id: 'selectionControls',
    title: 'Selection Controls',
    type: 'item',
    url: '/components/material-ui/selection-controls',
  },
  {
    id: 'selects',
    title: 'Selects',
    type: 'item',
    url: '/components/material-ui/selects',
  },
  {
    id: 'snackbars',
    title: 'Snackbars',
    type: 'item',
    url: '/components/material-ui/snackbars',
  },
  {
    id: 'steppers',
    title: 'Steppers',
    type: 'item',
    url: '/components/material-ui/steppers',
  },
  {
    id: 'tables',
    title: 'Tables',
    type: 'item',
    url: '/components/material-ui/tables',
  },
  {
    id: 'tabs',
    title: 'Tabs',
    type: 'item',
    url: '/components/material-ui/tabs',
  },
  {
    id: 'textFields',
    title: 'Text Fields',
    type: 'item',
    url: '/components/material-ui/text-fields',
  },
  {
    id: 'tooltips',
    title: 'Tooltips',
    type: 'item',
    url: '/components/material-ui/tooltips',
  },
]
