import axios from 'axios'
import {axiosInstanceBearer} from 'app/services/restApiService'
import global from 'app/global-variable'
import {reactLocalStorage} from 'reactjs-localstorage'
import history from 'history.js'
import _ from '@lodash'

export const GET_ALL_EVENTS = '[ACADEMY APP] GET ALL EVENTS'
export const GET_ALL_EVENTS_NEARBY = '[ACADEMY APP] GET ALL EVENTS NEARBY'
export const GET_EVENT_DETAIL = '[ACADEMY APP] GET EVENT DETAIL'

const userData = reactLocalStorage.getObject('userData')

export function getEvents(limit, page) {
  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/event/schedule/get?limit=' + limit + '&page=' + page,
    {
      headers: {
        Authorization: 'Bearer ' + userData.oauth.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_ALL_EVENTS,
        payload: response.data,
      })
    )
}

export function getEventsNearby(limit, page, latitude, longitude) {
  console.log('getEventsNearby latitude', latitude)
  console.log('getEventsNearby longitude', longitude)

  const request = axiosInstanceBearer.get(
    global.api.dev +
      '/event/schedule/get?lng=' +
      longitude +
      '&lat=' +
      latitude +
      '&orders[0][0]=distance&limit=' +
      limit +
      '&page=' +
      page,
    {
      headers: {
        Authorization: 'Bearer ' + userData.oauth.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      console.log(response.data)
      return dispatch({
        type: GET_ALL_EVENTS_NEARBY,
        payload: response.data,
      })
    })
}

export function getEventById(eventId) {
  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/event/schedule/' + eventId,
    {
      headers: {
        Authorization: 'Bearer ' + userData.oauth.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_DETAIL,
        payload: response.data,
      })
    )
}

export function getEventBySlug(eventSlug) {
  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axiosInstanceBearer.get(
    global.api.dev + '/event/schedule/get/' + eventSlug,
    {
      headers: {
        Authorization: 'Bearer ' + userData.oauth.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_DETAIL,
        payload: response.data,
      })
    )
}
