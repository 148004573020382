import gql from 'graphql-tag'

export const GET_LEARNING_PROFILE = gql`
  query {
    learningProfile {
      activities {
        activity_post_id
        action
        activity_by {
          email
          name
        }
        content
        created_at
      }
      certificates {
        name_to
        course_name
      }
      classrooms {
        id
        name
      }
      learning_duration {
        audiobook
        quiz
        course
        event_tracker
        practice_test
        quiz
      }
      teach_duration {
        audiobook
        quiz
        course
        event_tracker
        practice_test
        quiz
      }
      mentees {
        id
        avatar
        name
      }
      mentors {
        id
        avatar
        name
      }
      badges {
        name
        badge
      }
      learning_material {
        audiobook
        course
        event
        event_tracker
        practice_test
        quiz
      }
      microlearnings {
        name
        false_answer_count
        correct_answer_count
        question_total
      }
    }
  }
`
export const GET_LIST_CERTIFICATES = gql`
  query($userId: uuid!) {
    academy_certificates(where: {student: {_eq: $userId}}) {
      id
      course
      code
      score
      name_to
      instructors
      date_added
      academy_course_enrollment {
        id
        academy_course {
          id
          title
          thumbnail
        }
      }
    }
  }
`
export const GET_CERTIFICATE_BY_ID = gql`
  query($id: Int!) {
    academy_certificates(where: {id: {_eq: $id}}) {
      id
      course
      code
      score
      name_to
      instructors
      date_added
      academy_course_enrollment {
        id
        academy_course {
          id
          title
          thumbnail
        }
      }
    }
  }
`
