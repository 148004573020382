import gql from 'graphql-tag'

export const GET_DAILY_QUESTION = gql`
  query {
    microlearningDailyNew {
      daily_answer_id
      microlearning {
        id
        name
        description
        icon_url
        owner {
          name
          avatar
        }
      }
      microlearning_questions {
        id
        question
        microlearning_question_answers {
          id
          answer
        }
      }
    }
  }
`
