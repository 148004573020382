import gql from 'graphql-tag'

export const GET_DETAIL_MICROLEARNING = gql`
  query($id: uuid!, $userId: uuid!) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      company_profile {
        id
        brand_name
        legal_name
      }
      type
      icon_url
      name
      description
      subtitle
      slug
      price
      question_count
      microlearning_members(where: {user_id: {_eq: $userId}}) {
        id
        schedule
        schedule_time
        daily_question
      }
      is_joined: microlearning_members_aggregate(
        where: {user_id: {_eq: $userId}}
      ) {
        aggregate {
          count
        }
      }
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          count
          avg {
            star
          }
        }
      }
      academy_course_category {
        id
        name
        parent: academy_course_category {
          id
          name
        }
      }
      global_language {
        id
        name
        language_code
      }
      microlearning_questions(
        where: {
          microlearning_member_answers: {
            microlearning_member_daily: {
              microlearning_member: {user_id: {_eq: $userId}}
            }
          }
        }
      ) {
        id
        question
        question_type
        microlearning_member_answers(
          where: {
            microlearning_member_daily: {
              microlearning_member: {user_id: {_eq: $userId}}
            }
          }
        ) {
          id
          is_answered
          score
          description
          date_answered
        }
        microlearning_question_answers {
          id
          answer
          is_correct
          microlearning_member_answer_options(
            where: {
              microlearning_member_answer: {
                is_answered: {_eq: true}
                microlearning_member_daily: {
                  microlearning_member: {user_id: {_eq: $userId}}
                }
              }
            }
          ) {
            id
          }
        }
      }
      date_added
      status
      level
      topic
    }
  }
`
