import global from '../global-variable'

export function convertToRupiah(angka) {
  let rupiah = ''
  const angkarev = angka
    .toString()
    .split('')
    .reverse()
    .join('')
  for (let i = 0; i < angkarev.length; i++)
    if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.'

  return (
    'Rp' +
    rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('') +
    ',00'
  )
}

export function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/[^0-9]/g, ''), 10)
}

export function nextAlphabet(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

// let counter = setInterval(timer, 1000);

/* function timer(seconds) {
    seconds--
    if (seconds < 0) return clearInterval(counter)
    return countDownTimer(seconds)
} */

export function countDownTimer(seconds) {
  const second = seconds / 1000
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m
  if (s < 10) s = '0' + s

  return h + ':' + m + ':' + s
}

export function getDate(date) {
  const initDate = new Date(date)
  let dd = initDate.getDate()
  let mm = initDate.getMonth() + 1
  const yyyy = initDate.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }

  if (mm < 10) {
    mm = `0${mm}`
  }

  const dateFull = `${mm}/${dd}/${yyyy}`
  return dateFull
}

export function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie =
    name + '=' + value + expires + '; path=/; domain=' + global.app_cookie
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export const selectProps = val => {
  return {
    displayEmpty: true,
    style: {
      color: !val && '#808080',
    },
  }
}

export function getPercent(value, total) {
  if (total === 0) {
    return 0
  } else {
    return (value / total) * 100
  }
}

export const hasModule = (data, moduleName) =>
  (data &&
    data.company_module_settings &&
    data.company_module_settings.length === 0) ||
  (data &&
    data.company_module_settings &&
    data.company_module_settings.some(o => o.global_module.name === moduleName))

export function listenCookieChange() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (!getCookie('userData')) {
        window.location.href = global.sso_url
      }
    }
  })
}

export const FILE_TYPE_UPLOAD = [
  'doc',
  'docx',
  'xls',
  'pdf',
  'xlsx',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tiff',
  'pjp',
  'pjpeg',
  'jfif',
  'tif',
  'mp3',
  'mp4',
  'mov',
  'mpeg-1',
  'mpeg-4',
  'avi',
  'flv',
  'mkv',
  'quicktime',
  'webm',
  'h264/avc',
  'vp8',
  'vp9',
  'asf',
  'wmv2',
  'wmv3',
  'wmv',
  'aac',
  'mpeg4',
  'wav',
  'zip',
  'rar',
]

export const getTypeFile = (href = '') => {
  return href
    .split('.')
    .slice(-1)
    .pop()
    .toLocaleLowerCase()
}

export const XSSChecker = value => {
  if (/<\/?[a-z][\s\S]*>/i.test(value)) {
    return ''
  }
  return value
}
