import gql from 'graphql-tag'

export const GET_CLASSROOM_CATEGORIES = gql`
  query {
    academy_course_categories {
      name
      code
      id
    }
  }
`
