import {combineReducers} from 'redux'
import books from './books.reducer'
import courses from './courses.reducer'
import course from './course.reducer'
import event from './event.reducer'
import practices from './practices.reducer'
import notification from './notification.reducer'
import classrooms from './classroom.reducer'

const reducer = combineReducers({
  books,
  courses,
  course,
  event,
  practices,
  notification,
  classrooms,
})

export default reducer
