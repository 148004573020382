import gql from 'graphql-tag'

export const GET_MY_LEARNING_COURSES_PRACTICES = gql`
  query(
    $search: String
    $offset: Int
    $limit: Int
    $userId: uuid
    $categoryIds: [Int!]
    $levels: [Int!]
    $languages: [String!]
    $durations: [academy_courses_bool_exp!]
    $type: Int
    $orderBy: [academy_course_enrollments_order_by!]
    $price: Int_comparison_exp
  ) {
    status_count_not_started: academy_course_enrollments_aggregate(
      where: {
        academy_course: {type: {_eq: $type}}
        completion_rate: {_eq: 0}
        user_id: {_eq: $userId}
      }
    ) {
      aggregate {
        count
      }
    }
    status_count_on_progress: academy_course_enrollments_aggregate(
      where: {
        academy_course: {type: {_eq: $type}}
        completion_rate: {_nin: [0, 100]}
        user_id: {_eq: $userId}
      }
    ) {
      aggregate {
        count
      }
    }
    status_count_finished: academy_course_enrollments_aggregate(
      where: {
        academy_course: {type: {_eq: $type}}
        completion_rate: {_eq: 100}
        user_id: {_eq: $userId}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_course_enrollments(
      order_by: $orderBy
      where: {
        academy_course: {
          academy_price_tier: {pricing_idr: $price}
          type: {_eq: $type}
          _or: $durations
          title: {_ilike: $search}
          category: {_in: $categoryIds}
          level: {_in: $levels}
          language: {_in: $languages}
        }
        user_id: {_eq: $userId}
      }
      offset: $offset
      limit: $limit
    ) {
      academy_course {
        id
        thumbnail
        title
        level
        language
        type
        average_rating
        slug
        academy_course_category {
          id
          name
        }
        author: global_user {
          id
          name
        }
        duration_seconds
      }
      academy_certificates {
        id
        code
      }
      completion_rate
    }
  }
`
