import React from 'react'
import {Divider, Paper, Typography, Icon, AppBar} from '@material-ui/core'
import {Line} from 'react-chartjs-2'
import RecentTransaction from './RecentTransaction'

const chartOption = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

const appBarStyle = {
  borderTopRightRadius: '.8rem',
  borderTopLeftRadius: '.8rem',
  backgroundColor: '#003d51',
}

const EarningComponent = (props) => {
  const {course} = props

  // const handleDelete = () => {
  //   console.info('You clicked the delete icon.')
  // }

  const chartData = {
    labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
      {
        label: 'Revenue',
        data: [12, 19, 3, 5, 2, 3],
        // fill: false,
        backgroundColor: 'rgba(220,220,220,0)',
        borderColor: 'rgba(0, 234, 255, 1)',
        // backgroundColor: Array(labels.length).fill('rgba(220,220,220,0)'),
        // borderColor: Array(labels.length).fill('rgba(0, 234, 255, 1)'),
        borderWidth: 1,
      },
    ],
  }

  return (
    <Paper className="mb-28 rounded-8 shadow-none border-1">
      <AppBar position="static" style={appBarStyle}>
        <div className="p-16 pr-4 flex flex-row items-center justify-between">
          <div className="pr-16">
            <Typography className="h4" color="inherit">
              Earnings by Time
            </Typography>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="px-16 p-20 flex flex-col items-end">
            <Typography
              className="text-48 font-300 leading-none"
              color="inherit"
            >
              Rp.15.000.000
            </Typography>
          </div>
        </div>
      </AppBar>
      <div className="p-16">
        <Line data={chartData} options={chartOption} />
      </div>
    </Paper>
  )
}

export default EarningComponent
