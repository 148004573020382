import gql from 'graphql-tag'

export const GET_PROFILE = gql`
  query getProfile($id: uuid!) {
    main_profile: global_users(where: {id: {_eq: $id}}) {
      id
      name
      avatar
      working_status: people_work_placements(
        where: {user: {_eq: $id}, status: {_eq: "ACTIVE"}}
        limit: 1
      ) {
        id
        company_profile {
          id
          brand_name
        }
        company_employee_position {
          id
          name
        }
      }
    }
  }
`
