import React, {useState} from 'react'
import {
  Card,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Icon,
  Typography,
  Divider,
} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {LEARNING_CONTRIBUTOR_LIST_LEARNERS} from 'app/graphql/queries/index'

const Learners = ({students}) => {
  return (
    <tbody>
      {students.map((student, index) => (
        <tr key={index}>
          <td className="text-left w-96">
            <img
              src={
                !student.global_user.avatar
                  ? 'https://public-media.worklifebeyond.com/96decbb7-6c25-473f-af13-9e1288850c00-1588909023531.png'
                  : student.global_user.avatar
              }
              alt={student.name}
              style={{width: 42, borderRadius: 5}}
            />
          </td>
          <td>
            <Typography>{student.global_user.name}</Typography>
          </td>
          <td>
            <Typography variant="body1">{student.global_user.email}</Typography>
          </td>
          <td className="text-center">
            <Typography variant="body1">
              {student.question_answered_count + '/' + student.daily_question}
            </Typography>
          </td>
          <td className="text-center">
            {student.is_finished ? 'Selesai' : 'Tidak Selesai'}
          </td>
        </tr>
      ))}
    </tbody>
  )
}
const LearnerComponent = props => {
  const [sortList, setSortList] = useState('latest')
  const [searchKeyword, setSearcKeyword] = useState('')
  const context = {
    headers: {
      'X-Hasura-Role': 'organization-staff',
    },
  }
  const {data: listLearners} = useQuery(LEARNING_CONTRIBUTOR_LIST_LEARNERS, {
    variables: {
      microlearningId: props.location.state.microLearning.id,
      search: `%${searchKeyword}%`,
      orderBy: {
        date_added: sortList === 'latest' ? 'desc' : 'asc',
      },
    },
    context: context,
  })

  return (
    <Card className="w-full rounded-8 mb-28" style={{minHeight: '70vh'}}>
      <div className="flex flex-col sm:flex sm:flex-row p-24 justify-between items-center">
        <FormControl>
          <Select
            value={sortList}
            onChange={e => setSortList(e.target.value)}
            // className={classes.selectSorting}
          >
            <MenuItem value="latest">
              <em>Paling Baru</em>
            </MenuItem>
            <MenuItem value="oldest">
              <em>Paling Lama</em>
            </MenuItem>
          </Select>
        </FormControl>

        <div>
          <FormControl>
            <TextField
              variant="outlined"
              placeholder="Cari Pembelajar"
              className="mr-90 ml-80"
              onChange={e => setSearcKeyword(e.target.value)}
              value={searchKeyword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon color="action">search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
      </div>
      <Divider className="mx-24" />
      <div className="flex flex-wrap px-24">
        <table className="simple clickable">
          <thead>
            <tr style={{color: '#a9a8a8'}}>
              {/* <th>
                <Checkbox onChange="" value="" />
              </th> */}
              <th />
              <th>Nama</th>
              <th>Email</th>
              <th className="text-center">Jawaban Pertanyaan</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>

          {listLearners && (
            <Learners students={listLearners.microlearning_members} />
          )}
        </table>
      </div>
    </Card>
  )
}

export default LearnerComponent
