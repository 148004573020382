import * as Actions from '../actions'

const initialState = {
  data: [],
  recommendation: [],
  trending: [],
  promoted: [],
  categories: [],
  cart: [],
  wishlists: [],
  myCourses: [],
  student: {
    latestBalance: 0,
  },
  searchText: '',
  categoryFilter: 'all',
  courseBySlugLearning: '',
  eventCategories: [],
  eventTypes: [],
  courseCategories: [],
  events: [],
  videos: [],
  books: [],
  practiceTests: [],
  bookTypes: [],
  filteredCourse: '',
  filteredEvent: '',
  filteredBook: '',
  filteredPractice: '',
  instructorCourse: null,
}

const coursesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COURSES: {
      //console.log("action type: "+action.type)
      //console.log("GET_COURSES: "+JSON.stringify(action.payload))
      return {
        ...state,
        data: action.payload,
      }
    }
    case Actions.GET_STUDENT: {
      return {
        ...state,
        student: action.payload,
      }
    }
    case Actions.GET_INSTRUCTOR_COURSE: {
      return {
        ...state,
        instructorCourse: action.payload.data,
      }
    }
    case Actions.GET_MY_COURSES: {
      return {
        ...state,
        myCourses: action.payload,
      }
    }
    case Actions.GET_RECOMMENDATION: {
      return {
        ...state,
        recommendation: action.payload,
      }
    }
    case Actions.GET_TRENDING: {
      return {
        ...state,
        trending: action.payload,
      }
    }
    case Actions.GET_PROMO: {
      return {
        ...state,
        promoted: action.payload,
      }
    }
    case Actions.IS_SHOWED: {
      //console.log("reducer Actions.IS_SHOWED: "+action.payload)
      return {
        ...state,
        coursesCategoryShowed: action.payload,
      }
    }
    case Actions.ADD_TO_CART: {
      return {
        ...state,
      }
    }
    case Actions.CHECKOUT: {
      return {
        ...state,
      }
    }
    case Actions.GET_CART: {
      //console.log("REDUCER GET CART", action.payload);
      return {
        ...state,
        cart: action.payload,
      }
    }
    case Actions.GET_COURSE_BY_SLUG: {
      return {
        ...state,
        //...action.payload
        courseBySlug: action.payload,
        //courseBySlug: {name:"Muhammad Ilham Fadillah"}
      }
    }
    case Actions.GET_WISHLIST: {
      return {
        ...state,
        wishlists: action.payload,
      }
    }
    case Actions.GET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case Actions.SET_COURSES_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      }
    }
    case Actions.SET_COURSES_CATEGORY_FILTER: {
      return {
        ...state,
        categoryFilter: action.category,
      }
    }
    case Actions.GET_EVENT_CATEGORIES:
      return {
        ...state,
        eventCategories: action.payload,
      }
    case Actions.GET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.payload,
      }
    case Actions.GET_COURSE_CATEGORIES:
      return {
        ...state,
        courseCategories: action.payload,
      }
    case Actions.GET_EVENT:
      return {
        ...state,
        events: action.payload,
      }
    case Actions.GET_VIDEO:
      return {
        ...state,
        videos: action.payload,
      }
    case Actions.GET_BOOKS:
      return {
        ...state,
        books: action.payload,
      }
    case Actions.GET_PRACTICE_TESTS:
      return {
        ...state,
        practiceTests: action.payload.data,
      }
    case Actions.GET_BOOK_TYPES:
      return {
        ...state,
        bookTypes: action.payload,
      }
    case Actions.FILTER_MYCOURSE: {
      return {
        ...state,
        filteredCourse: action.filtered,
      }
    }
    case Actions.FILTER_MYEVENT: {
      return {
        ...state,
        filteredEvent: action.filtered,
      }
    }
    case Actions.FILTER_MYVIDEOS: {
      return {
        ...state,
        filteredVideo: action.filtered,
      }
    }
    case Actions.FILTER_MYBOOK: {
      return {
        ...state,
        filteredBook: action.filtered,
      }
    }
    case Actions.FILTER_MYPRACTICE: {
      return {
        ...state,
        filteredPractice: action.filtered,
      }
    }
    default: {
      return state
    }
  }
}

export default coursesReducer
