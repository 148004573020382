import axios from 'axios'
import global from 'app/global-variable'
import {createCookie} from 'app/utils/helpers'

const axiosInstanceBearer = axios.create({
  baseURL: global.restApi,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${global.access_token}`,
  },
})

axiosInstanceBearer.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Token is expired.
      createCookie('userData', '', -1)
      setTimeout(() => {
        window.location = global.sso_url
      }, 500)
    }
    Promise.reject(error)
  }
)

export {axiosInstanceBearer}
