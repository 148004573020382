import gql from 'graphql-tag'

export const GET_MICROLEARNING_REVIEWS = gql`
  query($microlearningId: String!, $offset: Int, $limit: Int) {
    microlearningReviews(
      microlearningId: $microlearningId
      offset: $offset
      limit: $limit
    ) {
      average_star
      review_count
      star_count {
        one
        two
        three
        four
        five
      }
      responses {
        star
        description
        user {
          id
          name
          avatar
        }
        date_added
      }
    }
  }
`
