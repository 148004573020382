import * as Actions from '../actions'

const initialState = {
  unreadNotifications: null,
  unreadAllNotifications: null,
  fourUnreadNotifications: null,
  readAllNotifications: null,
}

const notificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_UNREAD_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        unreadNotifications: action.payload,
      }
    }
    case Actions.GET_UNREAD_NOTIFICATIONS: {
      return {
        ...state,
        unreadAllNotifications: action.payload,
      }
    }
    case Actions.GET_4_UNREAD_NOTIFICATIONS: {
      return {
        ...state,
        fourUnreadNotifications: action.payload,
      }
    }
    case Actions.READ_ALL_NOTIFICATIONS: {
      return {
        ...state,
        readAllNotifications: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default notificationReducer
