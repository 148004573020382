import * as Actions from '../actions'

const initialState = {
  data: [],
  // book: null,
  // bookChapters: [],
}

const practicesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PRACTICES: {
      return {
        ...state,
        data: action.payload.data,
      }
    }
    // case Actions.GET_BOOK_BY_ISBN: {
    //   return {
    //     ...state,
    //     book: action.payload,
    //   }
    // }
    // case Actions.GET_BOOK_CHAPTERS: {
    //   return {
    //     ...state,
    //     bookChapters: action.payload,
    //   }
    // }
    default: {
      return state
    }
  }
}

export default practicesReducer
