// import {DashboardAppConfig} from './dashboard/DashboardAppConfig'
// import {SharePageConfig} from './dashboard/SharePageConfig'
import {AnalyticsDashboardAppConfig} from './instructor/AnalyticsDashboardAppConfig'
import {InstructorDashboardAppConfig} from './instructor/InstructorDashboardAppConfig'
// import {ChatAppConfig} from './dashboard/ChatAppConfig'
// import {LearningProfileConfig} from './learningProfile/LearningProfileConfig'
// import {LearningContributorConfig} from './dashboard/learning-contributor/LearningContributorConfig'
// import {createClassroomConfig} from './dashboard/CreateClassroomConfig'
// import {MicroLearningConfig} from './microLearning/MicroLearningConfig'
import EventConfig from './instructor/event/EventConfig'
import BookConfig from './instructor/MyBook/BookConfig'
// import {WishlistConfig} from './wishlist/WishlistConfig'
import {ManageMicroLearningConfig} from './instructor/MicroLearning/manage/MicroLearningManageConfig'
import {InstructorMicroLearningConfig} from './instructor/MicroLearning/MicroLearningConfig'

export const learningsConfigs = [
  // DashboardAppConfig,
  ManageMicroLearningConfig,
  AnalyticsDashboardAppConfig,
  InstructorDashboardAppConfig,
  // ChatAppConfig,
  // LearningProfileConfig,
  // LearningContributorConfig,
  // createClassroomConfig,
  // MicroLearningConfig,
  EventConfig,
  BookConfig,
  InstructorMicroLearningConfig,
  // SharePageConfig,
  // WishlistConfig,
]
