import * as Actions from '../actions'

const initialState = {
  role: '',
  data: {
    displayName: '',
    photoURL: '',
    email: '',
    shortcuts: [],
  },
  student: null,
}

const user = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return {
        ...initialState,
        ...action.payload,
      }
    }
    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState,
      }
    }
    case Actions.GET_STUDENT: {
      return {
        ...state,
        student: action.payload,
      }
    }
    case Actions.USER_LOGGED_OUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default user
