import * as Actions from '../actions'

const initialState = {
  data: null,
  quiz: null,
  event: null,
  book: null,
  eventReviews: null,
  bookReviews: null,
  courseAnalytics: null,
  lessonAnalytics: null,
  enrollmentAnalytics: null,
  userAnalytics: null,
  overallRatings: null,
  topCourses: null,
  latestComments: null,
  enrollmentsChart: null,
  revenuesChart: null,
}

const widgetsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_WIDGETS:
      return {
        ...state,
        data: {...action.payload},
      }
    case Actions.GET_DASHBOARD_STATISTICS:
      return {
        ...state,
        statistics: {...action.payload},
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_COURSES:
      return {
        ...state,
        courseAnalytics: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_LESSONS:
      return {
        ...state,
        lessonAnalytics: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_ENROLLMENTS:
      return {
        ...state,
        enrollmentAnalytics: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_USERS:
      return {
        ...state,
        userAnalytics: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_OVERALL_RATINGS:
      return {
        ...state,
        overallRatings: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_TOP_COURSES:
      return {
        ...state,
        topCourses: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_LATEST_COMMENTS:
      return {
        ...state,
        latestComments: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_ENROLLMENTS_CHART:
      return {
        ...state,
        enrollmentsChart: action.payload,
      }
    case Actions.GET_DASHBOARD_INSTRUCTOR_REVENUES_CHART:
      return {
        ...state,
        revenuesChart: action.payload,
      }
    case Actions.GET_QUIZ:
      return {
        ...state,
        quiz: action.payload.data,
      }
    case Actions.GET_EVENT_BY_SLUG:
      return {
        ...state,
        event: action.payload,
      }
    case Actions.GET_BOOK_BY_ISBN:
      return {
        ...state,
        book: action.payload,
      }
    case Actions.GET_EVENT_REVIEWS:
      return {
        ...state,
        eventReviews: action.payload,
      }
    case Actions.GET_BOOK_REVIEWS:
      return {
        ...state,
        bookReviews: action.payload,
      }
    case Actions.SAVE_QUIZ:
      return {
        ...state,
      }
    case Actions.REMOVE_QUIZ:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default widgetsReducer
