import gql from 'graphql-tag'

export const SEARCH_CONTRIBUTOR = gql`
  query(
    $offset: Int
    $limit: Int
    $order: [global_users_order_by!]
    $query: String
    $contrib_labels: [String!]
    $rating: float8_comparison_exp
  ) {
    global_users(
      order_by: $order
      offset: $offset
      limit: $limit
      where: {
        name: {_ilike: $query}
        contributor_label: {_in: $contrib_labels}
        _or: [
          {learning_contributor_ratings: {user_id: {_is_null: false}}}
          {contributor_label: {_is_null: false}}
          {
            classroom_members: {
              is_admin: {_eq: true}
              classroom: {versioning_id: {_is_null: false}}
            }
          }
        ]
      }
    ) {
      id
      name
      avatar
      contributor_label
      learning_contributor_ratings {
        total_rating_avg
        total_catalog_count
        total_rating_count
        total_learner_count
        classroom_rating_avg
        classroom_rating_count
        classroom_learner_count
        classroom_count
        courses_count
        courses_rating_avg
        courses_rating_count
        course_learner_count
        books_count
        book_rating_avg
        book_rating_count
        book_learner_count
      }
      academy_books(limit: 5, where: {thumbnail: {_is_null: false, _neq: ""}}) {
        id
        title
        thumbnail
      }
      academy_courses(
        limit: 5
        where: {thumbnail: {_is_null: false, _neq: ""}}
      ) {
        id
        title
        thumbnail
      }
      classrooms(
        limit: 5
        where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
      ) {
        id
        name
        avatar_url
      }
      event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
        id
        title
        thumbnail
      }
      people_work_placements {
        company_job_profile {
          position
          title
        }
        company_profile {
          brand_name
          legal_name
        }
      }
      academy_courses_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const CONTRIBUTOR_RATING_FILTER = gql`
  query(
    $offset: Int
    $limit: Int
    $order: [global_users_order_by!]
    $query: String
    $contrib_labels: [String!]
    $rating: float8_comparison_exp
  ) {
    global_users(
      order_by: $order
      offset: $offset
      limit: $limit
      where: {
        name: {_ilike: $query}
        contributor_label: {_in: $contrib_labels}
        _or: [
          {learning_contributor_ratings: {user_id: {_is_null: false}}}
          {contributor_label: {_is_null: false}}
          {
            classroom_members: {
              is_admin: {_eq: true}
              classroom: {versioning_id: {_is_null: false}}
            }
          }
        ]
      }
    ) {
      id
      name
      avatar
      contributor_label
      learning_contributor_ratings {
        total_rating_avg
        total_catalog_count
        total_rating_count
        total_learner_count
        classroom_rating_avg
        classroom_rating_count
        classroom_learner_count
        classroom_count
        courses_count
        courses_rating_avg
        courses_rating_count
        course_learner_count
        books_count
        book_rating_avg
        book_rating_count
        book_learner_count
      }
      academy_books(limit: 5, where: {thumbnail: {_is_null: false, _neq: ""}}) {
        id
        title
        thumbnail
      }
      academy_courses(
        limit: 5
        where: {thumbnail: {_is_null: false, _neq: ""}}
      ) {
        id
        title
        thumbnail
      }
      classrooms(
        limit: 5
        where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
      ) {
        id
        name
        avatar_url
      }
      event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
        id
        title
        thumbnail
      }
      people_work_placements {
        company_job_profile {
          position
          title
        }
        company_profile {
          brand_name
          legal_name
        }
      }
      people_work_placements {
        company_job_profile {
          position
          title
        }
        company_profile {
          brand_name
          legal_name
        }
      }
    }
  }
`
