import React, {Component} from 'react'
import {
  Avatar,
  Button,
  Badge,
  Card,
  Icon,
  ListItemText,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  withStyles,
  Tabs,
  Tab,
} from '@material-ui/core'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {NavLink} from 'react-router-dom'
import * as Actions from '../../main/apps/academy/store/actions'
import withReducer from 'app/store/withReducer'
import reducer from '../../main/apps/academy/store/reducers'
import ReactHover from 'react-hover'
import '../../../styles/menu.css'
import {FuseScrollbars} from '@fuse'
import _ from '@lodash'
import {convertToRupiah} from 'app/utils/helpers'
import moment from 'moment'
import global from 'app/global-variable'
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu'

// .
const styles = theme => ({
  hoverAdjustment: {
    zIndex: 1000,
  },
  wlbColor: {
    color: '#014a62',
    fontWeight: 'bold',
  },
})

const options = {
  followCursor: false,
}

class InstructorNotification extends Component {
  render() {
    let instructorNotifications = this.props.notification.filter(notification =>
      notification.title.includes('[Instructor]')
    )

    return (
      <div>
        {instructorNotifications.slice(0, 2).map(notification => (
          <React.Fragment>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  src={
                    notification.data.avatar
                      ? notification.data.avatar
                      : '/assets/images/avatars/profile.jpg'
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={notification.title}
                secondary={
                  <React.Fragment>
                    <Typography className="text-grey-darker">
                      {moment(notification.createdAt).fromNow()}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </div>
    )
  }
}
class StudentNotification extends Component {
  render() {
    let learnenrNotifications = this.props.notification.filter(
      notification => !notification.title.includes('[Instructor]')
    )
    return (
      <div>
        {learnenrNotifications.slice(0, 2).map(notification => (
          <React.Fragment>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  src={
                    notification.data.avatar
                      ? notification.data.avatar
                      : '/assets/images/avatars/profile.jpg'
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={notification.title}
                secondary={
                  <React.Fragment>
                    <Typography className="text-grey-darker">
                      {moment(notification.createdAt).fromNow()}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </div>
    )
  }
}

class ToolbarMenuSet2 extends Component {
  state = {
    tabValue: 0,
    userMenu: null,
  }

  handleTabChange = (event, value) => {
    this.setState({tabValue: value})
  }

  userMenuClick = event => {
    this.setState({userMenu: event.currentTarget})
  }

  favoriteMenuClick = event => {
    this.setState({favoriteMenu: event.currentTarget})
  }

  notificationMenuClick = event => {
    this.setState({notificationMenu: event.currentTarget})
  }

  userMenuClose = () => {
    this.setState({userMenu: null})
  }

  favoriteMenuClose = () => {
    this.setState({favoriteMenu: null})
  }

  notificationMenuClose = () => {
    this.setState({notificationMenu: null})
  }

  componentDidMount() {
    const userData = global.data_user
    if (_.isEmpty(userData)) {
      // history.push('/learning')
    } else {
      this.props.getCart()
      this.props.getWishlist()
      this.props.getUnreadNotificationCount()
      this.props.getFourUnreadNotifications()
    }
  }

  render() {
    const {
      cart,
      wishlist,
      classes,
      unreadNotificationCount,
      fourUnreadNotifications,
    } = this.props
    const {userMenu, tabValue} = this.state

    const iconStyle = {
      width: '24px',
      height: '24px',
    }

    return (
      <React.Fragment>
        <div
          className="md:flex flex-row justify-between h-60 pt-16 pl-6 pr-64"
          style={{width: '32vw'}}
        >
          <a href="/learning">
            {/* <Badge color="secondary" badgeContent={wishlist.length}> */}
            <Icon color="action" style={{color: '#014a62'}}>
              home
            </Icon>
            {/* </Badge> */}
          </a>
          <a
            href={`${global.vendor_url}/profile/shopping-cart`}
            target="_blank"
          >
            <Icon
              color="action"
              className="cursor-pointer"
              style={{color: '#014a62'}}
            >
              shopping_cart
            </Icon>
          </a>
          <NavLink to={'/wishlist/course'}>
            <ReactHover options={options} className="z-20">
              <ReactHover.Trigger type="trigger" className="z-20">
                <Icon color="action" style={{color: '#014a62'}}>
                  favorite_border
                </Icon>
              </ReactHover.Trigger>
              <ReactHover.Hover type={'hover'} className="z-20">
                {wishlist.length !== 0 ? (
                  <Card
                    styles={classes.hoverAdjustment}
                    className="dropdownHover flex flex-row overflow-hidden"
                  >
                    <React.Fragment>
                      <FuseScrollbars>
                        <List className="list-item">
                          {wishlist
                            .filter(function(item) {
                              return item.Course
                            })
                            .map((item, index) => {
                              return (
                                <ListItem
                                  className="list-item"
                                  alignItems="flex-start"
                                  key={index}
                                >
                                  <ListItemAvatar>
                                    <img
                                      alt="course-1"
                                      src={
                                        item.Course.thumbnail
                                          ? item.Course.thumbnail
                                          : 'assets/images/courses/course-default-thumbnail.jpg'
                                      }
                                      width={90}
                                      height={75}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.Course.title}
                                    secondary={
                                      <React.Fragment>
                                        {item.Course.CreatorUser.name}

                                        <Typography
                                          component="span"
                                          color="error"
                                        >
                                          {(item.Course &&
                                            item.Course.Price &&
                                            item.Course.Price.pricing &&
                                            item.Course.Price.pricing.IDR ==
                                              0) ||
                                          !item.Course.Price
                                            ? 'FREE'
                                            : convertToRupiah(
                                                item.Course.Price.pricing.IDR
                                              )}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                              )
                            })}

                          {wishlist.length > 0 ? <Divider /> : ''}
                          <ListItem className="list-item">
                            <div className="flex flex-1 flex-row items-end justify-end content-end">
                              <NavLink
                                to={{
                                  pathname: '/learning_my_courses',
                                  search: '?tab=1',
                                  state: {tab: 1},
                                }}
                              >
                                <Typography className="text-wlb text-center">
                                  See All
                                </Typography>
                              </NavLink>
                            </div>
                          </ListItem>
                        </List>
                      </FuseScrollbars>
                    </React.Fragment>
                  </Card>
                ) : (
                  ''
                )}
              </ReactHover.Hover>
            </ReactHover>
          </NavLink>
          <a
            href={`${global.sosmed_url}followers/${global.user_id}/`}
            target="_blank"
          >
            <Typography style={iconStyle}>
              <img
                src="assets/images/logos/people-alt-outlined.svg"
                alt=""
                style={iconStyle}
              />
            </Typography>
          </a>
          {/* <NavLink to={'/learning_cart'}>
            <ReactHover options={options} className="z-20">
              <ReactHover.Trigger type="trigger" className="z-20">
                <Icon
                  color="action"
                 
                  style={{color: '#014a62'}}
                >
                  shopping_cart
                </Icon>
              </ReactHover.Trigger>
              <ReactHover.Hover type="hover" className="z-20">
                {cart.length > 0 ? (
                  <Card
                    styles={classes.hoverAdjustment}
                    className="dropdownHover flex flex-row overflow-hidden"
                  >
                    <React.Fragment>
                      <FuseScrollbars>
                        <List className="list-item">
                          {cart
                            .filter(function(item) {
                              return item.Course
                            })
                            .map((item, index) => {
                              return (
                                <ListItem alignItems="flex-start" key={index}>
                                  <ListItemAvatar>
                                    <img
                                      alt="course-1"
                                      src={
                                        item.Course.thumbnail
                                          ? item.Course.thumbnail
                                          : 'assets/images/courses/course-default-thumbnail.jpg'
                                      }
                                      width={90}
                                      height={75}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.Course.title}
                                    secondary={
                                      <React.Fragment>
                                        {item.Course.CreatorUser.name}                                        
                                        <Typography
                                          component="span"
                                          color="error"
                                        >
                                          {item.Course.Price.pricing.IDR === '0'
                                            ? 'FREE'
                                            : convertToRupiah(
                                                item.Course.Price.pricing.IDR
                                              )}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                              )
                            })}
                          <ListItem style={{backgroundColor: '#f6f8f9'}}>
                            <div
                              className={
                                'flex flex-row flex-1 justify-center item-center'
                              }
                            >
                              <Typography variant="subtitle1">
                                Total :
                              </Typography>{' '}
                              <Typography variant="h6">
                                {(function() {
                                  let sum = 0
                                  cart.map((item, index) => {
                                    if (item.Course) {
                                      return (sum += parseFloat(
                                        item.Course.Price.pricing['IDR']
                                      ))
                                    } else {
                                      return 0
                                    }
                                  })

                                  return convertToRupiah(sum)
                                })()}
                              </Typography>
                            </div>
                          </ListItem>
                          <ListItem style={{backgroundColor: '#f6f8f9'}}>
                            <div
                              className={
                                'flex flex-1 justify-center item-center'
                              }
                            >
                              <NavLink to="/learning_cart">
                                <Button variant="contained" color="primary">
                                  Go to Cart
                                </Button>
                              </NavLink>
                            </div>
                          </ListItem>
                        </List>
                      </FuseScrollbars>
                    </React.Fragment>
                  </Card>
                ) : (
                  ''
                )}
              </ReactHover.Hover>
            </ReactHover>
          </NavLink> */}
          <a href={`${global.chat}`} target="_blank">
            <Icon color="action" style={{color: '#014a62'}}>
              mail
            </Icon>
          </a>
          {/* <NavLink to="/apps/chat">
            <ReactHover options={options} className="z-20">
              <ReactHover.Trigger type="trigger" className="z-20">
                <Badge
                  color="secondary"
                  badgeContent={
                    unreadNotificationCount && unreadNotificationCount.unread
                  }
                >
                  <Icon
                    color="action"
                   
                    style={{color: '#014a62'}}
                  >
                    mail
                  </Icon>
                </Badge>
              </ReactHover.Trigger>
              <ReactHover.Hover type="hover" className="z-20" />
            </ReactHover>
          </NavLink> */}

          <React.Fragment>
            <ReactHover options={options} className="flex flex-1 z-20">
              <ReactHover.Trigger type="trigger" className="z-20 w-224">
                <Badge
                  color="secondary"
                  badgeContent={
                    unreadNotificationCount && unreadNotificationCount.unread
                  }
                >
                  <Icon color="action" style={{color: '#014a62'}}>
                    notifications
                  </Icon>
                </Badge>
              </ReactHover.Trigger>
              <ReactHover.Hover
                type="hover"
                className="dropdownHover flex flex-row overflow-hidden w-320"
                style={{transform: 'translate(-80%, 1%)'}}
              >
                <Card style={{transform: 'translate(-80%, 1%)'}}>
                  {fourUnreadNotifications &&
                  fourUnreadNotifications.docs.length > 0 ? (
                    <div>
                      <Tabs
                        value={tabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        <Tab label="Instructor" />
                        <Tab label="Learner" />
                      </Tabs>
                      <List>
                        {tabValue === 0 && (
                          <InstructorNotification
                            notification={fourUnreadNotifications.docs}
                          />
                        )}
                        {tabValue === 1 && (
                          <StudentNotification
                            notification={fourUnreadNotifications.docs}
                          />
                        )}

                        <ListItem>
                          <div className="flex flex-1 flex-row">
                            <NavLink
                              to="#"
                              onClick={e => {
                                this.props.readAllNotifications()
                                e.preventDefault()
                              }}
                            >
                              <Typography>Mark as read</Typography>
                            </NavLink>
                            <NavLink
                              className="flex flex-1 items-end justify-end content-end"
                              to={'/notification'}
                            >
                              <Typography>See All</Typography>
                            </NavLink>
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  ) : (
                    <div>
                      <Tabs
                        value={tabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        <Tab label="Instructor" />
                        <Tab label="Learner" />
                      </Tabs>
                      <List>
                        <ListItem>
                          <div className="flex flex-1 flex-row">
                            <NavLink
                              to="#"
                              onClick={e => {
                                this.props.readAllNotifications()
                                e.preventDefault()
                              }}
                            >
                              <Typography>Mark as read</Typography>
                            </NavLink>
                            <NavLink
                              className="flex flex-1 items-end justify-end content-end"
                              to={'/notification'}
                            >
                              <Typography>See All</Typography>
                            </NavLink>
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  )}
                </Card>
              </ReactHover.Hover>
            </ReactHover>
          </React.Fragment>
          <UserMenu />
        </div>

        <Popover
          className="flex w-400"
          open={Boolean(userMenu)}
          anchorEl={userMenu}
          // onClose={this.userMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: 'py-8',
          }}
        >
          <React.Fragment>
            <List>
              {cart
                .filter(function(item) {
                  return item.Course
                })
                .map((item, index) => {
                  return (
                    <ListItem alignItems="flex-start" key={index}>
                      <ListItemAvatar>
                        <img
                          alt="course-1"
                          src={
                            item.Course.thumbnail
                              ? item.Course.thumbnail
                              : 'assets/images/courses/course-default-thumbnail.jpg'
                          }
                          width={90}
                          height={75}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.Course.title}
                        secondary={
                          <React.Fragment>
                            {item.Course.CreatorUser.name}
                            {/*}<Typography component="span" style={{color:"grey", textDecoration:"line-through"}}>
                                                        {item.Course.Price.pricing.IDR}
                                                    </Typography>*/}
                            <Typography component="span" color="error">
                              {item.Course.Price.pricing &&
                              item.Course.Price.pricing.IDR === '0'
                                ? 'FREE'
                                : convertToRupiah(
                                    item.Course.Price.pricing.IDR
                                  )}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  )
                })}

              <ListItem style={{backgroundColor: '#f6f8f9'}}>
                <div className={'flex flex-1 justify-center item-center'}>
                  <NavLink to="/learning_cart">
                    <Button variant="contained" color="primary">
                      Go to Cart
                    </Button>
                  </NavLink>
                </div>
              </ListItem>
            </List>
          </React.Fragment>
        </Popover>
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCart: Actions.getCart,
      getWishlist: Actions.getWishlist,
      getUnreadNotificationCount: Actions.getUnreadNotificationsCount,
      getFourUnreadNotifications: Actions.getFourUnreadNotifications,
      readAllNotifications: Actions.readAllNotifications,
    },
    dispatch
  )
}

function mapStateToProps({auth, academyApp}) {
  return {
    user: auth.user,
    cart: academyApp.courses.cart,
    wishlist: academyApp.courses.wishlist,
    unreadNotificationCount: academyApp.notification.unreadNotifications,
    fourUnreadNotifications: academyApp.notification.fourUnreadNotifications,
  }
}

export default withReducer(
  'academyApp',
  reducer
)(
  withStyles(styles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(ToolbarMenuSet2)
  )
)
