import gql from 'graphql-tag'

export const GET_EVENT_BY_OWNER = gql`
  query(
    $offset: Int
    $limit: Int
    $order: [event_schedules_order_by!]
    $query: String
    $categoryIds: [Int!]
    $ratingComparison: float8_comparison_exp
    $pricing: Int_comparison_exp
    $eventTypes: [Int!]
    $durations: [event_schedules_bool_exp]
    $author: uuid
  ) {
    event_schedules(
      offset: $offset
      limit: $limit
      order_by: $order
      where: {
        title: {_ilike: $query}
        category: {_in: $categoryIds}
        average_rating: $ratingComparison
        pricing_idr: $pricing
        event_type: {id: {_in: $eventTypes}}
        _or: $durations
        creator: {_eq: $author}
      }
    ) {
      id
      event_category {
        id
        name
      }
      title
      thumbnail
      pricing_idr
      datestart
      location_info
      creator
      slug
      event_type {
        id
        name
      }
      event_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
        }
      }
    }
  }
`
