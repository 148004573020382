import {FuseLoadable} from '@fuse'

export const ProfilePageConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
          folded: false,
          position: 'left',
        },
        toolbar: {
          display: true,
          style: 'fixed',
          position: 'below',
        },
        footer: {
          display: false,
          style: 'static',
          position: 'below',
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/timeline',
      component: FuseLoadable({
        loader: () => import('./ProfilePage'),
      }),
    },
  ],
}
