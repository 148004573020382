import gql from 'graphql-tag'

export const GET_MY_BOOKS = gql`
  query($offset : Int, $limit : Int, $userId : uuid, $progress : Int_comparison_exp, $keyword : String, $categoryIds : [Int!], $orderBy : [academy_books_order_by!], $type : Int){
  academy_books(where : {
    academy_book_owners : {
      user_id : {
        _eq : $userId
      }
      progress : $progress
    }
    title : {
      _ilike : $keyword
    }
    category : {
      _in : $categoryIds
    }
    book_type : {
      _eq : $type
    }
  }
  	offset : $offset,
    limit : $limit,
    order_by : $orderBy
  ){
    id
    title
    thumbnail
    slug
    isbn
    type : academy_book_type  { 
    	id
    }
    academy_book_owners {
      id
      progress
    }
    academy_course_category{
      id
      name
    }
    average_rating
    count_reviews
    global_user{
      id
      name
      avatar
      learning_contributor_ratings{
        total_rating_avg
        total_rating_count
      }
    }
  }
}
`
