import axios from 'axios'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable'
import history from 'history.js'
import _ from '@lodash'
import {
  getCourseById,
  getCourseBySlug,
} from '../../../../learnings/instructor/store/actions'

export const GET_COURSES = '[ACADEMY APP] GET COURSES'
export const GET_MY_COURSES = '[ACADEMY APP] GET MY COURSES'
export const GET_STUDENT = '[ACADEMY APP] GET STUDENT PROFILE'
export const GET_PROMO = '[ACADEMY APP] GET PROMO COURSES'
export const GET_RECOMMENDATION = '[ACADEMY APP] GET RECOMMENDATION'
export const GET_TRENDING = '[ACADEMY APP] GET TRENDING'
export const GET_CART = '[ACADEMY APP] GET CART'
export const GET_WISHLIST = '[ACADEMY APP] GET WISHLIST'
export const GET_CATEGORIES = '[ACADEMY APP] GET CATEGORIES'
export const GET_COURSE_CATEGORIES = '[ACADEMY APP] GET COURSE CATEGORIES'
export const SET_COURSES_SEARCH_TEXT = '[ACADEMY APP] SET COURSES SEARCH TEXT'
export const SET_COURSES_CATEGORY_FILTER =
  '[ACADEMY APP] SET COURSES CATEGORY FILTER'
export const ADD_TO_CART = '[ACADEMY APP] ADD TO CART'
export const ADD_TO_WISHLIST = '[ACADEMY APP] ADD TO WISHLIST'
export const REMOVE_FROM_WISHLIST = '[ACADEMY APP] REMOVE FROM WISHLIST'
export const CHECKOUT = '[ACADEMY APP] CHECKOUT'
export const GET_COURSES_INSTRUCTOR = '[ACADEMY APP] GET COURSES INSTUCTOR'
export const GET_COURSE_EXPERT = '[ACADEMY APP] GET COURSES EXPERT LOCATOR'
export const SAVE_RATING_REVIEW = '[ACADEMY APP] SAVE RATING & REVIEW'
export const GET_BOOKS = '[ACADEMY APP] GET BOOKS'
export const GET_PURCHASE_HISTORY = '[ACADEMY APP] GET PURCHASE HISTORY'

const userData = global.data_user
if (_.isEmpty(userData)) {
  // history.push('/learning')
}

export function getCourses() {
  const request = axios.get('/api/academy-app/courses',{
    withCredentials: true
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_COURSES,
        payload: response.data,
      })
    )
}

export function getCategories() {
  const request = axios.get('/api/academy-app/categories',{
    withCredentials: true
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_CATEGORIES,
        payload: response.data,
      })
    )
}

export function getCourseCategories() {
  const request = axios.get(global.api.dev + '/academy/category/get/', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_COURSE_CATEGORIES,
        payload: response.data,
      })
    )
}

// export function getCourseRecommended(limit,page){
export function getCourseRecommended() {
  const request = axios.get(global.api.dev + '/academy/course/recommendation', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_RECOMMENDATION,
        payload: response.data.data,
      })
    })
}

// export function getCourseTrending(limit,page){
export function getCourseTrending() {
  // const request=axios.get('https://poseidon.worklifebeyond.com/academy/course/get?limit='+limit+"&page="+page,
  const request = axios.get(global.api.dev + '/academy/course/trending', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_TRENDING,
        payload: response.data.data,
      })
    })
}

// export function getCoursePromo(limit,page){
export function getCoursePromo(limit, page) {
  // const request=axios.get('https://poseidon.worklifebeyond.com/academy/course/promo?limit='+limit+"&page="+page,
  const request = axios.get(global.api.dev + '/academy/course/promo', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_PROMO,
        payload: response.data.data,
      })
    })
}

export function getMyCourses() {
  const userData = global.data_user
  const request = axios.get(
    global.api.dev + '/academy/student/' + userData.id + '/enrollment',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_MY_COURSES,
        payload: response.data,
      })
    })
}

export function addToCart(courseId) {
  const request = axios.post(
    global.api.dev + '/academy/cart/add',
    {
      courseId: courseId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: ADD_TO_CART,
        })

        dispatch(getCart())

        // getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function checkout(paymentMethod, currency) {
  const request = axios.post(
    global.api.dev + '/academy/cart/checkout',
    {
      paymentMethod: paymentMethod,
      currency: currency,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: CHECKOUT,
        })

        dispatch(getCart())

        // getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function addToWishlist(courseId) {
  const request = axios.post(
    global.api.dev + '/academy/wishlist/add',
    {
      courseId: courseId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: ADD_TO_WISHLIST,
        })

        dispatch(getWishlist())

        // getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function removeFromWishlist(wishlistId) {
  const request = axios.delete(
    global.api.dev + '/academy/wishlist/' + wishlistId + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: REMOVE_FROM_WISHLIST,
        })

        dispatch(getWishlist())

        // getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function getCart() {
  const request = axios.get(global.api.dev + '/academy/cart/get', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_CART,
        payload: response.data,
      })
    })
}

export function getStudentProfile() {
  const userData = global.data_user
  const request = axios.get(
    global.api.dev + '/academy/student/' + userData.id,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_STUDENT,
        payload: response.data,
      })
    })
}

export function getWishlist() {
  const request = axios.get(global.api.dev + '/academy/wishlist/get', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_WISHLIST,
        payload: response.data,
      })
    })
}

export function setCoursesSearchText(event) {
  return {
    type: SET_COURSES_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function setCategoryFilter(event) {
  return {
    type: SET_COURSES_CATEGORY_FILTER,
    category: event.target.value,
  }
}

export function getCourseInstructor(dataInstructors) {
  const request = Promise.all(
    dataInstructors.map((id, index) => {
      return axios.get(
        global.api.dev + `/academy/instructor/${id}/course?limit=4`,
        {
          headers: {
            Authorization: 'Bearer ' + global.access_token,
          },
          withCredentials:true,
        }
      )
    })
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSES_INSTRUCTOR,
        payload: response,
      })
    })
}

export function getCourseExpert(search) {
  const request = axios.get(
    `${global.api.dev}/academy/course/get?search=${search}&limit=8`,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_EXPERT,
        payload: response.data.data,
      })
    })
}

export function saveRatingReview(data, handleSnackbar, slug) {
  // .
  const request = axios.patch(
    `${global.api.dev}/academy/enrollment/${data.enrollmentId}/update`,
    {
      rating: data.rating,
      reviewText: data.reviewText,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: SAVE_RATING_REVIEW,
        })

        dispatch(getCourseById(response.data.courseId))

        dispatch(getCourseBySlug(slug))
        handleSnackbar()
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function getBooks() {
  const request = axios.get(`${global.api.dev}/academy/book/get`, {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_BOOKS,
        payload: response.data.data,
      })
    })
}

export function getPurchaseHistory() {
  const userData = global.data_user
  const request = axios.get(
    `${global.api.dev}/academy/student/${userData.id}/invoice`,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_PURCHASE_HISTORY,
        payload: response.data.data,
      })
    })
}
