import gql from 'graphql-tag'

export const LIST_CATEGORIES = gql`
  query {
    categories: academy_course_categories(where: {parent: {_is_null: true}}) {
      id
      code
      name
      parent
      subcategories: academy_course_categories {
        id
        code
        name
        parent
      }
    }
  }
`
