import {combineReducers} from 'redux'
import widgets from './widgets.reducer'
import course from './course.reducer'
import courses from './courses.reducer'
import event from './event.reducer'
import books from './books.reducer'

const reducer = combineReducers({
  widgets,
  course,
  courses,
  event,
  books,
})

export default reducer
