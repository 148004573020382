import React from 'react'
import {
  AppBar,
  MuiThemeProvider,
  Toolbar,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import connect from 'react-redux/es/connect/connect'
import {Link} from 'react-router-dom'

const FooterLayout1 = ({classes, footerTheme}) => {
  return (
    <MuiThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className="relative z-50"
        style={{backgroundColor: '#f6f8f9'}}
      >
        <Toolbar className="px-72 py-0 flex items-center">
          <div className="flex flex-1">
            <img
              src={'assets/images/logos/wlb-combined-green.svg'}
              className="w-1/4 h-48"
              alt="wlb-logo"
            />
          </div>

          <div>
            <List className="flex flex-col">
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  <b>About</b>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  About Worklife &amp; Beyond
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>Careers</Typography>
              </ListItem>
              <ListItem>
                <Link className="font-medium" to="/instructor/become">
                  <Typography style={{color: '#014a62'}}>
                    Become an Instructor
                  </Typography>
                </Link>
              </ListItem>
            </List>
          </div>
          <div>
            <List className="flex flex-col">
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  <b>Product</b>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  Academic Solution
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  Business Solution
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  Government Solution
                </Typography>
              </ListItem>
            </List>
          </div>
          <div>
            <List className="flex flex-col">
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  <b>Language</b>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>Indonesia</Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>English</Typography>
              </ListItem>
            </List>
          </div>
          <div>
            <List className="flex flex-col">
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  <b>Connect With Us</b>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>English</Typography>
              </ListItem>
              <List className="flex flex-row">
                <ListItem>
                  <img
                    src={'assets/images/logos/fb-icon.svg'}
                    style={{width: '2em', height: '2em'}}
                    alt="fb-icon"
                  />
                </ListItem>
                <ListItem>
                  <img
                    src={'assets/images/logos/google-icon.png'}
                    style={{width: '2em', height: '2em'}}
                    alt="google-icon"
                  />
                </ListItem>
                <ListItem>
                  <img
                    src={'assets/images/logos/linked-icon.svg'}
                    style={{width: '2em', height: '2em'}}
                    alt="linked-in-icon"
                  />
                </ListItem>
              </List>
            </List>
          </div>
          {/*<div>*/}
          {/*<ChatButton></ChatButton>*/}
          {/*</div>*/}
        </Toolbar>
      </AppBar>
      <AppBar
        id="fuse-footer"
        className="relative z-10"
        style={{backgroundColor: '#fff', color: '#014a62'}}
      >
        <Toolbar className="px-72 py-0 flex items-center">
          <div className="flex flex-1">
            © 2019 PT. Kreasi inovasi Digital. All Rights Reserved
          </div>

          <div>
            <List className="flex flex-row">
              <ListItem>
                <Typography style={{color: '#014a62'}}>Home</Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>Service</Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>Term</Typography>
              </ListItem>
              <ListItem>
                <Typography style={{color: '#014a62'}}>
                  Privacy Policy
                </Typography>
              </ListItem>
            </List>
          </div>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  )
}

function mapStateToProps({fuse}) {
  return {
    footerTheme: fuse.settings.footerTheme,
  }
}

export default connect(mapStateToProps)(FooterLayout1)
