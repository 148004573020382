import React from 'react'
import {Divider, Paper, Typography} from '@material-ui/core'

const styles = {
  tealFont: {
    color: '#003d51',
  },
}

const DashboardDescription = props => {
  return (
    <Paper className="w-full mb-28">
      <div className="flex flex-col">
        <Typography
          // variant="h5"
          // component="h5"
          className="my-20 mx-16 font-bold text-blue-dark text-16"
          style={styles}
        >
          Keterangan Pembelajaran Mikro
        </Typography>
        <Divider className="mx-16" />
        <div className="py-32 px-16">
          <div className="flex flex-row">
            <img
              // src={
              //   course.thumbnail
              //     ? course.thumbnail
              //     : 'assets/images/courses/course-default-thumbnail.jpg'
              // }
              src={
                props.location.state.microLearning.icon_url ||
                'assets/images/courses/course-default-thumbnail.jpg'
              }
              alt="course-1"
              className="h-320 w-320 mr-32"
              style={{objectFit: 'contain', objectPosition: 'top'}}
            />
            <Typography>
              {props.location.state.microLearning.description ||
                'Tidak Ada Keterangan'}
            </Typography>
            {/* {course.description ? (
                <div dangerouslySetInnerHTML={{__html: course.description}} />
              ) : (
                'No description'
              )} */}
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default DashboardDescription
