import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Tabs, Tab} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {LearningSearch, BookSearch} from '../'

const styles = (theme) => ({
  layoutRoot: {},
  tabsIndicator: {
    backgroundColor: '#38aae6',
  },
})

class TabSeeAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
  }

  componentWillMount() {
    const path = window.location.pathname.split('/')[2]

    switch (path) {
      case 'courses':
        this.setState({value: 0})
        break
      case 'book':
        this.setState({value: 1})
        break
      case 'event':
        this.setState({value: 2})
        break
      case 'learning-contributor':
        this.setState({value: 3})
        break
      case 'social-learning':
        this.setState({value: 4})
        break
      case 'micro-learning':
        this.setState({value: 5})
        break
      default:
        this.setState({value: 0})
    }
  }

  handleChange = (_, value) => {
    this.setState({value})
  }
  render() {
    const {classes} = this.props
    const {value} = this.state

    return (
      <div>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{indicator: classes.tabsIndicator}}
          centered
        >
          <Tab
            label="Courses"
            component={Link}
            to="/tabs-all/courses"
            className="capitalize"
          />
          <Tab
            label="Book"
            component={Link}
            to="/tabs-all/book"
            className="capitalize"
          />
          <Tab
            label="Event"
            component={Link}
            to="/tabs-all/event"
            className="capitalize"
          />
          <Tab
            label="Learning Contributor"
            component={Link}
            to="/tabs-all/learning-contributor"
            className="capitalize"
          />
          <Tab
            label="Social Learning"
            component={Link}
            to="/tabs-all/social-learning"
            className="capitalize"
          />
          <Tab
            label="Micro Learning"
            component={Link}
            to="/tabs-all/micro-learning"
            className="capitalize"
          />
        </Tabs>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(TabSeeAll)
