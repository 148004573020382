import gql from 'graphql-tag'

export const UPDATE_MICROLEARNING = gql`
  mutation($microlearningId: uuid, $set: microlearnings_set_input) {
    update_microlearnings(where: {id: {_eq: $microlearningId}}, _set: $set) {
      returning {
        id
      }
    }
  }
`
