import axios from 'axios'
import jwtDecode from 'jwt-decode'
import FuseUtils from '@fuse/FuseUtils'
import {reactLocalStorage} from 'reactjs-localstorage'
import global from 'app/global-variable'

class jwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors()
    this.handleAuthentication()
  }

  setInterceptors = () =>
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (err) => {
        return new Promise((resolve, reject) => {
          try {
            if (
              err.response.status === 401 &&
              err.config &&
              !err.config.__isRetryRequest
            ) {
              /*console.log('401!!!')
              return this.requestNewAccessToken().then(response => {
                err.config.__isRetryRequest = true
                return axios(err.config)
              })*/
              this.setSession(null)
              this.emit('onAutoLogout', 'Access token expired')
            }

            throw err
          } catch (error) {
            if (err.response.status === 400) {
              window.location.reload()
            }
          }
        })
      }
    )

  requestNewAccessToken = () => {
    const userData = global.data_user
    console.log(JSON.stringify(userData))
    axios
      .post(global.api.dev + '/global/accounts/session/regen', {
        refresh_token: userData.oauth.refresh_token,
        access_token: userData.oauth.access_token,
      })
      .then((response) => {
        if (response.status === 200) {
          userData.oauth.refresh_token = response.data.refresh_token
          userData.oauth.access_token = response.data.access_token
          reactLocalStorage.setObject('userData', userData)
          // reactLocalStorage.setObject(
          //   'userData.oauth.access_token',
          //   response.data.access_token,
          // )
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + response.data.access_token
        }
      })
      .catch((error) => {})
  }

  handleAuthentication = () => {
    let access_token = this.getAccessToken()

    if (!access_token) {
      return
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token)
      this.emit('onAutoLogin', true)
    } else {
      this.setSession(null)
      this.emit('onAutoLogout', 'access_token expired')
    }
  }

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data,{
        withCredentials: true
      }).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token)
          resolve(response.data.user)
        } else {
          reject(response.data.error)
        }
      })
    })
  }

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/auth', {
          data: {
            email,
            password,
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
    })
  }

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/auth/access-token', {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
    })
  }

  updateUserData = (user) => {
    return axios.post('/api/auth/user/update', {
      user: user,
    },{
      withCredentials: true
    })
  }

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
    } else {
      localStorage.removeItem('jwt_access_token')
      delete axios.defaults.headers.common['Authorization']
    }
  }

  logout = () => {
    this.setSession(null)
  }

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false
    }
    const decoded = jwtDecode(access_token)
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      console.warn('access token expired')
      return false
    } else {
      return true
    }
  }

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token')
  }
}

const instance = new jwtService()

export default instance
