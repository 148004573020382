import React from 'react'
import {withStyles, Icon, ListItem, ListItemText} from '@material-ui/core'
import {NavLink, withRouter} from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as Actions from 'app/store/actions'
import FuseNavBadge from './../FuseNavBadge'

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
}

const defaultProps = {}

const styles = (theme) => ({
  root: {
    minHeight: 48,
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + '!important',
      pointerEvents: 'none',
      '& .list-item-text-primary': {
        color: 'inherit',
      },
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
    '& .list-item-icon': {},
    '& .list-item-text': {
      padding: '0 0 0 16px',
    },
    color: 'inherit!important',
    textDecoration: 'none!important',
    '&.dense': {
      padding: '8px 12px 8px 12px',
      minHeight: 40,
      '& .list-item-text': {
        padding: '0 0 0 8px',
      },
    },
  },
})

function FuseNavHorizontalItem({
  item,
  classes,
  nestedLevel,
  userRole,
  navbarCloseMobile,
  dense,
}) {
  if (
    item.auth &&
    (!item.auth.includes(userRole) ||
      (userRole !== 'guest' &&
        item.auth.length === 1 &&
        item.auth.includes('guest')))
  ) {
    return null
  }

  return (
    <ListItem
      button
      component={NavLink}
      to={item.url}
      activeClassName="active"
      className={classNames('list-item', classes.root, dense && 'dense')}
      onClick={navbarCloseMobile}
      exact={item.exact}
    >
      {item.icon && (
        <Icon className="list-item-icon text-16 flex-no-shrink" color="action">
          {item.icon}
        </Icon>
      )}
      <ListItemText
        className="list-item-text"
        primary={item.title}
        classes={{primary: 'text-14 list-item-text-primary'}}
      />
      {item.badge && <FuseNavBadge className="ml-8" badge={item.badge} />}
    </ListItem>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navbarCloseMobile: Actions.navbarCloseMobile,
    },
    dispatch
  )
}

function mapStateToProps({auth}) {
  return {
    userRole: auth.user.role,
  }
}

FuseNavHorizontalItem.propTypes = propTypes
FuseNavHorizontalItem.defaultProps = defaultProps

const NavHorizontalItem = withStyles(styles, {withTheme: true})(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FuseNavHorizontalItem)
  )
)

export default NavHorizontalItem
