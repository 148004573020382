import gql from 'graphql-tag'

export const GET_MICRO_LEARNING_BY_OWNER = gql`
  query(
    $offset: Int!
    $limit: Int!
    $userId: uuid
    $contributorUserId: uuid!
    $query: String!
    $order: [microlearnings_order_by!]
  ) {
    microlearnings(
      offset: $offset
      limit: $limit
      order_by: $order
      where: {
        name: {_ilike: $query}
        status: {_eq: "ACTIVE"}
        created_by_user_id: {_eq: $contributorUserId}
      }
    ) {
      id
      is_subscibed: microlearning_members_aggregate(
        where: {user_id: {_eq: $userId}}
      ) {
        aggregate {
          # returns 1 if subscribed
          count
        }
      }
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      global_user {
        id
        name
        avatar
      }
      name
      date_added
      status
      price
      type
      icon_url
      average_rating
      review_count
      academy_course_category {
        id
        name
      }
      description
    }
  }
`
