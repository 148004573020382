import React, {useState, useEffect} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Tabs, Tab} from '@material-ui/core'
import {Link} from 'react-router-dom'

const styles = () => ({
  layoutRoot: {},
  tabsIndicator: {
    backgroundColor: '#38aae6',
  },
})

const WishlistTab = (props) => {
  const {classes} = props
  const path = window.location.pathname.split('/')[2]

  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    switch (path) {
      case 'course':
        return setTabValue(0)
      case 'book':
        return setTabValue(1)
      case 'event':
        return setTabValue(2)
      default:
        setTabValue(0)
    }
  }, [path, tabValue])

  // eslint-disable-next-line
  const handleChange = (_, value) => {
    setTabValue(value)
  }

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        classes={{indicator: classes.tabsIndicator}}
        centered
      >
        <Tab
          label="Course"
          component={Link}
          to="/wishlist/course"
          className="capitalize"
        />
        <Tab
          label="Book"
          component={Link}
          to="/wishlist/book"
          className="capitalize"
        />
        <Tab
          label="Event"
          component={Link}
          to="/wishlist/event"
          className="capitalize"
        />
      </Tabs>
    </div>
  )
}

export default withStyles(styles, {withTheme: true})(WishlistTab)
