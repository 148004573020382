import axios from 'axios'
import {axiosInstanceBearer} from 'app/services/restApiService'
import global from 'app/global-variable'
import history from 'history.js'
import _ from '@lodash'

export const GET_BOOKS = '[ACADEMY APP] GET BOOKS'
export const GET_BOOK_BY_ISBN = '[ACADEMY APP] GET BOOK BY ISBN'
export const GET_BOOK_CHAPTERS = '[ACADEMY APP] GET BOOK CHAPTERS'

export function getBooks() {
  const request = axiosInstanceBearer.get(
    `${global.api.dev}/academy/book/get`,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: GET_BOOKS,
        payload: response.data.data,
      })
    })
}

export function getBookByIsbn(isbn) {
  const userData = global.data_user
  if (_.isEmpty(userData)) {
    // history.push('/')
  }
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/book/' + isbn,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_BOOK_BY_ISBN,
        payload: response.data,
      })
    )
}

export function getBookChapters(isbn) {
  const userData = global.data_user
  if (_.isEmpty(userData)) {
    // history.push('/')
  }
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/book/' + isbn + '/bookContent/get',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_BOOK_CHAPTERS,
        payload: response.data,
      })
    )
}
