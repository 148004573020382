import axios from 'axios'
import global from 'app/global-variable'

export const GET_CATEGORIES = '[ACADEMY APP] GET CATEGORIES'

export function getGlobalCategories() {
  const request = axios.get(`${global.api.dev}/academy/category/get`, {
    headers: {
      Authorization: `Bearer ${global.access_token}`,
    },
    withCredentials:true,
  })

  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: GET_CATEGORIES,
        payload: response.data.data,
      })
    })
}
