import gql from 'graphql-tag'

export const GET_GLOBAL_CLASSROOM = gql`
  query($limit: Int, $offset: Int) {
    classrooms(limit: $limit, offset: $offset) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      is_finished
      learning_contributors: classroom_members(
        where: {
          _or: [{is_individual_mentor: {_eq: true}}, {is_mentor: {_eq: true}}]
        }
      ) {
        global_user {
          id
          name
          avatar
        }
      }
      members: classroom_members(
        where: {
          is_mentor: {_eq: false}
          is_individual_mentor: {_eq: false}
          is_admin: {_eq: false}
        }
      ) {
        id
        global_user {
          id
          avatar
          name
        }
      }
      academy_course_category {
        code
        name
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
    }
  }
`

export const GET_RECOMMENDED_TRENDING_CLASSROOM = gql`
  query(
    $offset: Int
    $limit: Int
    $isRecommended: Boolean
    $sortByTrending: Boolean
    $showJoined: Boolean
  ) {
    getListClassroom(
      isRecommended: $isRecommended
      sortByTrending: $sortByTrending
      offset: $offset
      limit: $limit
      showJoined: $showJoined
    ) {
      count
      classrooms {
        id
        name
        description
        price
        avatar_url
        average_review
        review_count
        owner {
          id
          avatar
          name
          email
        }
        members {
          id
          is_admin
          is_mentor
          user {
            id
            name
            avatar
          }
        }
        privacy
      }
    }
  }
`
