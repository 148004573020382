import React from 'react'
import {Avatar, Typography, CircularProgress} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {LEARNING_CONTRIBUTOR_LIST_RESPONSES} from 'app/graphql/queries/index'
import {Link} from 'react-router-dom'

const MicroLearningResponse = props => {
  const microlearningId = props.location.state.microLearning.id
  const {data, loading: loadingResponses, error: errorResponses} = useQuery(
    LEARNING_CONTRIBUTOR_LIST_RESPONSES,
    {
      variables: {
        id: microlearningId,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'organization-staff',
        },
      },
    }
  )

  if (loadingResponses) {
    return <CircularProgress />
  } else if (errorResponses) {
    return <div>{JSON.stringify(errorResponses)}</div>
  }

  return (
    <table className="simple clickable">
      <thead style={{display: 'none'}}>
        <tr>
          <th colSpan="7"></th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-1">
          <td></td>
          <td>Nama</td>
          <td>Status</td>
          <td>Jawaban Benar</td>
          <td>Nilai</td>
          <td>Aksi</td>
        </tr>
        {data &&
          data.microlearnings.length > 0 &&
          data.microlearnings[0].microlearning_members.map(res => {
            return (
              <>
                <tr className="border-1">
                  <td>
                    <Avatar
                      src={
                        res.global_user.avatar ||
                        'assets/images/courses/course-default-thumbnail.jpg'
                      }
                      className="w-48 h-48"
                    />
                  </td>
                  <td>{res.global_user.name}</td>
                  <td>{res.is_finished ? 'Selesai' : 'Tidak Selesai'}</td>
                  <td>
                    {res.question_answered_count + '/' + res.daily_question}
                  </td>
                  <td>{res.score}</td>
                  <td>
                    <Typography
                      component={Link}
                      to={`/instructor/micro-learnings/response/${microlearningId}/${res.global_user.id}`}
                      style={{color: '#039be5'}}
                    >
                      Rincian
                    </Typography>
                  </td>
                </tr>
              </>
            )
          })}
      </tbody>
    </table>
  )
}

export default MicroLearningResponse
