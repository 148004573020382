import gql from 'graphql-tag'

export const SEARCH_MICROLEARNING = gql`
  query(
    $userId: uuid!
    $orderBy: [microlearnings_order_by!]
    $offset: Int
    $limit: Int
    $categoryIds: [Int!]
    $levels: [Int!]
    $languageIds: [Int!]
    $price: Int_comparison_exp
    $query: String
    $rating: float8_comparison_exp
  ) {
    microlearnings_aggregate(
      where: {
        average_rating: $rating
        price: $price
        language_id: {_in: $languageIds}
        level: {_in: $levels}
        category_id: {_in: $categoryIds}
        name: {_ilike: $query}
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings(
      where: {
        average_rating: $rating
        name: {_ilike: $query}
        price: $price
        language_id: {_in: $languageIds}
        level: {_in: $levels}
        category_id: {_in: $categoryIds}
      }
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
      name
      icon_url
      question_count
      microlearning_members(
        where: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
      ) {
        id
        progress
        score
      }
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          count
          avg {
            star
          }
        }
      }
      academy_course_category {
        id
        name
        parent: academy_course_category {
          id
          name
        }
      }
      global_language {
        id
        name
        language_code
      }
      date_added
      status
      level
      topic
      question_count
      global_user {
        id
        name
        avatar
      }
      type
      company_profile {
        id
        brand_name
        legal_name
      }
    }
  }
`
