import gql from 'graphql-tag'

export const GET_CROSS_MENTORING_CLASSROOM = gql`
  query($limit: Int, $offset: Int) {
    classrooms(
      limit: $limit
      offset: $offset
      where: {versioning_id: {_is_null: false}}
    ) {
      id
      avatar_url
      name
      description
      price
      rules
      academy_course_category {
        code
        name
      }
      #Classroom Owner
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            # Average Review
            star
          }
        }
      }
    }
  }
`
