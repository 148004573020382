import gql from 'graphql-tag'

export const GET_CROSS_MENTORING = gql`
  query {
    global_users(
      where: {
        classroom_members: {
          is_admin: {_eq: true}
          classroom: {versioning_id: {_is_null: false}}
        }
      }
    ) {
      id
      name
      avatar
      people_work_placements {
        company_profile {
          brand_name
          legal_name
        }
        company_job_profile {
          position
          title
        }
      }
      # Data review perorangan
      classrooms_aggregate(
        where: {classroom_members: {is_admin: {_eq: true}}}
      ) {
        nodes {
          classroom_reviews_aggregate {
            aggregate {
              avg {
                star
              }
              count
            }
          }
        }
      }
      # Data per classroom
      classrooms(where: {classroom_members: {is_admin: {_eq: true}}}) {
        id
        name
        classroom_reviews_aggregate {
          aggregate {
            avg {
              star
            }
            count
          }
        }
      }
    }
  }
`
