import gql from 'graphql-tag'

export const GET_REVIEW_BY_ISBN = gql`
  query($isbn: String, $rating: [float8!]!, $userId: uuid) {
    academy_book_reviews_aggregate(where: {isbn: {_eq: $isbn}}) {
      aggregate {
        avg {
          rating
        }
        count
      }
    }

    academy_book_reviews(where: {isbn: {_eq: $isbn}, rating: {_in: $rating}}) {
      #reviewernya
      id
      global_user {
        id
        name
        avatar
        email
      }

      #reviewnya
      rating
      review_text
      date_added

      #reply dari ownernya
      review_reply
      review_reply_date
    }

    is_reviewed: academy_book_reviews(
      where: {isbn: {_eq: $isbn}, global_user: {id: {_eq: $userId}}}
    ) {
      id
      rating
    }

    total_owned: academy_book_owners_aggregate(
      where: {user_id: {_eq: $userId}, isbn: {_eq: $isbn}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_TOTAL_OWNED_BY_ISBN = gql`
  query($isbn: String!, $userId: uuid!) {
    academy_book_owners_aggregate(
      where: {user_id: {_eq: $userId}, isbn: {_eq: $isbn}}
    ) {
      aggregate {
        count
      }
    }
  }
`

// (where: { isbn: { _eq: $isbn } }, { global_user: { id: { _eq: $userId } } } )
