import gql from 'graphql-tag'

export const GET_QUESTION_BY_MICROLEARNING = gql`
  query($id: uuid!, $offset: Int, $limit: Int) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      global_user {
        id
        name
        avatar
      }
      icon_url
      name
      date_added
      status
      microlearning_questions_aggregate {
        aggregate {
          count
        }
      }
      microlearning_questions(offset: $offset, limit: $limit) {
        id
        question
        date_added
        question_type
        microlearning_question_answers {
          answer
          is_correct
        }
      }
    }
  }
`
