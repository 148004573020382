import gql from 'graphql-tag'

export const GET_ALL_MICRO_LEARNING = gql`
  query($offset: Int!, $limit: Int!, $user_id: uuid) {
    microlearnings(
      offset: $offset
      limit: $limit
      where: {status: {_eq: "ACTIVE"}}
    ) {
      id
      is_subscibed: microlearning_members_aggregate(
        where: {user_id: {_eq: $user_id}}
      ) {
        aggregate {
          # returns 1 if subscribed
          count
        }
      }
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
      global_user {
        id
        name
        avatar
      }
      name
      date_added
      status
      price
      type
      icon_url
      company_profile {
        id
        brand_name
        legal_name
      }
    }
  }
`

export const GET_RECOMMENDATION_TRENDING__MICRO_LEARNING = gql`
  query(
    $offset: Int
    $limit: Int
    $isRecommended: Boolean
    $sortByTrending: Boolean
  ) {
    getListMicrolearning(
      isRecommended: $isRecommended
      sortByTrending: $sortByTrending
      offset: $offset
      limit: $limit
    ) {
      count
      microlearnings {
        id
        name
        description
        price
        icon_url
        average_rating
        review_count
        owner {
          id
          avatar
          name
          email
        }
        company_profile {
          id
          brand_name
          legal_name
        }
      }
    }
  }
`

export const LEARNING_CONTRIBUTOR_LIST_MICRO_LEARNINGS = gql`
  query(
    $offset: Int
    $limit: Int
    $user_id: uuid
    $type: String!
    $search: String!
    $orderBy: [microlearnings_order_by!]
  ) {
    microlearnings(
      offset: $offset
      limit: $limit
      where: {
        name: {_ilike: $search}
        type: {_eq: $type}
        global_user: {id: {_eq: $user_id}}
      }
      order_by: $orderBy
    ) {
      id
      slug
      description
      total_finished
      total_earned
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          avg {
            star
          }
          count
        }
      }
      microlearning_questions {
        id
        question
        question_type
        date_added
        microlearning_question_answers {
          answer
          is_correct
        }
      }
      academy_course_category {
        id
        name
      }
      global_user {
        id
        name
        avatar
      }

      name
      date_added
      status
      price
      type
      icon_url
    }
  }
`
export const LEARNING_CONTRIBUTOR_LIST_LEARNERS = gql`
  query(
    $microlearningId: uuid
    $limit: Int
    $offset: Int
    $search: String!
    $orderBy: [microlearning_members_order_by!]
  ) {
    microlearning_members(
      offset: $offset
      limit: $limit
      where: {
        microlearning_id: {_eq: $microlearningId}
        global_user: {name: {_ilike: $search}}
      }
      order_by: $orderBy
    ) {
      id
      global_user {
        id
        name
        email
        avatar
      }
      schedule
      schedule_time
      score
      is_finished
      daily_question
      expired_unanswered_question_count
      question_answered_count
    }
  }
`

export const LEARNING_CONTRIBUTOR_LIST_REVIEWS = gql`
  query($offset: Int, $limit: Int, $microlearningId: uuid) {
    microlearning_reviews(
      offset: $offset
      limit: $limit
      where: {microlearning_id: {_eq: $microlearningId}}
    ) {
      id
      global_user {
        id
        avatar
        name
      }
      description
      star
    }
  }
`

export const LEARNING_CONTRIBUTOR_LIST_COMMENTS = gql`
  query($offset: Int, $limit: Int, $microlearningId: uuid) {
    microlearning_comments(
      offset: $offset
      limit: $limit
      where: {microlearning_id: {_eq: $microlearningId}}
    ) {
      id
      content
      global_user {
        id
        name
        avatar
      }
      date_added
    }
  }
`

export const LEARNING_CONTRIBUTOR_LIST_RESPONSES = gql`
  query($id: uuid!, $userId: uuid, $offset: Int, $limit: Int, $query: String) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      name
      icon_url
      question_count
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_members(
        where: {user_id: {_eq: $userId}, global_user: {name: {_ilike: $query}}}
        offset: $offset
        limit: $limit
      ) {
        id
        global_user {
          id
          name
          email
          avatar
        }
        schedule
        schedule_time
        score
        is_finished
        daily_question
        expired_unanswered_question_count
        question_answered_count
      }
    }
  }
`

export const GET_MICROLEARNING_INDIVIDUAL_REPONSE = gql`
  query($microlearningId: uuid!, $userId: uuid!) {
    microlearnings(where: {id: {_eq: $microlearningId}}) {
      id
      name
      status
      question_count
      microlearning_members(where: {user_id: {_eq: $userId}}) {
        id
        score
        question_answered_count
        expired_unanswered_question_count
        global_user {
          id
          name
          avatar
        }
      }
      microlearning_questions {
        question
        question_type
        microlearning_question_answers {
          id
          answer
          is_correct
          microlearning_member_answer_options(
            where: {
              microlearning_member_answer: {
                is_answered: {_eq: true}
                microlearning_member_daily: {
                  microlearning_member: {user_id: {_eq: $userId}}
                }
              }
            }
          ) {
            id
          }
        }
        microlearning_member_answers(
          where: {
            is_answered: {_eq: true}
            microlearning_member_daily: {
              microlearning_member: {user_id: {_eq: $userId}}
            }
          }
        ) {
          id
          score
          description
          date_answered
        }
      }
    }
  }
`
