import gql from 'graphql-tag'

export const GET_REVIEW_COURSE = gql`
  query($id: Int, $userId: uuid) {
    academy_course_enrollments_aggregate(
      where: {
        rating: {_is_null: false}
        course_id: {_eq: $id}
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_course_enrollments(
      where: {
        rating: {_is_null: false}
        course_id: {_eq: $id}
        deletedAt: {_is_null: true}
        global_user: {id: {_eq: $userId}}
      }
    ) {
      id
      global_user {
        id
        name
        avatar
        company_profiles {
          legal_name
          brand_name
        }
      }
      rating
      review_date
      review_text
      review_reply_date
      review_reply_text
    }
  }
`
