import {MenuItem, TextField, Typography} from '@material-ui/core'
import React, {useState} from 'react'
import CreateCoupon from './CreateCoupon'

export default function ManageLanding({value, handleChange, isError}) {
  const [openCoupon, setOpenCoupon] = useState(false)
  // const [page, setPage] = useState(2)
  // const [rowsPerPage, setRowsPerPage] = useState(10)

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage)
  // }

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10))
  //   setPage(0)
  // }

  const handleNewCoupon = () => {
    setOpenCoupon(!openCoupon)
  }
  return (
    <>
      <div className="mb-40">
        <Typography variant="body2">Harga Kursus*</Typography>
        <div className="flex w-2/5">
          <TextField
            variant="outlined"
            margin="normal"
            select
            className="mr-12"
            value={'IDR'}
            disabled
            style={{width: 100}}
          >
            <MenuItem value="IDR">IDR</MenuItem>
          </TextField>
          <TextField
            variant="outlined"
            margin="normal"
            placeholder="Tetapkan harga"
            fullWidth
            type="number"
            value={value.price}
            onChange={handleChange('price')}
            error={isError && value.price === ''}
            helperText={
              isError && value.price === '' && 'Bagian ini diperlukan'
            }
          />
        </div>
      </div>
      {/* coupon onhold */}
      {/* <Typography variant="body2" className="font-semibold mb-20">
        Course Coupons
      </Typography>
      <div className="flex justify-between items-center mb-16">
        <Button
          variant="outlined"
          className="normal-case"
          color="secondary"
          size="large"
          onClick={handleNewCoupon}
        >
          {'Create New Coupon'}
        </Button>
        <TextField
          variant="outlined"
          margin="normal"
          placeholder="Search Coupons"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon color="secondary">search</Icon>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Price</TableCell>
            <TableCell align="right">Remaining</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Expired Date</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={100}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CreateCoupon handleClose={handleNewCoupon} open={openCoupon} />
    </>
  )
}
