import React, {useState} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  withStyles,
} from '@material-ui/core'

const useStyles = () => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 24,
    minHeight: 50,
  },
  btnDel: {
    backgroundColor: '#ef4d5e',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef4d5e',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {wordBreak: 'break-all'},
})

function DeletePopup(props) {
  const {
    open,
    handleClose,
    name,
    mutation,
    feature,
    title,
    deleteType,
    classes,
  } = props
  const [checked, setChecked] = useState(false)
  const handleChange = (e) => setChecked(e.target.checked)
  return (
    <Dialog
      onClose={() => {
        handleClose()
        setChecked(false)
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <div className="flex justify-between items-center p-20">
        <Typography variant="h6" color="error" className={classes.wordBreak}>
          Delete {title || name}?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
            setChecked(false)
          }}
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
      <DialogContent className={classes.content}>
        <Typography variant="body2" display="inline">
          Are you sure you want to delete {name} permanently?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={() => {
            handleClose()
            setChecked(false)
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.btnDel}
          //   disabled={feature ? !deleteType : !checked}
          variant="contained"
          onClick={() => {
            setChecked(false)
            handleClose()
            mutation()
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withStyles(useStyles)(DeletePopup)
