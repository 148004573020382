import React, {useState, useEffect} from 'react'
import {
  withStyles,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Icon,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core'
import connect from 'react-redux/es/connect/connect'
import LinesEllipsis from 'react-lines-ellipsis'
import {useQuery, useLazyQuery} from '@apollo/react-hooks'
import _ from 'lodash'
import {Link} from 'react-router-dom'

import withReducer from 'app/store/withReducer'
import reducer from '../../store/reducers'
import {SearchFilter} from './filter'
import {BOOK_TYPES, SEARCH_BOOK} from 'app/graphql/queries'
import {convertToRupiah} from 'app/utils/helpers'

const styles = () => ({
  card: {
    height: 300,
    position: 'relative',
  },
  media: {height: 140},
  skyBlueColor: {
    color: '#039be5',
  },
  progress: {
    color: '#B8C2CC',
  },
})

const StarLabel = (props) => {
  const {averageRating, classes} = props
  const starArray = []
  const fullStars = parseInt(averageRating)
  for (let i = 0; i < fullStars; i++) {
    starArray.push('star')
  }

  const halfStars = averageRating % 1 > 0 ? 1 : 0
  if (halfStars) {
    starArray.push('star_half')
  }

  for (let i = 0; i < 5 - (fullStars + halfStars); i++) {
    starArray.push('star_border')
  }

  return (
    <div className="flex flex-row justify-start items-center">
      {starArray.map((star, index) => {
        return (
          <Icon
            key={index}
            className={classes.skyBlueColor}
            style={{fontSize: 'small'}}
          >
            {star}
          </Icon>
        )
      })}
    </div>
  )
}

const ratingOptions = [
  {
    label: '4.5 & up',
    value: 4.5,
  },
  {
    label: '4.0 & up',
    value: 4.0,
  },
  {
    label: '3.0 & up',
    value: 3.0,
  },
  {
    label: '2.0 & up',
    value: 2.0,
  },
  {
    label: '1.0 & up',
    value: 1.0,
  },
]

const priceOptions = [
  {
    label: 'Free',
    value: 0,
  },
  {
    label: 'Paid',
    value: 1,
  },
]

const durationOptions = [
  {
    label: '0-2 hours',
    value: 0,
  },
  {
    label: '>2-6 hours',
    value: 2,
  },
  {
    label: '>6-16 hours',
    value: 6,
  },
  {
    label: '>16',
    value: 16,
  },
]

const OrderList = (props) => {
  const {value, onChange} = props

  return (
    <FormControl className="w-128">
      <Select value={value} placeholder="newest" onChange={onChange}>
        <MenuItem value="desc">
          <em>Newest</em>
        </MenuItem>
        <MenuItem value="asc">
          <em>Oldest</em>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

const BookList = (props) => {
  const {classes, data} = props

  return (
    <div className="w-full pb-24 sm:w-1/2 lg:w-1/4 sm:p-16">
      <Link to={`/learning_ebook/${data.isbn}`}>
        <Card variant="outlined" className={classes.card}>
          <CardMedia
            className={classes.media}
            image={data.thumbnail}
            title={data.title}
          />
          <CardContent>
            <div className="min-h-40">
              <Typography className="text-14 font-700">
                <LinesEllipsis
                  text={data.title}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </Typography>
            </div>
            <Typography className="text-13 font-600 mt-4" color="textSecondary">
              <LinesEllipsis
                text={_.isNull(data.author) ? '-' : data.author.name}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </Typography>
            <div className="flex flex-row items-center mt-12">
              <StarLabel averageRating={data.average_rating} {...props} />
              <Typography className="text-11">
                &nbsp;{' '}
                {data.average_rating ? data.average_rating.toFixed(1) : '-'}
                <span className="text-grey">(-)</span>
              </Typography>
            </div>
            <Typography
              className="text-14 font-400 text-right mt-8"
              variant="h6"
            >
              <b>{convertToRupiah(data.pricing_idr)}</b>
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </div>
  )
}

const BookSearch = (props) => {
  const {classes, searchGlobalValue} = props

  const {data: dataBookTypes} = useQuery(BOOK_TYPES)
  const [searchBook, {data: dataBooks, loading: isLoadingBook}] = useLazyQuery(
    SEARCH_BOOK
  )

  const [books, setBooks] = useState([])
  const [sortBy, setSortBy] = useState('desc')
  const [filterBy, setFilterBy] = useState({
    type: [],
    category: [],
    level: [],
    language: [],
    price: [],
    priceMin: '',
    priceMax: '',
    ratings: '',
    duration: [],
  })
  const [isOpenRangePrice, setIsOpenRangePrice] = useState(false)

  useEffect(() => {
    const {price, priceMin, priceMax, ratings, duration} = filterBy

    if (searchGlobalValue !== '') {
      let variables = {
        offset: 0,
        order: {
          date_added: sortBy,
        },
        query: `%${searchGlobalValue}%`,
        typeIds: filterBy.type,
        durations: [
          {
            duration_seconds: {
              _gte: null,
              _lte: null,
            },
          },
        ],
      }

      if (price.length > 0) {
        if (price.includes('0') && price.includes('1')) {
          delete variables.price
          setIsOpenRangePrice(false)
        } else if (price.includes('0')) {
          variables = {
            ...variables,
            pricing: {...variables.price, _eq: 0},
          }
          setIsOpenRangePrice(false)
        } else if (price.includes('1')) {
          variables = {
            ...variables,
            pricing: {...variables.price, _gt: 0},
          }
          setIsOpenRangePrice(true)
        }
        if (priceMin !== '' || priceMax !== '') {
          const _gte = priceMin === '' ? null : Number(priceMin)
          const _lte = priceMax === '' ? null : Number(priceMax)
          variables = {
            ...variables,
            pricing: {_gte, _lte},
          }
        }
      } else if (price.length === 0) {
        setIsOpenRangePrice(false)
      }

      if (ratings !== '') {
        variables = {
          ...variables,
          rating: {
            _gte: Number(ratings),
          },
        }
      }

      if (duration.length > 0) {
        const durations = duration.map((item) => {
          let obj = {
            duration_seconds: {
              _gte: null,
              _lte: null,
            },
          }

          switch (Number(item)) {
            case 0:
              obj.duration_seconds._gte = 0
              obj.duration_seconds._lte = 7200
              break
            case 2:
              obj.duration_seconds._gte = 7201
              obj.duration_seconds._lte = 21600
              break
            case 6:
              obj.duration_seconds._gte = 21601
              obj.duration_seconds._lte = 57600
              break
            case 16:
              obj.duration_seconds._gte = 57601
              obj.duration_seconds._lte = null
              break
            default:
              obj = {...obj}
          }

          return obj
        })

        variables = {...variables, durations}
      }

      setTimeout(() => searchBook({variables}), 1000)
    }
  }, [searchGlobalValue, filterBy, sortBy])

  useEffect(() => {
    if (dataBookTypes && filterBy.type.length === 0) {
      setFilterBy((prevState) => ({
        ...prevState,
        type: [dataBookTypes.academy_book_types[0].id],
      }))
    }

    if (dataBooks) {
      setBooks(dataBooks.academy_books)
    }
  }, [dataBooks, dataBookTypes])

  const handleTypeChange = (id) => {
    setFilterBy((prevState) => ({...prevState, type: [id]}))
  }

  const handleOnChange = (e) => {
    const {name, value} = e.target
    if (name !== 'priceMin' && name !== 'priceMax' && name !== 'ratings') {
      let filters = [...filterBy[name]]
      const removedFilters = filters.filter((item) => item !== value)

      if (filters.includes(value)) {
        filters = removedFilters
      } else {
        filters.push(value)
      }
      setFilterBy((prevState) => ({...prevState, [name]: filters}))

      if (name === 'price' && filters.length === 0) {
        setFilterBy((prevState) => ({...prevState, priceMin: '', priceMax: ''}))
      }
    } else {
      setFilterBy((prevState) => ({...prevState, [name]: value}))
    }
  }

  const handleSortChange = (e) => {
    const {value} = e.target
    setSortBy(value)
  }

  return (
    <div className="w-full">
      <div className="flex flex-1 flex-row justify-start">
        <div className="w-1/4 pl-8 pr-4 sm:pl-32 sm:pl:18 py-24">
          <SearchFilter
            ratings={ratingOptions}
            prices={priceOptions}
            durations={durationOptions}
            isOpenRangePrice={isOpenRangePrice}
            onChange={handleOnChange}
            value={{ratings: filterBy.ratings}}
          />
        </div>
        <div className="w-3/4 mx-auto px-8 sm:px-16 py-24">
          <div className="px-8 mb-24">
            {dataBookTypes &&
              dataBookTypes.academy_book_types.map((type) => {
                return (
                  <Button
                    key={type.id}
                    variant="contained"
                    color={filterBy.type[0] === type.id ? 'primary' : ''}
                    className="capitalize mr-16"
                    onClick={() => handleTypeChange(type.id)}
                  >
                    {type.name}
                  </Button>
                )
              })}
          </div>
          <div className="max-w-2xl w-full px-8 sm:px-16 py-8">
            <div className="flex flex-row justify-between items-center">
              <Typography variant="body1">
                {books.length} Result for &quot;{searchGlobalValue}&quot;
              </Typography>
              <OrderList value={sortBy} onChange={handleSortChange} />
            </div>
          </div>
          <div
            enter={{
              animation: 'transition.slideUpBigIn',
            }}
            className="flex flex-wrap py-20"
          >
            {isLoadingBook ? (
              <div className="w-full text-center">
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              books.map((book) => {
                return <BookList key={book.id} data={book} {...props} />
              })
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({globalSearch}) {
  return {
    searchGlobalValue: globalSearch.courses.searchGlobalValue,
  }
}

export default withReducer(
  'globalSearch',
  reducer
)(
  withStyles(styles, {withTheme: true})(
    connect(mapStateToProps, null)(BookSearch)
  )
)
