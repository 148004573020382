import React, {Component} from 'react'
import {
  AppBar,
  Hidden,
  MuiThemeProvider,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core'
import {FuseShortcutsTimeline} from '@fuse'
import connect from 'react-redux/es/connect/connect'
import {withRouter, NavLink} from 'react-router-dom'
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton'
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu'
import ToolbarMenuSet from 'app/fuse-layouts/shared-components/ToolbarMenuSet'
import {reactLocalStorage} from 'reactjs-localstorage'
import withReducer from 'app/store/withReducer'
import * as Actions from 'app/main/learnings/instructor/store/actions'
import {bindActionCreators} from 'redux'
import reducer from 'app/main/learnings/instructor/store/reducers'
import {status_process} from 'app/constants'
import global from 'app/global-variable'

import Logo from '../../../../assets/logo-smartasn-horizontal.png'

// const styles = theme => ({
//   separator: {
//     width: 1,
//     height: 64,
//     backgroundColor: theme.palette.divider,
//   },
// })

class ToolbarLayout1 extends Component {
  componentDidMount() {
    const slug = reactLocalStorage.getObject('slugPractice')

    if (this.props.location.pathname.includes('manage-event')) {
      let eventSlug = this.props.location.pathname.split('/')[3]
      return this.props.getEventBySlug(eventSlug)
    }

    if (this.props.location.pathname.includes('manage_book')) {
      let bookByISBN = this.props.location.pathname.split('/').pop()
      return this.props.getBookByISBN(bookByISBN)
    }

    this.props.getCourseBySlug(slug)
  }

  saveManagePractice = () => {
    this.props.saveManagePractice(this.props.dataPractice)
    // console.log("dataPractice", this.props.dataPractice)
  }

  render() {
    const {
      classes,
      toolbarTheme,
      settings,
      courseBySlug,
      eventBySlug,
      bookByISBN,
      dataEvent,
      dataBook,
    } = this.props
    const layoutConfig = settings.layout.config
    const {pathname} = this.props.location
    const slug =
      pathname.includes('manage-event') || pathname.includes('manage_book')
        ? pathname.split('/')[3]
        : reactLocalStorage.getObject('slugPractice')
    return (
      <div className="w-full flex flex-row shadow-md">
        {pathname.includes('practice-') ||
        pathname.includes('-practice') ||
        pathname.includes('manage-event') ||
        pathname.includes('manage_book') ||
        pathname.includes('control-event') ||
        pathname.includes('control-book') ||
        pathname.includes(`course-`) ? (
          <div
            // className="w-full md:px-64 px-16 py-16 flex flex-col sm:flex-row items-center"
            className="w-full md:px-64 px-16 py-16 flex flex-row items-center"
            style={{backgroundColor: '#fff'}}
          >
            <div className="w-1/4 hidden md:inline">
              <a href={global.new_learning_url}>
                <img
                  src={global.company_logo || Logo}
                  alt="wlb-logo"
                  className="h-40"
                />
              </a>
            </div>

            {courseBySlug &&
            !pathname.includes('manage-event') &&
            !pathname.includes('manage_book') &&
            !pathname.includes('control-event') &&
            !pathname.includes('control-book') ? (
              <div className="w-full justify-center items-center flex md:flex-row">
                <Typography variant="h6" className="pr-8">
                  {courseBySlug.title}
                </Typography>
                <Typography variant="subtitle1" className="uppercase">
                  {status_process[courseBySlug.status]}
                </Typography>
              </div>
            ) : eventBySlug ? (
              <div className="w-full justify-center items-center flex md:flex-row">
                <Typography variant="h6" className="pr-8">
                  {eventBySlug.title}
                </Typography>
                <Typography variant="subtitle1" className="uppercase">
                  {status_process[eventBySlug.status.toLowerCase()]}
                </Typography>
              </div>
            ) : bookByISBN ? (
              <div className="w-full justify-center items-center flex md:flex-row">
                <Typography variant="h6" className="pr-8">
                  {bookByISBN.title}
                </Typography>
                <Typography variant="subtitle1" className="uppercase">
                  {status_process[bookByISBN.status]}
                </Typography>
              </div>
            ) : (
              true
            )}

            <div className="w-1/4 text-right">
              {pathname.includes('manage-practice') ||
              pathname.includes('control-event') ||
              pathname.includes('control-book') ||
              pathname.includes('practice-landing-page') ? (
                <div>
                  <NavLink
                    to={
                      pathname.includes('control-book')
                        ? `/instructor/manage_book/${
                            this.props.location.pathname.split('/')[3]
                          }`
                        : !pathname.includes('control-event')
                        ? `/instructor/practice-dashboard/${slug}`
                        : `/instructor/manage-event/${
                            this.props.location.pathname.split('/')[3]
                          }/dashboard`
                    }
                    style={{color: '#cb2519'}}
                    className="text-right"
                  >
                    Batalkan
                  </NavLink>
                  <NavLink
                    to="#"
                    style={{color: '#cb2519'}}
                    className="text-right"
                  >
                    <Button
                      variant="contained"
                      className="capitalize md:px-32 px-8 ml-16 text-white"
                      style={{background: '#039be5'}}
                      onClick={() => {
                        if (pathname.includes('control-book')) {
                          return this.props.saveManageBook(dataBook)
                        } else if (pathname.includes('control-event')) {
                          return this.props.saveManageEvent(dataEvent)
                        } else {
                          return this.saveManagePractice
                        }

                        /* if (!pathname.includes('control-event')) {
                          return this.saveManagePractice
                        } else {
                          return this.props.saveManageEvent(dataEvent)
                        } */
                      }}
                    >
                      Simpan
                    </Button>
                  </NavLink>
                </div>
              ) : !pathname.includes('practice-landing-page') &&
                !pathname.includes('practice-price-coupons') &&
                !pathname.includes('practice-questions') ? (
                <NavLink
                  to={
                    pathname.includes('manage-practice') ||
                    pathname.includes('practice-dashboard')
                      ? `/instructor/practice-landing-page/${slug}`
                      : pathname.includes('manage-event')
                      ? `/instructor/control-event/${slug}/landing`
                      : pathname
                          .toLowerCase()
                          .includes('instructor/manage_book')
                      ? `/instructor/control-book/${slug}/landing`
                      : `/instructor/manage_course/${slug}`
                  }
                  style={{color: '#cb2519'}}
                  className="text-right"
                >
                  <Button
                    variant="contained"
                    className="capitalize md:px-32 px-16 text-white"
                    style={{background: '#039be5'}}
                  >
                    Kelola{' '}
                  </Button>
                </NavLink>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <MuiThemeProvider theme={toolbarTheme}>
            <AppBar
              id="fuse-toolbar"
              className="flex relative z-30"
              style={{backgroundColor: '#ffffff'}}
            >
              <Toolbar className="p-0">
                {layoutConfig.navbar.display &&
                  layoutConfig.navbar.position === 'left' && (
                    <Hidden lgUp>
                      <NavbarMobileToggleButton className="w-64 h-64 p-0" />
                      <div className={classes.separator} />
                    </Hidden>
                  )}

                <div className="flex flex-1">
                  {/* <Hidden mdDown> */}
                  <FuseShortcutsTimeline className="px-16 py-12 ml-40" />

                  {/* </Hidden> */}
                </div>

                <div className="flex flex-1 flex flex-1 justify-end content-end items-center">
                  <ToolbarMenuSet />

                  <UserMenu />

                  {/*<Hidden lgUp>*/}

                  {/*<div className={classes.separator}/>*/}

                  {/*<ChatPanelToggleButton/>*/}
                  {/*</Hidden>*/}

                  {/*<div className={classes.separator}/>*/}

                  {/*<QuickPanelToggleButton/>*/}
                </div>

                {layoutConfig.navbar.display &&
                  layoutConfig.navbar.position === 'right' && (
                    <Hidden lgUp>
                      <NavbarMobileToggleButton />
                    </Hidden>
                  )}
              </Toolbar>
            </AppBar>
          </MuiThemeProvider>
        )}
      </div>
    )
  }
}

// const ToolbarLayout1 = ({classes, settings, toolbarTheme, props}) => {}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCourseBySlug: Actions.getCourseBySlug,
      getEventBySlug: Actions.getEventBySlug,
      getBookByISBN: Actions.getBookByISBN,
      saveManagePractice: Actions.saveManagePractice,
      saveManageEvent: Actions.saveManageEvent,
      saveManageBook: Actions.saveManageBook,
    },
    dispatch
  )
}

function mapStateToProps({fuse, analyticsDashboardApp}) {
  return {
    settings: fuse.settings.current,
    toolbarTheme: fuse.settings.toolbarTheme,
    bookByISBN: analyticsDashboardApp.books.book,
    courseBySlug: analyticsDashboardApp.course.courseBySlug,
    eventBySlug:
      analyticsDashboardApp.widgets && analyticsDashboardApp.widgets.event,
    dataPractice: analyticsDashboardApp.course.dataPractice,
    dataEvent: analyticsDashboardApp.course.dataEvent,
    dataBook: analyticsDashboardApp.course.dataBook,
  }
}

export default withReducer(
  'analyticsDashboardApp',
  reducer
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolbarLayout1)))
