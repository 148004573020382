import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import {MuiPickersUtilsProvider, DatePicker} from 'material-ui-pickers'
import React from 'react'

export default function CreateCoupon({handleClose, open}) {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <div className="w-full flex justify-between items-center p-24">
        <Typography variant="body1" color="secondary">
          Create New Coupon
        </Typography>
        <IconButton onClick={handleClose} className="p-6">
          <Icon>close</Icon>
        </IconButton>
      </div>
      <Divider className="mb-24" />
      <DialogContent>
        <Typography gutterBottom>Select Coupon Type</Typography>
        <div className="flex items-center -ml-12">
          <Radio checked={true} />
          <Typography>Free</Typography>
        </div>
        <div className="flex items-center -ml-12 mb-12">
          <Radio checked={true} />
          <Typography>Discounted Price</Typography>
        </div>
        <div className="mb-20">
          <Typography variant="body2">Amount of discount</Typography>
          <div className="flex items-center">
            <TextField
              variant="outlined"
              margin="normal"
              placeholder="0"
              className="mr-12"
              type="number"
            />
            %
          </div>
        </div>
        <div className="mb-20">
          <Typography variant="body2">Coupon Code</Typography>

          <TextField
            variant="outlined"
            margin="normal"
            placeholder="Add coupon code"
            fullWidth
          />
        </div>
        <Typography
          variant="body2"
          className="mb-20"
          style={{color: '#A9A8A8'}}
        >
          Your coupon code must be between 6 and 20 characters. It can only
          contain alphanumeric characters (A-Z, 0-9), periods ("."), dashes
          ("-") or underscores ("_"). All letters must be CAPITAL.
        </Typography>
        <div className="mb-20">
          <Typography variant="body2">Number of Coupon</Typography>

          <TextField
            variant="outlined"
            margin="normal"
            placeholder="Add number of coupon"
            fullWidth
            type="number"
          />
        </div>
        <div className="mb-20">
          <Typography variant="body2">Expired Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="outlined"
              format="MM/dd/yyyy"
              //   value={values.start_date}
              //   onChange={date => handleChangeDate(date, 'start_date')}
              margin="normal"
              //   className={classes.w50}
              placeholder="Select start date"
              //   {...(!values.start_date && isError ? {error: true} : {})}
              //   {...(!values.start_date && isError
              //     ? {helperText: 'This field is required'}
              //     : {})}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon style={{color: '#A9A8A8'}}>today</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </DialogContent>
      <DialogActions className="m-24">
        <Button
          className="normal-case mr-12"
          onClick={handleClose}
          color="secondary"
          size="large"
        >
          Cancel
        </Button>
        <Button
          className="normal-case"
          onClick={handleClose}
          color="secondary"
          variant="contained"
          size="large"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
