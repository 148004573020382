import gql from 'graphql-tag'

export const COURSE_RANK = gql`
  query {
    ranking_course: global_users(
      where: {academy_course_enrollments: {id: {_is_null: false}}}
      order_by: {
        academy_course_enrollments_aggregate: {
          sum: {completion_rate: desc_nulls_last}
        }
      }
    ) {
      id
      name
      email
      avatar
      academy_course_enrollments_aggregate {
        aggregate {
          sum {
            completion_rate
          }
        }
      }
    }
  }
`

export const PRACTICE_TEST_RANK = gql`
  query {
    ranking_practice: global_users(
      where: {
        academy_lesson_grades: {
          academy_course_lesson: {lesson_type: {_eq: "exercise"}}
          grade: {_is_null: false}
        }
      }
      order_by: {
        academy_lesson_grades_aggregate: {sum: {grade: desc_nulls_last}}
      }
    ) {
      id
      name
      email
      avatar
      academy_lesson_grades_aggregate(
        where: {academy_course_lesson: {lesson_type: {_eq: "exercise"}}}
      ) {
        aggregate {
          sum {
            grade
          }
        }
      }
    }
  }
`
