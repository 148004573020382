import {ApolloClient} from 'apollo-boost'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {HttpLink} from 'apollo-link-http'
import {setContext} from 'apollo-link-context'

// import {GRAPHQL_URL, GRAPHQL_WS_URL, TOKEN, ROLE} from '../global-variable'
import global from '../global-variable'

const token = global.access_token
const cache = new InMemoryCache()
const httpLink = new HttpLink({
  uri: global.graphqlUrl,
  credentials:'include',
})

// eslint-disable-next-line no-unused-vars
const authLink = setContext((_, {headers}) => {
  return {
    headers: {
      // 'X-Hasura-Role': global.roles,
      'X-Hasura-Role': 'user',
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

export default client
