import {ForgotPasswordPageConfig} from './auth/forgot-password/ForgotPasswordPageConfig'
import {RegisterPageConfig} from './auth/register/RegisterPageConfig'
import {ResetPasswordPageConfig} from './auth/reset-password/ResetPasswordPageConfig'
import {LoginPageConfig} from './auth/login/LoginPageConfig'
import {MailConfirmPageConfig} from './auth/mail-confirm/MailConfirmPageConfig'
import {LockPageConfig} from './auth/lock/LockPageConfig'
import {Login2PageConfig} from './auth/login-2/Login2PageConfig'
import {Register2PageConfig} from './auth/register-2/Register2PageConfig'
import {ForgotPassword2PageConfig} from './auth/forgot-password-2/ForgotPassword2PageConfig'
import {ResetPassword2PageConfig} from './auth/reset-password-2/ResetPassword2PageConfig'
import {ComingSoonPageConfig} from './coming-soon/ComingSoonPageConfig'
import {Error404PageConfig} from './errors/404/Error404PageConfig'
import {Error500PageConfig} from './errors/500/Error500PageConfig'
import {MaintenancePageConfig} from './maintenance/MaintenancePageConfig'
import {ModernInvoicePageConfig} from './invoices/modern/ModernInvoicePageConfig'
import {CompactInvoicePageConfig} from './invoices/compact/CompactInvoicePageConfig'
import {PricingStyle1PageConfig} from './pricing/style-1/PricingStyle1PageConfig'
import {PricingStyle2PageConfig} from './pricing/style-2/PricingStyle2PageConfig'
import {PricingStyle3PageConfig} from './pricing/style-3/PricingStyle3PageConfig'
import {ProfilePageConfig} from './profile/ProfilePageConfig'
import {ClassicSearchPageConfig} from './search/classic/ClassicSearchPageConfig'
import {ModernSearchPageConfig} from './search/modern/ModernSearchPageConfig'
import {FaqPageConfig} from './faq/FaqPageConfig'
import {KnowledgeBasePageConfig} from './knowledge-base/KnowledgeBaseConfig'

export const pagesConfigs = [
  LoginPageConfig,
  RegisterPageConfig,
  ResetPasswordPageConfig,
  ForgotPasswordPageConfig,
  MailConfirmPageConfig,
  LockPageConfig,
  Login2PageConfig,
  Register2PageConfig,
  ForgotPassword2PageConfig,
  ResetPassword2PageConfig,
  ComingSoonPageConfig,
  Error404PageConfig,
  Error500PageConfig,
  MaintenancePageConfig,
  ModernInvoicePageConfig,
  CompactInvoicePageConfig,
  PricingStyle1PageConfig,
  PricingStyle2PageConfig,
  PricingStyle3PageConfig,
  ProfilePageConfig,
  ClassicSearchPageConfig,
  ModernSearchPageConfig,
  FaqPageConfig,
  KnowledgeBasePageConfig,
]
