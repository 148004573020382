import {FuseLoadable} from '@fuse'
import {authRoles} from 'app/auth'
import Register from 'app/main/register/Register'

export const SharePageConfig = {
  settings: {
    layout: {
      style: 'layout5',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
          folded: false,
          position: 'left',
        },
        toolbar: {
          display: true,
          style: 'fixed',
          position: 'below',
        },
        footer: {
          display: false,
          style: 'static',
          position: 'below',
        },
        mode: 'fullwidth',
      },
    },
  },

  routes: [
    {
      path: '/share_course/:slug',
      component: FuseLoadable({
        loader: () => import('./ShareCourse'),
      }),

      // component: Register,
      /* component: FuseLoadable({
        loader: () => import('./ShareCourse'),
      }), */
    },
    {
      path: '/sww',
      component: FuseLoadable({
        loader: () => import('./SWW'),
      }),
      exact: true,
    },
  ],
}
