import gql from 'graphql-tag'

export const GET_LIST_LEARNING_CONTRIBUTOR_LABEL_DASHBOARD = gql`
  query {
    learning_contributor_labels {
      label
    }
  }
`

export const GET_DATA_CONTRIBUTOR = gql`
  query($userId: uuid!) {
    global_users_by_pk(id: $userId) {
      id
      name
      contributor_label
      avatar
      people_work_placements(where: {deletedAt: {_is_null: true}}) {
        id
        company_job_profile {
          id
          position
          title
        }
        company_profile {
          id
          brand_name
        }
      }
    }
  }
`
