import * as Actions from '../actions'

const initialState = {
  categories: null,
}

const categoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default categoryReducer
