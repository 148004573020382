import React from 'react'
import {AppBar, MuiThemeProvider, withStyles} from '@material-ui/core'
import connect from 'react-redux/es/connect/connect'
import {withRouter} from 'react-router-dom'

const styles = (theme) => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
})

const SubToolbarLayout1 = ({settings, toolbarTheme}) => {
  //   const layoutConfig = settings.layout.config

  return (
    <MuiThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className="flex items-center justify-center relative z-0"
        style={{backgroundColor: '#f6f8f9'}}
      >
        {/* <Tabs
                    value={0}
                    indicatorColor="secondary"
                    textColor="secondary"
                    className="justify-center items-center content-center flex flex-row z-10">
                    <Tab label="All messages" style={{color:"#0d0d0d"}}/>
                    <Tab label="Unread" style={{color:"#a9a8a8"}}/>
                    
                </Tabs> */}
      </AppBar>
    </MuiThemeProvider>
  )
}

function mapStateToProps({fuse}) {
  return {
    settings: fuse.settings.current,
    toolbarTheme: fuse.settings.toolbarTheme,
  }
}

export default withStyles(styles, {withTheme: true})(
  withRouter(connect(mapStateToProps)(SubToolbarLayout1))
)
