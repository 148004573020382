import gql from 'graphql-tag'

export const GET_MODULAR_SETTINGS = gql`
  query($company: uuid) {
    company_module_settings(where: {company: {_eq: $company}}) {
      global_module {
        name
        routes
      }
    }
  }
`
