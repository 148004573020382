import React from 'react'

import {TextField} from '@material-ui/core'
import {XSSChecker} from 'app/utils/helpers'

const ValidatedTextField = props => {
  const handleChange = e => {
    e.target.value = XSSChecker(e.target.value)
    props.onChange(e)
  }
  return <TextField {...props} onChange={handleChange} />
}

export default ValidatedTextField
