import gql from 'graphql-tag'

export const GET_MY_LEARNING_MICROLEARNING = gql`
  query(
    $userId: uuid!
    $orderBy: [microlearnings_order_by!]
    $offset: Int
    $limit: Int
    $categoryIds: [Int!]
    $levels: [Int!]
    $languageIds: [Int!]
    $isFinished: Boolean
    $keyword: String
  ) {
    finished_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: true}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    on_progress_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: false}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings_aggregate(
      where: {
        language_id: {_in: $languageIds}
        level: {_in: $levels}
        category_id: {_in: $categoryIds}
        microlearning_members: {
          user_id: {_eq: $userId}
          is_valid: {_eq: true}
          is_finished: {_eq: $isFinished}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings(
      where: {
        name: {_ilike: $keyword}
        language_id: {_in: $languageIds}
        level: {_in: $levels}
        category_id: {_in: $categoryIds}
        microlearning_members: {
          user_id: {_eq: $userId}
          is_valid: {_eq: true}
          is_finished: {_eq: $isFinished}
        }
      }
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
      name
      icon_url
      question_count
      my_review: microlearning_reviews(
        where: {member_user_id: {_eq: $userId}}
      ) {
        id
        star
      }
      global_user {
        id
        name
        avatar
      }
      microlearning_members(
        where: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
      ) {
        id
        progress
        score
        schedule
        schedule_time
        daily_question
      }
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          count
          avg {
            star
          }
        }
      }
      academy_course_category {
        id
        name
        parent: academy_course_category {
          id
          name
        }
      }
      global_language {
        id
        name
        language_code
      }
      date_added
      status
      level
      topic
      type
      company_profile {
        id
        brand_name
        legal_name
      }
    }
  }
`
