import * as Actions from '../actions'

const initialState = {
  courseBySlugAcademy: null,
  instructor: null,
  instructorCourse: null,
  courseProgress: null,
  courseComments: null,
  courseReview: null,
  createCourseComment: null,
  createCourseCommentResponse: null,
  createHelpfulResponse: null,
  createUnhelpfulResponse: null,
  courseUpdate: null,
  data: null,
  mediaObject: null,
  priceTiers: null,
  instructors: [],
  answerStatus: null,
}

const courseReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COURSE: {
      return {
        ...action.payload,
      }
    }
    case Actions.GET_INSTRUCTORS: {
      return {
        ...state,
        instructors: action.payload,
      }
    }
    case Actions.GET_WIDGETS:
      return {
        ...state,
        data: {...action.payload},
      }
    case Actions.GET_COURSE_BY_SLUG: {
      return {
        ...action.payload,
        courseBySlug: action.payload,
      }
    }
    case Actions.GET_COURSE_INSTRUCTOR: {
      return {
        ...action.payload,
        instructor: action.payload,
      }
    }
    case Actions.GET_INSTRUCTOR_COUSE: {
      return {
        ...action.payload,
        instructorCourse: action.payload,
      }
    }
    case Actions.GET_COURSE_REVIEW: {
      return {
        ...state,
        courseReview: action.payload,
      }
    }
    case Actions.GET_COMMENTS: {
      return {
        ...state,
        courseComments: action.payload,
      }
    }
    case Actions.GET_PRICE_TIERS: {
      return {
        ...state,
        priceTiers: action.payload,
      }
    }
    case Actions.GET_MEDIA_OBJECT: {
      return {
        ...state,
        mediaObject: action.payload,
      }
    }

    case Actions.UPDATE_COURSE: {
      return {
        ...action.payload,
        courseUpdated: action.payload,
      }
    }
    case Actions.UPDATE_COURSE_PROGRESS: {
      return {
        ...state,
        ...action.payload,
        courseProgress: action.payload,
      }
    }
    case Actions.SAVE_DISCUSSION: {
      return {
        ...action.payload,
        createCourseComment: action.payload,
      }
    }
    case Actions.SAVE_DISCUSSION_RESPONSE: {
      return {
        ...action.payload,
        createCourseCommentResponse: action.payload,
      }
    }
    case Actions.SAVE_HELPFUL_RESPONSE: {
      return {
        ...action.payload,
        createHelpfulResponse: action.payload,
      }
    }
    case Actions.SAVE_UNHELPFUL_RESPONSE: {
      return {
        ...action.payload,
        createUnhelpfulResponse: action.payload,
      }
    }
    case Actions.SAVE_COURSE: {
      return {
        ...action.payload,
      }
    }
    case Actions.SAVE_GRADING_MANUAL: {
      return {
        ...action.payload,
      }
    }
    case Actions.SAVE_USER_ANSWER: {
      return {
        ...state,
        answerStatus: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default courseReducer
