import axios from 'axios'
import global from 'app/global-variable'

export const GET_PRACTICES = '[ACADEMY APP] GET PRACTICES'

export function getPractices() {
  const userData = global.data_user
  const request = axios.get(`${global.api.dev}/academy/course/get?type[0]=2`, {
    headers: {
      Authorization: 'Bearer ' + userData.oauth.access_token,
    },
    withCredentials:true,
  })

  return (dispatch) =>
    request.then((response) => {
      console.log('GET_PRACTICES', response.data)
      return dispatch({
        type: GET_PRACTICES,
        payload: response.data,
      })
    })
}
