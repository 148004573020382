// import '@fake-db'
import {FuseAuthorization, FuseLayout, FuseTheme} from '@fuse'
import {createGenerateClassName, jssPreset} from '@material-ui/core'
import {create} from 'jss'
import jssExtend from 'jss-extend'
import React, {useEffect} from 'react'
import JssProvider from 'react-jss/lib/JssProvider'
import Provider from 'react-redux/es/components/Provider'
import {Router} from 'react-router-dom'
import history from '../history'
import AppContext from './AppContext'
import {Auth} from './auth'
import routes from './fuse-configs/routesConfig'
import store from './store'
import {hasModule, listenCookieChange} from './utils/helpers'
import {useQuery} from '@apollo/react-hooks'
import {GET_MODULAR_SETTINGS} from './graphql/queries/global'
import global from './global-variable'

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
})

jss.options.insertionPoint = document.getElementById('jss-insertion-point')
const generateClassName = createGenerateClassName()

const AppContainer = () => {
  const {data} = useQuery(GET_MODULAR_SETTINGS, {
    variables: {
      company: global.company_id,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (data) {
    if (!hasModule(data, 'learning')) {
      window.location = global.sosmed_url
    }
  }

  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      listenCookieChange()
    }
  }, [listenCookieChange])

  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <Auth>
            <Router history={history}>
              <FuseAuthorization>
                <FuseTheme>
                  <FuseLayout />
                </FuseTheme>
              </FuseAuthorization>
            </Router>
          </Auth>
        </Provider>
      </JssProvider>
    </AppContext.Provider>
  )
}

export default AppContainer
