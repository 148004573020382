import React from 'react' //.
import {Link} from 'react-router-dom'
import {withStyles, Typography, Card, Avatar} from '@material-ui/core'
import './suggestions.css'
import history from 'history.js'
import * as Actions from 'app/main/learnings/store/actions'
import reducer from 'app/main/learnings/store/reducers'
import withReducer from 'app/store/withReducer'
import {bindActionCreators} from 'redux'
import connect from 'react-redux/es/connect/connect'

class Suggestions extends React.Component {
  state = {
    close: false,
    dataInstructorCourses: [],
  }

  componentWillMount() {
    this.props.getMyCourses()
  }

  goToTabs = () => {
    // const userData = reactLocalStorage.getObject('userData')
    // const dataInstructors = this.props.instructors
    // let dataInstructorCourses = []
    // for (let i = 0; i < dataInstructors.length; i++) {
    //   const id = dataInstructors[i]
    //   axios
    //     .get(global.api.dev + `/academy/instructor/${id}/course?limit=4`, {
    //       headers: {
    //         Authorization: 'Bearer ' + userData.oauth.access_token,
    //       },
    //     })
    //     .then(response => {
    //       if (response.status == 200) {
    //         dataInstructorCourses.push(response.data.data)
    //       }
    //     })
    //     .catch(error => {
    //       console.error(error)
    //     })
    // }
    /* this.setState({
      close: true,
      // dataInstructorCourses: dataInstructorCourses,
    }) */
    // history.push('/tabs-all') //SEE ALL
  }

  goToCourse(slug) {
    history.push(slug)
    //console.log(slug)
  }

  render() {
    const {courses, keyword, myCourses} = this.props
    return (
      <div
        style={
          this.state.close === false ? {display: 'block'} : {display: 'none'}
        }
      >
        <Card
          className="flex flex-col ml-80 p-16"
          style={{position: 'absolute', width: '450px', overflowY: 'auto'}}
        >
          <div className="flex pb-16">
            <div className="flex items-center pt-12 pr-8 border-r border-gray-400">
              <Typography variant="subtitle2" className="deep-teal">
                Instructor
              </Typography>
            </div>
            <div className="flex-1">
              {courses.length === 0 ? (
                <div className="not-found-list pl-16">
                  <Typography variant="subtitle2">No courses found!</Typography>
                </div>
              ) : (
                courses.map((course, key) => {
                  const myCourse = myCourses.filter((item) => {
                    let Course = {}
                    if (item.Course) {
                      Course = item.Course
                    } else {
                      Course = item.AcademyCourse
                    }
                    return Course.id === course.id
                  })
                  if (myCourse.length > 0) {
                    const slug = `/learning_my_course/${course.slug}`
                    return (
                      <div
                        key={key}
                        className="suggest-list flex"
                        onClick={this.goToCourse.bind(this, slug)}
                      >
                        <div className="flex-initial px-12">
                          <Avatar
                            alt="1"
                            src={course.CreatorUser.avatar}
                            className="rounded"
                          />
                        </div>
                        <div className="flex-initial">
                          <Typography variant="subtitle2" className="font-bold">
                            {course.CreatorUser.name}
                          </Typography>
                          <Typography
                            inline
                            variant="subtitle2"
                            className="med-gray"
                          >
                            {course.title}
                          </Typography>
                        </div>
                      </div>
                    )
                  } else {
                    const slug = `/learning_course/${course.slug}`
                    return (
                      <div
                        key={key}
                        className="suggest-list flex"
                        onClick={this.goToCourse.bind(this, slug)}
                      >
                        <div className="flex-initial px-12">
                          <Avatar
                            alt="1"
                            src={course.CreatorUser.avatar}
                            className="rounded"
                          />
                        </div>
                        <div className="flex-initial">
                          <Typography variant="subtitle2" className="font-bold">
                            {course.CreatorUser.name}
                          </Typography>
                          <Typography
                            inline
                            variant="subtitle2"
                            className="med-gray"
                          >
                            {course.title}
                          </Typography>
                        </div>
                      </div>
                    )
                  }
                })
              )}
            </div>
            <div className="flex-initial pt-12">
              <Typography variant="subtitle2" className="deep-teal">
                {`${courses.length} People Results`}
              </Typography>
            </div>
          </div>
          <div className="flex mb-20">
            <div className="flex items-center pt-12 pr-20 border-r border-gray-400">
              <Typography variant="subtitle2" className="deep-teal">
                Courses
              </Typography>
            </div>
            <div className="flex-1">
              {courses.length === 0 ? (
                <div className="not-found-list pl-16">
                  <Typography variant="subtitle2">No courses found!</Typography>
                </div>
              ) : (
                courses.map((course, key) => {
                  const myCourse = myCourses.filter((item) => {
                    let Course = {}
                    if (item.Course) {
                      Course = item.Course
                    } else {
                      Course = item.AcademyCourse
                    }
                    return Course.id === course.id
                  })
                  if (myCourse.length > 0) {
                    const slug = `/learning_my_course/${course.slug}`
                    return (
                      <div
                        key={key}
                        className="suggest-list flex"
                        onClick={this.goToCourse.bind(this, slug)}
                      >
                        <div className="flex-initial px-12">
                          <Avatar
                            alt="1"
                            src={course.thumbnail}
                            className="rounded"
                          />
                        </div>
                        <div className="flex-initial">
                          <Typography variant="subtitle2" className="font-bold">
                            {course.title}
                          </Typography>
                          <Typography
                            inline
                            variant="subtitle2"
                            className="med-gray"
                          >
                            By{' '}
                          </Typography>
                          <Typography
                            inline
                            variant="subtitle2"
                            className="med-gray"
                          >
                            {course.CreatorUser.name}
                          </Typography>
                        </div>
                      </div>
                    )
                  } else {
                    const slug = `/learning_course/${course.slug}`
                    return (
                      <div
                        key={key}
                        className="suggest-list flex"
                        onClick={this.goToCourse.bind(this, slug)}
                      >
                        <div className="flex-initial px-12">
                          <Avatar
                            alt="1"
                            src={course.thumbnail}
                            className="rounded"
                          />
                        </div>
                        <div className="flex-initial">
                          <Typography variant="subtitle2" className="font-bold">
                            {course.title}
                          </Typography>
                          <Typography
                            inline
                            variant="subtitle2"
                            className="med-gray"
                          >
                            By{' '}
                          </Typography>
                          <Typography
                            inline
                            variant="subtitle2"
                            className="med-gray"
                          >
                            {course.CreatorUser.name}
                          </Typography>
                        </div>
                      </div>
                    )
                  }
                })
              )}
            </div>
            <div className="flex-initial pt-12">
              <Typography variant="subtitle2" className="deep-teal">
                {`${courses.length} Course Results`}
              </Typography>
            </div>
          </div>
          <div className="flex pt-20 pb-10 border-t border-gray-400">
            <Link
              className="mx-auto"
              to={{
                pathname: '/tabs-all/courses',
                state: {keyword: keyword},
              }}
              onClick={() => this.setState({close: true})}
            >
              <Typography
                variant="subtitle2"
                className="deep-teal"
                // onClick={this.goToTabs}
              >
                SEE ALL
              </Typography>
            </Link>
          </div>
        </Card>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMyCourses: Actions.getMyCourses,
    },
    dispatch
  )
}

function mapStateToProps({academyApp}) {
  return {
    myCourses: academyApp.courses.myCourses,
  }
}

export default withReducer(
  'academyApp',
  reducer
)(
  withStyles(
    {},
    {withTheme: true}
  )(connect(mapStateToProps, mapDispatchToProps)(Suggestions))
)
