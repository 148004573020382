import axios from 'axios'
import {axiosInstanceBearer} from 'app/services/restApiService'
import {reactLocalStorage} from 'reactjs-localstorage'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable'
import history from 'history.js'
import _ from '@lodash'

export const GET_COURSE_BY_SLUG = '[ACADEMY APP] GET COURSE BY SLUG'
export const GET_COURSES = '[ACADEMY APP] GET COURSES'
export const GET_MY_COURSES = '[ACADEMY APP] GET MY COURSES'
export const GET_STUDENT = '[ACADEMY APP] GET STUDENT PROFILE'
export const GET_PROMO = '[ACADEMY APP] GET PROMO COURSES'
export const GET_RECOMMENDATION = '[ACADEMY APP] GET RECOMMENDATION'
export const GET_TRENDING = '[ACADEMY APP] GET TRENDING'
export const GET_CART = '[ACADEMY APP] GET CART'
export const GET_WISHLIST = '[ACADEMY APP] GET WISHLIST'
export const GET_CATEGORIES = '[ACADEMY APP] GET CATEGORIES'
export const SET_COURSES_SEARCH_TEXT = '[ACADEMY APP] SET COURSES SEARCH TEXT'
export const SET_COURSES_CATEGORY_FILTER =
  '[ACADEMY APP] SET COURSES CATEGORY FILTER'
export const ADD_TO_CART = '[ACADEMY APP] ADD TO CART'
export const ADD_TO_WISHLIST = '[ACADEMY APP] ADD TO WISHLIST'
export const REMOVE_FROM_WISHLIST = '[ACADEMY APP] REMOVE FROM WISHLIST'
export const CHECKOUT = '[ACADEMY APP] CHECKOUT'
export const IS_SHOWED = '[ACADEMY APP] SHOW ALL COURSES CATALOG'
export const GET_EVENT_CATEGORIES =
  '[INSTRUCTOR DASHBOARD] GET EVENT CATEGORIES'
export const GET_EVENT_TYPES = '[INSTRUCTOR DASHBOARD] GET EVENT TYPES'
export const GET_COURSE_CATEGORIES =
  '[INSTRUCTOR DASHBOARD] GET COURSE CATEGORIES'
export const GET_EVENT = '[INSTRUCTOR DASHBOARD] GET EVENT'
export const GET_VIDEO = '[INSTRUCTOR DASHBOARD] GET VIDEO'
export const GET_BOOKS = '[INSTRUCTOR DASHBOARD] GET BOOKS'
export const GET_PRACTICE_TESTS = '[INSTRUCTOR DASHBOARD] GET PRACTICE TESTS'
export const GET_INSTRUCTOR_COURSE =
  '[INSTRUCTOR DASHBOARD] GET INSTRUCTOR COURSE'
export const GET_BOOK_TYPES = '[INSTRUCTOR DASHBOARD] GET BOOK TYPES'
export const FILTER_MYCOURSE = '[INSTRUCTOR DASHBOARD] FILTER MY COURSES'
export const FILTER_MYEVENT = '[INSTRUCTOR DASHBOARD] FILTER MY EVENTS'
export const FILTER_MYVIDEOS = '[INSTRUCTOR DASHBOARD] FILTER MY VIDEOS'
export const FILTER_MYBOOK = '[INSTRUCTOR DASHBOARD] FILTER MY BOOKS'
export const FILTER_MYPRACTICE =
  '[INSTRUCTOR DASHBOARD] FILTER MY PRACTICE TEST'

const userData = global.data_user

export function getCourses(categoryId) {
  const userData = global.data_user
  if (_.isEmpty(userData)) {
    // history.push('/')
  } else {
    const request = axiosInstanceBearer.get(
      global.api.dev + '/academy/course/get?category=' + categoryId,
      {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
      }
    )
    return (dispatch) =>
      request.then((response) => {
        return dispatch({
          type: GET_COURSES,
          payload: response.data.data,
        })
      })
  }
}

export function getCategories() {
  const request = axios.get('/api/academy-app/categories',{
    withCredentials: true
  })

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CATEGORIES,
        payload: response.data,
      })
    )
}

export function getCourseRecommended(categoryId) {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/recommendation?category=' + categoryId,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )
  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: GET_RECOMMENDATION,
        payload: response.data.data,
      })
    })
}

//export function getCourseTrending(limit,page){
export function getCourseTrending(categoryId) {
  //const request=axios.get('https://poseidon.worklifebeyond.com/academy/course/get?limit='+limit+"&page="+page,

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/trending?category=' + categoryId,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: GET_TRENDING,
        payload: response.data.data,
      })
    })
}

//export function getCoursePromo(limit,page){
export function getCoursePromo(categoryId) {
  //const request=axios.get('https://poseidon.worklifebeyond.com/academy/course/promo?limit='+limit+"&page="+page,
  const APIURL = categoryId
    ? global.api.dev + '/academy/course/promo?category=' + categoryId
    : global.api.dev + '/academy/course/promo'

  const request = axiosInstanceBearer.get(APIURL, {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  })

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      return dispatch({
        type: GET_PROMO,
        payload: response.data.data,
      })
    })
}

export function showAllCoursesCategory(isShowed) {
  if (isShowed === true) {
    return (dispatch) =>
      dispatch({
        type: IS_SHOWED,
        payload: true,
      })
  } else {
    return (dispatch) =>
      dispatch({
        type: IS_SHOWED,
        payload: false,
      })
  }
}

export function getMyCourses() {
  const userData = global.data_user
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/student/' + userData.id + '/enrollment',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      return dispatch({
        type: GET_MY_COURSES,
        payload: response.data,
      })
    })
}

export function addToCart(courseId) {
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/cart/add',
    {
      courseId: courseId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      if (response.status < 400) {
        let disp = dispatch({
          type: ADD_TO_CART,
        })

        dispatch(getCart())

        //getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function checkout(paymentMethod, currency, latestBalance) {
  const userData = global.data_user
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/cart/checkout',
    {
      paymentMethod: paymentMethod,
      currency: currency,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      if (response.status < 400) {
        let disp = dispatch({
          type: CHECKOUT,
        })
        userData.latestBalance = latestBalance
        reactLocalStorage.setObject('userData', userData)

        dispatch(getCart())
        dispatch(getStudentProfile())

        //getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function addToWishlist(courseId) {
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/wishlist/add',
    {
      courseId: courseId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      if (response.status < 400) {
        let disp = dispatch({
          type: ADD_TO_WISHLIST,
        })

        dispatch(getWishlist())

        //getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function removeFromWishlist(wishlistId) {
  const request = axiosInstanceBearer.delete(
    global.api.dev + '/academy/wishlist/' + wishlistId + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      if (response.status < 400) {
        let disp = dispatch({
          type: REMOVE_FROM_WISHLIST,
        })

        dispatch(getWishlist())
        dispatch(getStudentProfile())

        //getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function getCart() {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/cart/get',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      return dispatch({
        type: GET_CART,
        payload: response.data,
      })
    })
}

export function getStudentProfile() {
  const userData = global.data_user
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/student/' + userData.id,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log('AXIOS', response)
      return dispatch({
        type: GET_STUDENT,
        payload: response.data,
      })
    })
}

export function getWishlist() {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/wishlist/get',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log("AXIOS", response);
      return dispatch({
        type: GET_WISHLIST,
        payload: response.data,
      })
    })
}

export function setCoursesSearchText(event) {
  return {
    type: SET_COURSES_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function setCategoryFilter(event) {
  return {
    type: SET_COURSES_CATEGORY_FILTER,
    category: event.target.value,
  }
}

export function getEventCategories() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/event/categories/get',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_CATEGORIES,
        payload: response.data,
      })
    )
}

export function getEventTypes() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/event/types/get',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_TYPES,
        payload: response.data,
      })
    )
}

export function getCourseCategories() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/category/get',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_COURSE_CATEGORIES,
        payload: response.data,
      })
    )
}

export function getEvents() {
  const userData = global.data_user
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/instructor/' + userData.id + '/event',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT,
        payload: response.data,
      })
    )
}

export function getVideos() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/instructor/' + global.user_id + '/video',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_VIDEO,
        payload: response.data,
      })
    )
}

export function getPracticeTests() {
  //a
  const userData = global.data_user
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/instructor/' + userData.id + '/course?type[0]=2',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: GET_PRACTICE_TESTS,
        payload: response.data,
      })
    })
}

export function getInstructorCourse() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    `${global.api.dev}/academy/instructor/${userData.id}/course`,
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: GET_INSTRUCTOR_COURSE,
        payload: response.data,
      })
    })
}

export function getBooks() {
  const userData = global.data_user
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev +
      '/academy/instructor/' +
      userData.id +
      '/book?orders[0][0]=dateAdded&orders[0][1]=asc',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_BOOKS,
        payload: response.data,
      })
    )
}

export function getBookTypes() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  }

  const bodyParameters = {}

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/bookType/get',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_BOOK_TYPES,
        payload: response.data,
      })
    )
}

export function filterMyCourse(filter) {
  return {
    type: FILTER_MYCOURSE,
    filtered: filter,
  }
}

export function filterMyEvent(filter) {
  return {
    type: FILTER_MYEVENT,
    filtered: filter,
  }
}

export function filterMyVideo(filter) {
  return {
    type: FILTER_MYVIDEOS,
    filtered: filter,
  }
}

export function filterMyBook(filter) {
  return {
    type: FILTER_MYBOOK,
    filtered: filter,
  }
}

export function filterMyPractice(filter) {
  return {
    type: FILTER_MYPRACTICE,
    filtered: filter,
  }
}
