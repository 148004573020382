import React from 'react'
import {Redirect} from 'react-router-dom'
import {FuseUtils} from '@fuse/index'
// import {appsConfigs} from 'app/main/apps/appsConfigs'
import {learningsConfigs} from 'app/main/learnings/learningsConfigs'
import {pagesConfigs} from 'app/main/pages/pagesConfigs'
// import {authRoleExamplesConfigs} from 'app/main/auth/authRoleExamplesConfigs'
import {UserInterfaceConfig} from 'app/main/user-interface/UserInterfaceConfig'
import {ComponentsConfig} from 'app/main/components/ComponentsConfig'
// // import {DocumentationConfig} from 'app/main/documentation/DocumentationConfig'
// import {LoginConfig} from 'app/main/login/LoginConfig'
import {RegisterConfig} from 'app/main/register/RegisterConfig'
import {LogoutConfig} from 'app/main/logout/LogoutConfig'
import {CallbackConfig} from 'app/main/callback/CallbackConfig'
import {SharePageConfig} from 'app/main/learnings/dashboard/SharePageConfig.js'
import global from 'app/global-variable'

const routeConfigs = [
  // ...appsConfigs,
  ...learningsConfigs,
  ...pagesConfigs,
  // ...authRoleExamplesConfigs,
  ComponentsConfig,
  UserInterfaceConfig,
  // DocumentationConfig,
  // LoginConfig,
  SharePageConfig,
  RegisterConfig,
  LogoutConfig,
  CallbackConfig,
]

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => {
      window.location.href = global.new_learning_url
      return null
    },
  },
  {
    component: () => <Redirect to="/pages/errors/error-404" />,
  },
]

export default routes
