import axios from 'axios'
import global from 'app/global-variable'
import _ from '@lodash'
// .
export const GET_CLASSROOM = '[ACADEMY APP] GET CLASSROOM'
export const GET_ALL_CLASSROOM = '[ACADEMY APP] GET ALL CLASSROOM'

export function getClassroom() {
  const userData = global.data_user
  if (_.isEmpty(userData)) {
    // history.push('/learning').
  } else {
    const request = axios.get(
      global.api.sosmed + `/v1/users/${userData.id}/classrooms`,
      {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
        withCredentials:true,
      }
    )
    return (dispatch) =>
      request.then((response) => {
        return dispatch({
          type: GET_CLASSROOM,
          payload: response.data.data,
        })
      })
  }
}

export function getAllClassroom() {
  const userData = global.data_user
  if (_.isEmpty(userData)) {
    // history.push('/learning').
  } else {
    const request = axios.get(
      global.api.sosmed + `/v1/users/${userData.id}/classrooms-global`,
      {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
        withCredentials:true,
      }
    )
    return (dispatch) =>
      request.then((response) => {
        return dispatch({
          type: GET_ALL_CLASSROOM,
          payload: response.data.data,
        })
      })
  }
}
