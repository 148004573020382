import axios from 'axios'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable'
import history from 'history.js'
import _ from '@lodash'

export const GET_UNREAD_NOTIFICATIONS_COUNT =
  '[ACADEMY APP] GET UNREAD NOTIFICATIONS COUNT'
export const GET_4_UNREAD_NOTIFICATIONS =
  '[ACADEMY APP] GET 4 UNREAD NOTIFICATIONS'
export const GET_UNREAD_NOTIFICATIONS = '[ACADEMY APP] GET UNREAD NOTIFICATIONS'
export const READ_ALL_NOTIFICATIONS = '[ACADEMY APP] READ ALL NOTIFICATIONS'

const userData = global.data_user

export function getUnreadNotificationsCount() {
  if (_.isEmpty(userData)) {
    // history.push('/learning')
  } else {
    const request = axios.get(
      global.api.dev + '/global/notification/get/count',
      {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
        withCredentials:true,
      }
    )

    return (dispatch) =>
      request.then((response) => {
        return dispatch({
          type: GET_UNREAD_NOTIFICATIONS_COUNT,
          payload: response.data,
        })
      })
  }
}

export function getUnreadNotifications(page, limit) {
  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  let request
  if (limit === -1) {
    request = axios.get(
      global.api.dev + '/global/notification/get?&createdAt=desc',
      {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
        withCredentials:true,
      }
    )
  } else if (limit > 0) {
    request = axios.get(
      global.api.dev +
        '/global/notification/get?&createdAt=desc&limit=' +
        limit +
        '&page=' +
        page,
      {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
        withCredentials:true,
      }
    )
  }

  return (dispatch) =>
    request.then((response) => {
      //console.log('getUnreadNotifications', response)
      return dispatch({
        type: GET_UNREAD_NOTIFICATIONS,
        payload: response.data,
      })
    })
}

export function getFourUnreadNotifications() {
  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  const request = axios.get(
    global.api.dev +
      '/global/notification/get?read=false&page=1&limit=4&createdAt=desc',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log('get4UnreadNotifications', response)
      return dispatch({
        type: GET_4_UNREAD_NOTIFICATIONS,
        payload: response.data,
      })
    })
}

export function readAllNotifications() {
  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  const request = axios.patch(
    global.api.dev + '/global/notification/read/all',
    {},
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      //console.log(response)
      if (response.status === 200)
        dispatch(showMessage({message: 'All notications have been read'}))

      return dispatch({
        type: READ_ALL_NOTIFICATIONS,
        payload: response.data,
      })
    })
}
