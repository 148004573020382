import {FuseLoadable} from '@fuse'

export const Register2PageConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
          folded: false,
          position: 'left',
        },
        toolbar: {
          display: false,
          style: 'fixed',
          position: 'below',
        },
        footer: {
          display: false,
          style: 'fixed',
          position: 'below',
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/signup',
      component: FuseLoadable({
        loader: () => import('./Register2Page'),
      }),
    },
  ],
}
