import gql from 'graphql-tag'

export const EVENT_CATEGORIES = gql`
  query {
    event_categories {
      id
      parent
      code
      name
    }
  }
`

export const SEARCH_EVENT = gql`
  query(
    $offset: Int
    $limit: Int
    $order: [event_schedules_order_by!]
    $query: String
    $status: String
    $categoryIds: [Int!]
    $ratingComparison: float8_comparison_exp
    $pricing: Int_comparison_exp
    $eventTypes: [Int!]
    $durations: [event_schedules_bool_exp]
  ) {
    event_schedules(
      offset: $offset
      limit: $limit
      order_by: $order
      where: {
        title: {_ilike: $query}
        status: {_eq: $status}
        category: {_in: $categoryIds}
        average_rating: $ratingComparison
        pricing_idr: $pricing
        event_type: {id: {_in: $eventTypes}}
        _or: $durations
      }
    ) {
      event_category {
        id
        name
      }
      title
      slug
      thumbnail
      pricing_idr
      datestart
      date_added
      event_category {
        id
        name
      }

      event_type {
        id
        name
      }
      event_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
        }
      }
    }
  }
`
