import gql from 'graphql-tag'

export const COURSE_CATEGORIES = gql`
  query {
    academy_course_categories {
      id
      name
      academy_course_categories {
        id
        name
      }
    }
  }
`

export const SEARCH_COURSE = gql`
  query(
    $offset: Int
    $limit: Int
    $query: String
    $durations: [academy_courses_bool_exp!]
    $order: [academy_courses_order_by!]
    $levels: [Int!]
    $language: [String!]
    $category: [Int!]
    $price: Int_comparison_exp
    $rating: float8_comparison_exp
    $userId: uuid!
  ) {
    academy_courses(
      offset: $offset
      limit: $limit
      where: {
        average_rating: $rating
        academy_price_tier: {pricing_idr: $price}
        level: {_in: $levels}
        language: {_in: $language}
        category: {_in: $category}
        _and: [
          {_or: [{title: {_ilike: $query}}, {slug: {_ilike: $query}}]}
          {_or: $durations}
        ]
      }
      order_by: $order
    ) {
      id
      title
      thumbnail
      slug
      academy_course_enrollments_aggregate(where: {user_id: {_eq: $userId}}) {
        aggregate {
          is_enrolled: count
        }
      }
      academy_course_category {
        id
        name
      }
      level
      language
      global_user {
        id
        name
      }
      academy_price_tier {
        pricing_idr
      }
      average_rating
      topic
      duration_seconds
    }
  }
`
