import {FuseLoadable} from '@fuse'

export const AnalyticsDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/administrator/dashboard',
      component: FuseLoadable({
        loader: () => import('./AnalyticsDashboardApp'),
      }),
    },
    {
      path: '/instructor/course/create',
      component: FuseLoadable({
        loader: () => import('./InstructorCreateCourse'),
      }),
    },
    {
      path: '/instructor/become',
      component: FuseLoadable({
        loader: () => import('./BecomeInstructor'),
      }),
    },
    {
      path: '/instructor/manage_course/:slug',
      component: FuseLoadable({
        loader: () => import('./InstructorManageCourse'),
      }),
    },
    {
      path: '/instructor/manage_course_curriculum/:slug',
      component: FuseLoadable({
        loader: () => import('./ManageCourseCurriculum'),
      }),
    },
    {
      path: '/instructor/course/curriculum/quiz/preview/:lessonId',
      component: FuseLoadable({
        loader: () => import('./course/quiz/preview/Preview'),
      }),
    },
    {
      path: '/instructor/manage_course_caption/:slug',
      component: FuseLoadable({
        loader: () => import('./InstructorManageCourseCaption'),
      }),
    },
    {
      path: '/instructor/manage_course_landing_page/:slug',
      component: FuseLoadable({
        loader: () => import('./InstructorManageCourseLandingPage'),
      }),
    },
    {
      path: '/instructor/manage_course_price_coupons/:slug',
      component: FuseLoadable({
        loader: () => import('./InstructorManageCoursePriceCoupons'),
      }),
    },
    {
      path: '/instructor/manage_course_communications/:slug',
      component: FuseLoadable({
        loader: () => import('./InstructorManageCourseCommunications'),
      }),
    },
  ],
}
