import gql from 'graphql-tag'

export const CREATE_MICROLEARNING_COMMENT = gql`
  mutation($content: String!, $microlearningId: uuid!, $parentId: uuid) {
    insert_microlearning_comments_one(
      object: {
        content: $content
        microlearning_id: $microlearningId
        parent_id: $parentId
      }
    ) {
      id
    }
  }
`
