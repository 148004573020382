import gql from 'graphql-tag'

export const GET_CREATOR_COURSE = gql`
  query($courseId: Int) {
    academy_courses(where: {id: {_eq: $courseId}}) {
      id
      global_user {
        id
        name
        avatar
        contributor_label
        people_work_placements {
          id
          company_job_profile {
            id
            position
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
        learning_contributor_ratings {
          total_rating_avg
          total_learner_count
          total_catalog_count
          total_rating_count
        }
      }
    }
  }
`
