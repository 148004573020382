import React from 'react'
import {Grid} from '@material-ui/core'
import DashboardDescription from './DashboardDescription'
import DashboardCategory from './DashboardCategory'
import DashboardEnrollments from './DashboardEnrollments'
import DashboardRating from './DashboardRating'
import DashboardReview from './DashboardReview'

const MicroLearningDashboard = props => {
  return (
    <div>
      <DashboardDescription {...props} />
      <DashboardCategory {...props} />
      <Grid container spacing={16} className="mb-28">
        <Grid item xs={12} md={4}>
          <DashboardEnrollments {...props} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardRating {...props} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardReview {...props} />
        </Grid>
      </Grid>
      {/* <DashboardRevenue /> */}
    </div>
  )
}

export default MicroLearningDashboard
