// import {authRoles} from 'app/auth';
import Callback from './Callback'

export const CallbackConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  // auth    : authRoles.onlyGuest,
  routes: [
    {
      path: '/callback',
      component: Callback,
    },
  ],
}
