import axios from 'axios'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable'
import _ from 'lodash'
import history from 'history.js'

export const GET_COURSE = '[ACADEMY APP] GET COURSE'
export const GET_COMMENTS = '[ACADEMY APP] GET COMMENTS'
export const GET_COURSE_BY_SLUG = '[ACADEMY APP] GET COURSE BY SLUG'
export const SAVE_COURSE = '[ACADEMY APP] SAVE COURSE'
export const UPDATE_COURSE_PROGRESS = '[ACADEMY APP] UPDATE COURSE PROGRESS'
export const UPDATE_COURSE = '[ACADEMY APP] UPDATE COURSE'
export const GET_WIDGETS = '[PROJECT DASHBOARD APP] GET WIDGETS'
export const GET_QUIZ = '[INSTRUCTOR DASHBOARD] GET QUIZ'
export const SAVE_QUIZ = '[INSTRUCTOR DASHBOARD] SAVE QUIZ'
export const REMOVE_QUIZ = '[INSTRUCTOR DASHBOARD] REMOVE QUIZ'
export const GET_DASHBOARD_STATISTICS =
  '[ANALYTICS DASHBOARD APP] GET DASHBOARD STATISTICS'
export const GET_QUESTIONS_BY_LESSON_ID =
  '[INSTRUCTOR MANAGE COURSE] GET QUESTIONS BY LESSON ID'

//WLB KID Implementation
export function updateCourse(data) {
  const request = axios.patch(
    global.api.dev + '/academy/course/' + data.id + '/update/',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      dispatch(showMessage({message: 'Course Saved'}))
      return dispatch({
        type: UPDATE_COURSE,
        payload: response.data,
      })
    })
}

export function updateLesson(data) {
  const request = axios.patch(
    global.api.dev + '/academy/lesson/' + data.id + '/update/',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      if (response.status === 200)
        dispatch(
          showMessage({
            message: 'Lesson Updated',
          })
        )
      return dispatch({
        type: UPDATE_COURSE,
        payload: response.data,
      })
    })
}

export function updateCourseProgress(data) {
  const request = axios.post(
    global.api.dev + '/academy/enrollment/' + data.courseId + '/progress/',
    {
      courseId: data.courseId,
      lessonId: data.lessonId,
      grade: data.grade,
      status: data.status,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true
    }
  )

  return (dispatch) =>
    request.then((response) => {
      return dispatch({
        type: UPDATE_COURSE_PROGRESS,
        payload: response.data,
      })
    })
}

export function getCourseBySlug(slug) {
  const userData = global.data_user
  //console.log("userData: "+JSON.stringify(global.data_user))

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axios.get(global.api.dev + '/academy/course/get/' + slug, {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_COURSE_BY_SLUG,
        payload: response.data,
      })
    )
}

export function getCourseDiscussion(courseId) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/')
  }

  const request = axios.get(
    global.api.dev + '/academy/course/' + courseId + '/discussion',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      dispatch({
        type: GET_COMMENTS,
        payload: response.data,
      })
    })
}

export function getDashboardStatistics() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.data_user.oauth.access_token,
    },
    withCredentials:true,
  }

  const bodyParameters = {}

  const request = axios.get(
    global.api.dev + '/academy/dashboard/get',
    config,
    bodyParameters
  )
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_DASHBOARD_STATISTICS,
        payload: response.data,
      })
    )
}

/* export function getQuiz() {
  const config = {
    headers: {
      Authorization: 'Bearer ' + global.data_user.oauth.access_token,
    },
  }

  const bodyParameters = {}

  const request = axios.get(
    global.api.dev + '/academy/question/get',
    config,
    bodyParameters
  )
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_QUIZ,
        payload: response.data,
      })
    )
} */

export function getQuestionsByLessonId(lessonId) {
  const request = axios.get(
    global.api.dev + '/academy/lesson/' + lessonId + '/question',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      console.log('getQuestionsByLessonId action 1', response.data)
      return dispatch({
        type: GET_QUESTIONS_BY_LESSON_ID,
        payload: response.data,
      })
    })
}

export function saveQuiz(dataPost) {
  const request = axios.post(
    global.api.dev + '/academy/question/create',
    dataPost,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return (dispatch) =>
    request.then((response) => {
      if (response.status < 400) {
        let disp = dispatch({
          type: SAVE_QUIZ,
        })

        dispatch(getQuestionsByLessonId(dataPost.lesson))

        return disp
      } else {
        dispatch({message: 'Error ' + response.status})
      }
    })
}

export function removeQuiz(id) {
  const request = axios.delete(
    global.api.dev + '/academy/question/' + id + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )
  return (dispatch) =>
    request.then((response) => {
      let disp = dispatch({
        type: REMOVE_QUIZ,
      })

      dispatch(getQuestionsByLessonId(id))

      return disp
    })
}

//Fuse Demo
export function getCourse(params) {
  const request = axios.get('/api/academy-app/course', {params},{
    withCredentials: true
  })

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_COURSE,
        payload: response.data,
      })
    )
}

export function saveCourse(data) {
  const request = axios.post('/api/academy-app/course/save', data,{
    withCredentials: true
  })

  return (dispatch) =>
    request.then((response) => {
      dispatch(showMessage({message: 'Course Saved'}))

      return dispatch({
        type: SAVE_COURSE,
        payload: response.data,
      })
    })
}

/*export function updateCourse(data) {
  return (dispatch, getState) => {
    const {id} = getState().academyApp.course
    const request = axios.post('/api/academy-app/course/update', {id, ...data})

    request.then(response => {
      dispatch(showMessage({message: 'Course Updated'}))

      return dispatch({
        type: UPDATE_COURSE,
        payload: response.data,
      })
    })
  }
}*/

export function getWidgets() {
  const request = axios.get('/api/project-dashboard-app/widgets',{
    withCredentials: true
  })

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_WIDGETS,
        payload: response.data,
      })
    )
}
