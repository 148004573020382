import gql from 'graphql-tag'

export const GET_WISHLIST_BOOK = gql`
  query($type: Int, $limit: Int, $offset: Int, $query: String) {
    academy_book_wishlists(
      offset: $offset
      limit: $limit
      where: {academy_book: {title: {_ilike: $query}, book_type: {_eq: $type}}}
    ) {
      id
      isbn
      academy_book {
        id
        title
        thumbnail
        author_written
        pricing_idr
        average_rating
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`
