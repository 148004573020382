import gql from 'graphql-tag'

export const LIKE_COMMENT_MICROLEARNING = gql`
  mutation($microlearningCommentId: uuid) {
    insert_microlearning_comment_likes_one(
      object: {microlearning_comment_id: $microlearningCommentId}
    ) {
      id
    }
  }
`

export const UNLIKE_COMMENT_MICROLEARNING = gql`
  mutation($microlearningCommentId: uuid, $userId: uuid!) {
    delete_microlearning_comment_likes(
      where: {
        microlearning_comment_id: {_eq: $microlearningCommentId}
        user_id: {_eq: $userId}
      }
    ) {
      affected_rows
    }
  }
`
