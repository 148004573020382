import gql from 'graphql-tag'

export const GET_JOINED_CLASSROOM = gql`
  query($memberUserId: uuid!, $limit: Int, $offset: Int) {
    classrooms(
      where: {classroom_members: {member_user_id: {_eq: $memberUserId}}}
      limit: $limit
      offset: $offset
    ) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      is_finished
      learning_contributors: classroom_members(
        where: {
          _or: [{is_individual_mentor: {_eq: true}}, {is_mentor: {_eq: true}}]
        }
      ) {
        global_user {
          id
          name
          avatar
        }
      }
      members: classroom_members(
        where: {
          is_mentor: {_eq: false}
          is_individual_mentor: {_eq: false}
          is_admin: {_eq: false}
        }
      ) {
        id
        user: global_user {
          id
          avatar
          name
        }
      }
      #Classroom Owner
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            # Average Review
            star
          }
        }
      }
    }
  }
`
