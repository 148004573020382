import gql from 'graphql-tag'

export const GET_DIARY = gql`
  query($userId: uuid) {
    learning_diaries(where: {user_id: {_eq: $userId}}) {
      id
      subject
      description
      date_added
      duration
      date
      global_user {
        id
        name
        avatar
      }
      learning_diary_activity {
        activity
      }
      learning_diary_participants {
        id
        global_user {
          id
          name
          avatar
        }
      }
      learning_diary_files {
        id
        size
        file
        name
        type
      }
    }
  }
`

export const GET_DIARY_BY_ID = gql`
  query($id: uuid) {
    learning_diaries(where: {id: {_eq: $id}}) {
      id
      subject
      description
      date_added
      duration
      global_user {
        id
        name
        avatar
      }
      learning_diary_activity {
        activity
      }
      learning_diary_participants {
        id
        global_user {
          id
          name
          avatar
        }
      }
      learning_diary_files {
        id
        size
        file
        name
        type
      }
      competency_dictionary {
        id
        name
      }
    }
  }
`

export const GET_ACTIVITIES = gql`
  query {
    learning_diary_activities {
      id
      activity
    }
  }
`

export const GET_COMPETENCIES = gql`
  query(
    $offset: Int
    $limit: Int
    $companyId: uuid
    $userId: uuid
    $query: String
  ) {
    competency_dictionaries(
      offset: $offset
      limit: $limit
      where: {
        search_name: {_ilike: $query}
        status: {_eq: "ACTIVE"}
        company: {_eq: $companyId}
      }
    ) {
      id
      name
      company
      search_name
    }
    learning_diary_competencies(
      where: {
        name: {_ilike: $query}
        user_id: {_eq: $userId}
        company_id: {_eq: $companyId}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      user_id
      name
      description
    }
  }
`

export const GET_USER_COMPANY_COMPETENCIES_LEARNING_DIARY = gql`
  query getUserCompanyCompentenciesLearningDiary(
    $userId: uuid!
    $limit: Int
    $offset: Int
    $query: String
  ) {
    learning_diary_competencies(
      where: {user_id: {_eq: $userId}, name: {_ilike: $query}}
      limit: $limit
      offset: $offset
    ) {
      id
      name
      description
    }
    competency_dictionaries(
      where: {
        competency_version_dictionaries: {
          competency_individuals: {
            user: {_eq: $userId}
            deleted_at: {_is_null: true}
          }
        }
        search_name: {_ilike: $query}
      }
    ) {
      id
      dictionary_id
      name
    }
  }
`
