// constants for global
export const status_process = {
  active: 'Aktif',
  inactive: 'Tidak Aktif',
  draft: 'Draf',
  pending: 'Tertunda',
  registered: 'Terdaftar',
}

export const EVENT_TYPE_CATEGORY = {
  Online: 'Daring',
  Offline: 'Luring',
  Inhouse: 'Sesuai permintaan peserta',
}

export const CONTRIBUTOR_LABEL = {
  COACH: 'Pelatih',
  INSTRUCTOR: 'Instruktur / Pengajar',
  MENTOR: 'Pembimbing',
  TRAINER: 'Pelatih Individu',
  EXPERT: 'Ahli',
}

export const LEVEL_SKILL = {
  beginner: 'Pemula',
  intermediate: 'Menengah',
  advanced: 'Lanjutan',
  advance: 'Lanjutan',
}
