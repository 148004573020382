import React from 'react'
import {Divider, Paper, Typography, Chip} from '@material-ui/core'

const styles = {
  tealFont: {
    color: '#003d51',
  },
}

const DashboardCategory = props => {
  const {course} = props

  const handleDelete = () => {
    console.info('You clicked the delete icon.')
  }

  return (
    <Paper className="w-full mb-28">
      <div className="flex flex-col">
        <Typography
          // variant="h5"
          // component="h5"
          className="my-20 mx-16 font-bold text-blue-dark text-16"
          style={styles}
        >
          Kategori
        </Typography>
        <Divider className="mx-16" />
        <div className="py-32 px-16">
          {props.location.state.microLearning.academy_course_category && (
            <Chip
              label={
                props.location.state.microLearning.academy_course_category.name
              }
              color="secondary"
              className="mr-12 mb-12"
              style={{backgroundColor: '#039BE5', padding: '10px'}}
            />
          )}
        </div>
      </div>
    </Paper>
  )
}

export default DashboardCategory
