import React from 'react'
import {
  Avatar,
  Paper,
  Typography,
  Divider,
  Icon,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {LEARNING_CONTRIBUTOR_LIST_REVIEWS} from 'app/graphql/queries/index'
import moment from 'moment'

const RATING_DATA = [1, 2, 3, 4, 5]
const FILTER_RATING_DATA = [5, 4, 3, 2, 1]

const styles = {
  skyBlueColor: {
    color: '#039be5',
  },
  viewMore: {
    color: '#014a62',
    borderColor: '#014a62',
    width: '50%',
    height: '4rem',
    fontWeight: 'bold',
  },
}

const ListPeople = ({rating, name, avatar, date_added, review_text}) => {
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-row p-8 mt-10">
        <Avatar src={avatar || '/assets/images/avatars/profile.jpg'} />
        <div className="flex flex-col">
          <div className="flex flex-row">
            {RATING_DATA.map(res => {
              if (res <= rating) {
                return <Icon style={styles.skyBlueColor}>star</Icon>
              } else return <Icon className="text-grey">star</Icon>
            })}
          </div>
          <Typography>{name || '-'}</Typography>
        </div>
        <div className="flex flex-col">
          <Typography className="text-grey mt-10">
            {moment(date_added).format('MMM Do YY')}
          </Typography>
          <Typography>{review_text}</Typography>
        </div>
      </div>
      <Divider className="mt-10 mb-10" />
    </React.Fragment>
  )
}

const ReviewComponent = props => {
  const {
    data: dataReviews,
    loading: loadingReviews,
    error: errorReviews,
  } = useQuery(LEARNING_CONTRIBUTOR_LIST_REVIEWS, {
    variables: {
      offset: 0,
      limit: 10,
      microlearningId: props.location.state.microLearning.id,
    },
  })

  if (loadingReviews) {
    return <CircularProgress />
  } else if (errorReviews) {
    return <div>{JSON.stringify(errorReviews)}</div>
  }

  return (
    <Paper className="w-full">
      <Typography
        className="my-20 mx-16 font-bold text-blue-dark text-16"
        style={styles}
      >
        Pratinjau Pembelajaran Mikro
      </Typography>
      <Divider />
      <div className="px-48">
        <div className="flex justify-center my-24">
          <div className="flex flex-col items-center">
            {(function() {
              let avg = 0
              dataReviews.microlearning_reviews.filter(review => {
                avg += review.star
              })

              return (
                <>
                  <Typography className="text-grey-darker" variant="h2">
                    {/* {(avg / dataReviews.microlearning_reviews.length) > 0 ? (avg / dataReviews.microlearning_reviews).toFixed(1) : 0} */}
                    {avg / dataReviews.microlearning_reviews.length > 0
                      ? (
                          avg / dataReviews.microlearning_reviews.length
                        ).toFixed(1)
                      : 0}
                  </Typography>
                  <div className="flex mt-8">
                    {RATING_DATA.map((res, i) => (
                      <Icon
                        key={`${i}`}
                        style={
                          res <= avg ? styles.skyBlueColor : {color: '#6C7C87'}
                        }
                      >
                        star
                      </Icon>
                    ))}
                  </div>
                  <Typography className="text-grey-darker">
                    {`Berdasarkan ${dataReviews.microlearning_reviews.length} penilaian`}
                  </Typography>
                </>
              )
            })()}
          </div>
        </div>
        <div className="flex mt-48 w-full mb-48">
          <div className="flex flex-1 content-between justify-between w-3/4 ">
            <Button variant="outlined">Semua</Button>
            {FILTER_RATING_DATA.map((res, i) => {
              return (
                <Button key={`${i}`} variant="outlined">
                  <Icon style={styles.skyBlueColor}>star</Icon>
                  <Typography className="ml-4">{res}</Typography>
                </Button>
              )
            })}
          </div>
        </div>
        <Divider className="mt-12" />
        {dataReviews.microlearning_reviews.map((res, i) => (
          <ListPeople
            rating={res.star}
            name={res.global_user && res.global_user.name}
            avatar={res.global_user && res.global_user.avatar}
            date_added={res.created_at}
            review_text={res.description || '-'}
            key={`${i}-idx`}
          />
        ))}
      </div>
    </Paper>
  )
}

export default ReviewComponent
