import {Button, Divider, Paper, Snackbar, Typography} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import ManageLanding from './form/MicroLearningManageLanding'
import ManagePrice from './form/MicroLearningManagePrice'
import ManageQuestion from './form/MicroLearningManageQuestion'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_MICROLEARNING_BY_ID,
  GET_COUNT_SLUG,
  GET_QUESTIONS_BY_MICROLEARNING_ID,
} from '../../../../../graphql/queries/microlearning'
import {UPDATE_MICROLEARNING} from '../../../../../graphql/mutations/microlearning'
import {status_process} from 'app/constants'

const style = {
  colorPrimary: {
    color: '#014A62',
  },
  saveBtn: {
    backgroundColor: '#039BE5',
    color: '#fff',
    textTransform: 'none',
    marginLeft: 12,
  },
  header: {
    backgroundColor: '#fff',
    width: '-webkit-fill-available',
    boxShadow: '0px 3px 6px #0000001A',
    zIndex: 1,
  },
  grey: {color: '#A9A8A8'},
  chipSkyBlue: {backgroundColor: '#039BE5'},
  iconSkyBlue: {
    color: '#039BE5',
    border: '1px solid #039BE5',
    padding: 5,
    marginTop: 12,
  },
  iconDisabled: {
    color: '#A9A8A8',
    border: '1px solid #A9A8A8',
    padding: 5,
    marginTop: 12,
  },
  iconRed: {
    color: '#DD5962',
    border: '1px solid #DD5962',
    padding: 5,
    marginTop: 12,
  },
  addContainer: {
    background: '#F7F8F9 0% 0% no-repeat padding-box',
    border: '1px dashed #A9A8A8',
    width: '400px',
    padding: '85px 0',
    textAlign: 'center',
    marginTop: 40,

    position: 'relative',
  },
  input: {
    opacity: 0.0,
    MsFilter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
    filter: 'alpha(opacity=0)',
    MozOpacity: 0.0,
    KhtmlOpacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  expansion: {background: '#f7f8f9', marginBottom: 20},
  customEditorFooter: {
    width: '100%',
    height: 20,
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#014a62',
  },
  textSkyBlue: {color: '#039BE5'},
  uploadedImg: {width: 400, marginRight: 16},
}

const MicroLearningManage = props => {
  const [page, setPage] = useState(0)
  const microlearningId = props.match.params.id

  const [value, setValue] = useState({
    name: '',
    description: '',
    slug: '',
    banner_url: '',
    topic: [],
    category_id: '',
    level: '',
    language_id: '',
    subtitle: '',
    status: '',
    subcategory: '',
    price: '',
  })

  const [isError, setIsError] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    message: '',
  })

  const handlePage = _page => {
    setPage(_page)
  }

  const {data} = useQuery(GET_MICROLEARNING_BY_ID, {
    variables: {
      id: microlearningId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const {data: countSlug} = useQuery(GET_COUNT_SLUG, {
    variables: {
      slug: value.slug,
      id: microlearningId,
    },
  })

  const {data: dataQuest, refetch: refetchQuest} = useQuery(
    GET_QUESTIONS_BY_MICROLEARNING_ID,
    {
      variables: {
        id: microlearningId,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'organization-staff',
        },
      },
    }
  )

  const [mutationUpdateMicrolearning] = useMutation(UPDATE_MICROLEARNING)

  const isSlugExist =
    countSlug && countSlug.microlearnings_aggregate.aggregate.count > 0

  const _dataQuest =
    dataQuest && dataQuest.microlearnings[0].microlearning_questions

  const handleSave = type => {
    const errorPage = tab => {
      setPage(tab)
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    }

    if (
      !value.name ||
      !value.slug ||
      value.topic.length === 0 ||
      !value.category_id ||
      !value.level ||
      !value.language_id ||
      !value.subcategory ||
      isSlugExist
    ) {
      errorPage(0)
    } else if (value.price === '') {
      errorPage(1)
    } else if (_dataQuest.length === 0) {
      errorPage(2)
    } else {
      const variables = {
        microlearningId,
        set: {
          name: value.name,
          description: value.description,
          slug: value.slug,
          icon_url: value.banner_url,
          topic: value.topic,
          category_id: value.subcategory,
          level: value.level,
          language_id: value.language_id,
          subtitle: value.subtitle,
          price: parseInt(value.price),
          status: value.status,
        },
      }

      if (type === 'publish') {
        variables.set.status = 'PENDING'
      }

      mutationUpdateMicrolearning({
        variables,
        context: {
          headers: {
            'X-Hasura-Role': 'organization-staff',
          },
        },
      })
        .then(() => {
          setAlert({
            open: true,
            message: 'Micro Learning Updated',
          })
          setTimeout(() => {
            setAlert({
              open: false,
              message: '',
            })
            props.history.push({
              pathname: '/instructor/micro-learning',
            })
          }, 2000)
        })
        .catch(() => {})
    }
  }

  useEffect(() => {
    refetchQuest()
    if (data) {
      const {
        name,
        status,
        description,
        slug,
        icon_url,
        academy_course_category,
        global_language,
        topic,
        level,
        subtitle,
        price,
      } = data.microlearnings[0]

      setValue({
        name,
        description,
        slug,
        banner_url: icon_url,
        topic: topic || [],
        category_id:
          academy_course_category && academy_course_category.parent
            ? academy_course_category.parent.id
            : academy_course_category.id,
        level,
        language_id: global_language && global_language.id,
        subtitle,
        status,
        price,
        subcategory:
          academy_course_category && academy_course_category.parent
            ? academy_course_category.id
            : null,
      })
    }
  }, [data])

  const handleChange = prop => event => {
    const vals = {
      ...value,
      [prop]: event.target.value,
    }
    if (prop === 'category_id') {
      vals.subcategory = null
    }
    setValue(vals)
  }

  const nav = _page => {
    return (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => handlePage(_page)}
      >
        {/* <Checkbox checked={page === _page} /> */}
        <Typography
          variant="body2"
          className="font-semibold"
          style={page === _page ? style.colorPrimary : {color: 'unset'}}
          paragraph
        >
          {_page === 0
            ? 'Halaman Arahan Pembelajaran Mikro'
            : _page === 1
            ? 'Harga'
            : 'Pertanyaan'}
        </Typography>
      </div>
    )
  }

  return (
    <div>
      <div
        className="md:px-64 px-16 py-16 flex flex-row items-center fixed"
        style={style.header}
      >
        <div className="w-1/4">
          <img
            src={'assets/images/logos/wlb-combined-green.svg'}
            alt="wlb-logo"
            className="w-128"
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <Typography variant="h6" style={{fontWeight: 'bold'}}>
            {value.name}
          </Typography>
          <Typography variant="body1" className="ml-12">
            {status_process[value.status]}
          </Typography>
        </div>

        <div className="w-1/4 text-right">
          <Button
            style={{
              color: '#EF4D5E',
              textTransform: 'none',
            }}
            size="large"
            onClick={props.history.goBack}
          >
            Batal
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={style.saveBtn}
            size="large"
            onClick={() => handleSave('save')}
          >
            Simpan
          </Button>
        </div>
      </div>
      <div className="flex mx-72" style={{marginTop: '10rem'}}>
        <div className="w-1/4">
          <Paper className="p-24">
            <Typography variant="body1" className="font-bold" paragraph>
              Kelola Publikasi
            </Typography>
            {nav(0)}
            {nav(1)}
            <Typography variant="body1" className="font-bold mt-16" paragraph>
              Isi
            </Typography>
            {nav(2)}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="normal-case w-full mt-24"
              onClick={() => handleSave('publish')}
            >
              Kirim Ulasan
            </Button>
          </Paper>
        </div>
        <div className="w-3/4">
          <Paper className="ml-24" style={{minHeight: '75vh'}}>
            <Typography
              variant="body1"
              className="font-semibold p-24"
              style={style.colorPrimary}
            >
              {page === 0
                ? 'Halaman Arahan Pembelajaran Mikro'
                : page === 1
                ? 'Harga'
                : 'Pertanyaan'}
            </Typography>
            <Divider />
            <div className="p-24">
              {page === 0 ? (
                <ManageLanding
                  style={style}
                  value={value}
                  handleChange={handleChange}
                  setValue={setValue}
                  isError={isError}
                  isSlugExist={isSlugExist}
                  setAlert={setAlert}
                />
              ) : page === 1 ? (
                <ManagePrice
                  value={value}
                  handleChange={handleChange}
                  isError={isError}
                />
              ) : (
                <ManageQuestion
                  style={style}
                  data={_dataQuest}
                  microlearningId={microlearningId}
                  refetch={refetchQuest}
                  isError={isError}
                />
              )}
            </div>
          </Paper>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alert.open}
        message={alert.message}
        // ContentProps={{classes: {root: 'bg-red'}}}
      />
    </div>
  )
}

export default withRouter(MicroLearningManage)
