import axios from 'axios'
import {axiosInstanceBearer} from 'app/services/restApiService'
import {reactLocalStorage} from 'reactjs-localstorage'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable'
import history from 'history.js'
import _ from '@lodash'
// .
export const GET_COURSE_BY_SLUG = '[ACADEMY APP] GET COURSE BY SLUG'
export const GET_COURSES = '[ACADEMY APP] GET COURSES'
export const GET_MY_COURSES = '[ACADEMY APP] GET MY COURSES'
export const GET_STUDENT = '[ACADEMY APP] GET STUDENT PROFILE'
export const GET_PROMO = '[ACADEMY APP] GET PROMO COURSES'
export const GET_RECOMMENDATION = '[ACADEMY APP] GET RECOMMENDATION'
export const GET_TRENDING = '[ACADEMY APP] GET TRENDING'
export const GET_CART = '[ACADEMY APP] GET CART'
export const GET_WISHLIST = '[ACADEMY APP] GET WISHLIST'
export const GET_CATEGORIES = '[ACADEMY APP] GET CATEGORIES'
export const GET_COURSE_CATEGORIES = '[ACADEMY APP] GET COURSE CATEGORIES'
export const SET_COURSES_SEARCH_TEXT = '[ACADEMY APP] SET COURSES SEARCH TEXT'
export const SET_COURSES_CATEGORY_FILTER =
  '[ACADEMY APP] SET COURSES CATEGORY FILTER'
export const ADD_TO_CART = '[ACADEMY APP] ADD TO CART'
export const ADD_TO_WISHLIST = '[ACADEMY APP] ADD TO WISHLIST'
export const SET_ARCHIEVED = '[ACADEMY APP] SET ARCHIEVED'
export const REMOVE_FROM_WISHLIST = '[ACADEMY APP] REMOVE FROM WISHLIST'
export const CHECKOUT = '[ACADEMY APP] CHECKOUT'
export const IS_SHOWED = '[ACADEMY APP] SHOW ALL COURSES CATALOG'
export const REMOVE_FROM_CART = '[ACADEMY APP] REMOVE FROM CART'
export const GET_COURSES_INSTRUCTOR = '[ACADEMY APP] GET COURSES INSTUCTOR'
export const GET_COURSE_EXPERT = '[ACADEMY APP] GET COURSES EXPERT LOCATOR'
export const MOVE_TO_WISHLIST = '[ACADEMY APP] MOVE TO WISHLIST'
export const SEARCH_GLOBAL_VALUE = '[ACADEMY APP] SEARCH GLOBAL VALUE'

export function getCourses(categoryId, type) {
  const userData = global.data_user
  if (_.isEmpty(userData)) {
    // history.push('/learning').
  } else {
    const request = axios.get(
      `${global.api.dev}${
        !type
          ? '/academy/course/get?type[0]=1&category='
          : '/academy/book/get?category='
      }${categoryId}`,
      {
        headers: {
          Authorization: 'Bearer ' + global.access_token,
        },
        withCredentials:true,
      }
    )
    return dispatch =>
      request.then(response => {
        return dispatch({
          type: GET_COURSES,
          payload: response.data.data,
        })
      })
  }
}

export function getCategories() {
  const request = axios.get('/api/academy-app/categories',{
    withCredentials: true
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_CATEGORIES,
        payload: response.data,
      })
    )
}

export function getCourseCategories() {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/category/get/',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_CATEGORIES,
        payload: response.data,
      })
    })
}

// export function getCourseRecommended(limit,page){
export function getCourseRecommended(categoryId, type) {
  /* if(userData){ */

  const APIURL = categoryId
    ? `${global.api.dev}${
        !type
          ? '/academy/course/recommendation?type[0]=1&category='
          : '/academy/course/recommendation?type[0]=1&category='
      }${categoryId}`
    : `${global.api.dev}${
        !type
          ? '/academy/course/recommendation'
          : '/academy/course/recommendation'
      }`

  const request = axiosInstanceBearer.get(APIURL, {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  })
  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_RECOMMENDATION,
        payload: response ? response.data.data : [],
      })
    })
  /* }else{
    learning;
} */
}

// export function getCourseTrending(limit,page){
export function getCourseTrending(categoryId, type) {
  // const request=axios.get('https://poseidon.worklifebeyond.com/academy/course/get?limit='+limit+"&page="+page,

  const request = axiosInstanceBearer.get(
    `${global.api.dev}${
      !type
        ? '/academy/course/trending?type[0]=1&category='
        : '/academy/course/trending?type[0]=1&category='
    }${categoryId}`,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_TRENDING,
        payload: response ? response.data.data : [],
      })
    })
}

// export function getCoursePromo(limit,page){
export function getCoursePromo(categoryId, type) {
  // const request=axios.get('https://poseidon.worklifebeyond.com/academy/course/promo?limit='+limit+"&page="+page,
  const APIURL = categoryId
    ? `${global.api.dev}${
        !type
          ? '/academy/course/promo?type[0]=1&category='
          : '/academy/book/promo?category='
      }${categoryId}`
    : `${global.api.dev}${
        !type ? '/academy/course/promo?type[0]=1' : '/academy/book/promo'
      }`

  const request = axiosInstanceBearer.get(APIURL, {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_PROMO,
        payload: response ? response.data.data : [],
      })
    })
}

export function showAllCoursesCategory(isShowed) {
  if (isShowed === true) {
    return dispatch =>
      dispatch({
        type: IS_SHOWED,
        payload: true,
      })
  } else {
    return dispatch =>
      dispatch({
        type: IS_SHOWED,
        payload: false,
      })
  }
}

export function getMyCourses() {
  const userData = global.data_user
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/student/' + userData.id + '/enrollment',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_MY_COURSES,
        payload: response.data,
      })
    })
}

export function addToCart(courseId) {
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/cart/add',
    {
      courseId: courseId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response && response.status < 400) {
        const disp = dispatch({
          type: ADD_TO_CART,
        })

        dispatch(getCart())
        dispatch(getWishlist())

        // getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response && response.status}))
      }
    })
}

export function checkout(paymentMethod, currency, latestBalance) {
  const userData = global.data_user
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/cart/checkout',
    {
      paymentMethod: paymentMethod,
      currency: currency,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: CHECKOUT,
        })
        userData.latestBalance = latestBalance
        reactLocalStorage.setObject('userData', userData)

        dispatch(getCart())
        dispatch(getStudentProfile())

        // getCart();
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function setArchieved(enrollmentId, archieveStatus) {
  const request = axiosInstanceBearer.patch(
    `${global.api.dev}/academy/enrollment/${enrollmentId}/update`,
    {
      archived: archieveStatus,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: SET_ARCHIEVED,
          enrollmentId,
          archieveStatus,
        })
        dispatch(
          showMessage({
            message: archieveStatus ? 'Course Archived' : 'Course Unarchived',
          })
        )
        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function addToWishlist(courseId) {
  const request = axiosInstanceBearer.post(
    global.api.dev + '/academy/wishlist/add',
    {
      courseId: courseId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: ADD_TO_WISHLIST,
        })

        dispatch(getWishlist())
        dispatch(getCart())

        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.message}))
      }
    })
}

export function removeFromWishlist(wishlistId) {
  const request = axiosInstanceBearer.delete(
    global.api.dev + '/academy/wishlist/' + wishlistId + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response && response.status === 204) {
        const disp = dispatch({
          type: REMOVE_FROM_WISHLIST,
        })

        setTimeout(() => {
          dispatch(getWishlist())
          dispatch(getCart())
        }, 1000)

        return disp
      } else {
        dispatch(
          showMessage({
            message: 'Something went wrong.',
          })
        )
      }
    })
}

export function getCart() {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/cart/get',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_CART,
        payload: response.data,
      })
    })
}

export function getStudentProfile() {
  const userData = global.data_user
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/student/' + userData.id,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_STUDENT,
        payload: response.data,
      })
    })
}

export function getWishlist() {
  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/wishlist/get',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_WISHLIST,
        payload: response.data,
      })
    })
}

export function setCoursesSearchText(event) {
  return {
    type: SET_COURSES_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function setCategoryFilter(event) {
  return {
    type: SET_COURSES_CATEGORY_FILTER,
    category: event.target.value,
  }
}

export function removeFromCart(cartId) {
  const request = axiosInstanceBearer.delete(
    global.api.dev + '/academy/cart/' + cartId + '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status < 400) {
        const disp = dispatch({
          type: REMOVE_FROM_CART,
        })
        dispatch(getCart())

        return disp
      } else {
        dispatch(showMessage({message: 'Error ' + response.status}))
      }
    })
}

export function getCourseInstructor(dataInstructors) {
  const request = Promise.all(
    dataInstructors.map((id, index) => {
      return axiosInstanceBearer.get(
        global.api.dev + `/academy/instructor/${id}/course?limit=4`,
        {
          headers: {
            Authorization: 'Bearer ' + global.access_token,
          },
        }
      )
    })
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSES_INSTRUCTOR,
        payload: response,
      })
    })
}

export function getCourseExpert(search) {
  const request = axiosInstanceBearer.get(
    `${global.api.dev}/academy/course/get?search=${search}&limit=8`,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_EXPERT,
        payload: response.data.data,
      })
    })
}

export function moveCartToWishlist(cartId, courseId) {
  return dispatch => {
    const disp = dispatch({
      type: MOVE_TO_WISHLIST,
    })
    // dispatch(removeFromCart(cartId))
    dispatch(addToWishlist(courseId))

    return disp
  }
}

export function getCourseBySlug(slug) {
  const userData = global.data_user

  const request = axiosInstanceBearer.get(
    global.api.dev + '/academy/course/get/' + slug,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
    }
  )

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_COURSE_BY_SLUG,
        payload: response.data,
      })
    )
}

export function searchGlobalValue(value) {
  return dispatch => {
    dispatch({
      type: SEARCH_GLOBAL_VALUE,
      value,
    })
  }
}
