import React from 'react'
import {
  Button,
  AppBar,
  MuiThemeProvider,
  Toolbar,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import connect from 'react-redux/es/connect/connect'
import {Link, NavLink} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'

const StyledLi = withStyles({
  root: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
})(ListItem)

const FooterLayout2 = ({footerTheme}) => {
  return (
    <MuiThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className="relative z-10"
        style={{backgroundColor: '#f6f8f9'}}
        elevation="0"
      >
        <Toolbar className="max-w-2xl w-full mx-auto flex items-start">
          <div
            className="w-1/4"
            style={{
              justifyContent: 'center',
              flexDirection: 'column',
              alignSelf: 'center',
            }}
          >
            <NavLink to="/">
              <img
                src={'assets/images/logos/wlb-combined-green.svg'}
                className="h-48"
                alt="wlb-logo"
              />
            </NavLink>
          </div>

          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>About</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#about"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>
                    About Worklife &amp; Beyond
                  </Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#careers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>Careers</Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <Link className="font-medium" to="/instructor/become">
                  <Typography style={{color: '#014a62'}}>
                    Become an Instructor
                  </Typography>
                </Link>
              </StyledLi>
            </List>
          </div>
          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>Product</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Academic Solution
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Business Solution
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Government Solution
                </Typography>
              </StyledLi>
            </List>
          </div>
          {/* <div>
                        <List className="flex flex-col">
                            <StyledLi>
                                <Typography style={{color:"#014a62"}}>
                                    <b>
                                        Language
                                    </b>
                                </Typography>
                            </StyledLi>
                            <StyledLi>
                                <Typography style={{color:"#014a62"}}>Indonesia</Typography>
                            </StyledLi>
                            <StyledLi>
                                <Typography style={{color:"#014a62"}}>English</Typography>
                            </StyledLi>

                        </List>
                    </div> */}
          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>Language</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>Indonesia</Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>English</Typography>
              </StyledLi>
            </List>
          </div>
          <div className="w-1/5">
            <List className="flex flex-col">
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  <b>Connect With Us</b>
                </Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>
                  Email Address
                </Typography>
              </StyledLi>
              <StyledLi>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                />
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    marginLeft: '.8rem',
                    background: '#039be5',
                    fontSize: '1rem',
                  }}
                >
                  SUBSCRIBE
                </Button>
              </StyledLi>
              <List className="flex flex-row">
                <StyledLi>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={'assets/images/logos/fb-icon.svg'}
                      style={{width: '2em', height: '2em'}}
                      alt="fb-icon"
                    />
                  </a>
                </StyledLi>
                {/* <StyledLi>
                                    <img src={"assets/images/logos/google-icon.png"} style={{width: "2em", height: "2em"}} alt="google-icon"/>
                                </StyledLi> */}
                <StyledLi>
                  <img
                    src={'assets/images/logos/google-icon.png'}
                    style={{width: '2em', height: '2em'}}
                    alt="google-icon"
                  />
                </StyledLi>
                <StyledLi>
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={'assets/images/logos/linked-icon.svg'}
                      style={{width: '2em', height: '2em'}}
                      alt="linked-in-icon"
                    />
                  </a>
                </StyledLi>
              </List>
              <StyledLi>
                <br />
              </StyledLi>
            </List>
          </div>
          {/*<div>*/}
          {/*<ChatButton></ChatButton>*/}
          {/*</div>*/}
        </Toolbar>
      </AppBar>
      <AppBar
        id="fuse-footer"
        className="relative z-10"
        style={{backgroundColor: '#fff', color: '#014a62'}}
        elevation="0"
      >
        <Toolbar className="px-72 py-0 flex items-center">
          <div className="flex flex-1">
            © {new Date().getFullYear()} PT. Kreasi inovasi Digital. All Rights
            Reserved
          </div>

          <div>
            <List className="flex flex-row">
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>Home</Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <a
                  href="https://www.wlb.co.id/#services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{color: '#014a62'}}>Service</Typography>
                </a>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62'}}>Term</Typography>
              </StyledLi>
              <StyledLi>
                <Typography style={{color: '#014a62', width: '100px'}}>
                  Privacy Policy
                </Typography>
              </StyledLi>
            </List>
          </div>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  )
}

function mapStateToProps({fuse}) {
  return {
    footerTheme: fuse.settings.footerTheme,
  }
}

export default connect(mapStateToProps)(FooterLayout2)
