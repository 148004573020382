import axios from 'axios'
import {showMessage} from 'app/store/actions/fuse'
import global from 'app/global-variable' // .
import history from 'history.js'
import _ from '@lodash'

export const GET_COURSE = '[ACADEMY APP] GET COURSE'
export const SAVE_COURSE = '[ACADEMY APP] SAVE COURSE'
export const SAVE_DISCUSSION = '[ACADEMY APP] SAVE DISCUSSION'
export const SAVE_DISCUSSION_RESPONSE = '[ACADEMY APP] SAVE DISCUSSION RESPONSE'
export const SAVE_HELPFUL_RESPONSE = '[ACADEMY APP] SAVE HELPFUL RESPONSE'
export const SAVE_UNHELPFUL_RESPONSE = '[ACADEMY APP] SAVE UNHELPFUL RESPONSE'
export const UPDATE_COURSE = '[ACADEMY APP] UPDATE COURSE'
export const GET_COURSE_BY_SLUG = '[ACADEMY APP] GET COURSE BY SLUG'
export const UPDATE_COURSE_PROGRESS = '[ACADEMY APP] UPDATE COURSE PROGRESS'
export const SAVE_USER_ANSWER = '[ACADEMY APP] SAVE USER ANSWER'
export const SAVE_GRADING_MANUAL = '[ACADEMY APP] SAVE GRADING MANUAL'
export const GET_WIDGETS = '[PROJECT DASHBOARD APP] GET WIDGETS'
export const GET_COMMENTS = '[ACADEMY APP] GET_COMMENTS'
export const GET_COURSE_INSTRUCTOR = '[ACADEMY APP] GET COURSE INSTRUCTOR'
export const GET_INSTRUCTOR_COUSE = '[ACADEMY APP] GET INSTRUCTOR COURSE'
export const GET_PRICE_TIERS = '[ACADEMY APP] GET PRICE TIERS'
export const GET_MEDIA_OBJECT = '[ACADEMY APP] GET MEDIA OBJECT'
export const GET_COURSE_REVIEW = '[ACADEMY APP] GET COURSE REVIEW'
export const GET_INSTRUCTORS = '[ACADEMY APP] GET_INSTRUCTORS'
export const DELETE_DICUSSION_COURSE = '[ACADEMY APP] DELETE DISCUSSION COURSE'
const userData = global.data_user

export function getCourse(params) {
  const request = axios.get('/api/academy-app/course', {params},{
    withCredentials: true
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_COURSE,
        payload: response.data,
      })
    )
}

export function getCourseBySlug(slug) {
  const userData = global.data_user
  //console.log("userData: "+JSON.stringify(global.data_user))

  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  const request = axios.get(global.api.dev + '/academy/course/get/' + slug, {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_BY_SLUG,
        payload: response.data,
      })
    })
}

export function getInstructors() {
  const request = axios.get(global.api.dev + '/academy/instructor/get', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_INSTRUCTORS,
        payload: response.data.data,
      })
    })
}

export function getCourseDiscussion(courseId, page, limit, order) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }
  //console.log(courseId, page, limit)
  const request = axios.get(
    global.api.dev +
      '/academy/course/' +
      courseId +
      // '/discussion?page=' +
      // page +
      // '&limit=' +
      // limit +
      '/discussion?orderBy=' +
      order,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      //console.log('getCourseDiscussion', response.data)
      return dispatch({
        type: GET_COMMENTS,
        payload: response.data,
      })
    })
}

export function getMediaObject(videoId) {
  const request = axios.get(global.jw.get_dev + '/' + videoId, {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_MEDIA_OBJECT,
        payload: response.data,
      })
    )
}

export function getPriceTiers() {
  const request = axios.get(global.api.dev + '/academy/pricing/get', {
    headers: {
      Authorization: 'Bearer ' + global.access_token,
    },
    withCredentials:true,
  })

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_PRICE_TIERS,
        payload: response.data,
      })
    })
}

export function getInstructor(instructorId) {
  const request = axios.get(
    global.api.dev + '/academy/instructor/' + instructorId,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_INSTRUCTOR,
        payload: response.data,
      })
    })
}

export function getInstructorCourse(instructorId) {
  const request = axios.get(
    global.api.dev + '/academy/instructor/' + instructorId + '/course',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    },
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_INSTRUCTOR_COUSE,
        payload: response.data,
      })
    })
}

export function getCourseReview(courseId) {
  const request = axios.get(
    global.api.dev + '/academy/course/' + courseId + '/review',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    },
  )

  return dispatch =>
    request.then(response => {
      return dispatch({
        type: GET_COURSE_REVIEW,
        payload: response.data,
      })
    })
}

export function createUserAnswer(data) {
  const userData = global.data_user

  if (_.isEmpty(userData)) {
    // history.push('/learning')
  }

  console.log('actionData', data)

  const request = axios.post(
    global.api.dev +
      '/academy/lesson/' +
      data.lessonId +
      '/question/' +
      data.questionId +
      '/answer',
    {
      courseId: data.courseId,
      answers: data.answers,
      userId: userData.id,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    },
  )

  return dispatch =>
    request.then(response => {
      // console.log(response.data, response.status)
      dispatch({
        type: SAVE_USER_ANSWER,
        payload: response.data,
      })
    })
}

export function saveGradingManual(data) {
  const request = axios.post(
    global.api.dev +
      '/academy/lesson/' +
      data.lessonId +
      '/question/' +
      data.questionId +
      '/grade',
    {
      grade: data.grade,
      userId: userData.id,
      instructorComment: data.comment,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      console.log(response.data, response.status)
      dispatch({
        type: SAVE_GRADING_MANUAL,
        payload: response.data,
      })
    })
}

export function createDiscussion(data) {
  const userData = global.data_user

  const request = axios.post(
    global.api.dev + '/academy/discussion/create',
    {
      title: data.title,
      lessonId: data.lessonId,
      fulltext: data.fulltext,
      userId: userData.id,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_DISCUSSION,
          payload: response.data,
        })
        dispatch(getCourseDiscussion(data.courseId, 0, 0, 'desc'))
        return dispatch(
          showMessage({message: 'Success to create new discussion.'})
        )
      }

      return response
    })
}

export function createDiscussionResponse(data) {
  const userData = global.data_user

  const request = axios.post(
    global.api.dev + '/academy/discussion/' + data.discussionId + '/response',
    {
      fulltext: data.fulltext,
      userId: userData.id,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_DISCUSSION_RESPONSE,
          payload: response.data,
        })
        dispatch(getCourseDiscussion(data.courseId, 0, 0, 'desc'))
        return dispatch(
          showMessage({message: 'Success to create new discussion response.'})
        )
      }
    })
}

export function createHelpfulDiscussion(data) {
  const request = axios.post(
    global.api.dev + '/academy/discussion/' + data.discussionId + '/helpful/',
    {
      lessonId: data.lessonId,
      userId: userData.id,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status === 200) {
        dispatch(
          showMessage({message: 'Success to mark as a helpful comment.'})
        )

        dispatch(
          getCourseDiscussion(data.courseId, data.commentPage, 10, 'desc')
        )

        dispatch({
          type: SAVE_HELPFUL_RESPONSE,
          payload: response.data,
        })
      }
    })
}

export function createUnhelpfulDiscussion(data) {
  const request = axios.delete(
    global.api.dev + '/academy/discussion/' + data.discussionId + '/unhelpful',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status === 204) {
        dispatch(
          showMessage({
            message: 'Success to mark as an unhelpful discussion.',
          })
        )

        dispatch(
          getCourseDiscussion(data.courseId, data.commentPage, 10, 'desc')
        )
      }

      dispatch({
        type: SAVE_UNHELPFUL_RESPONSE,
        payload: response.data,
      })
    })
}

export function createHelpfulResponse(data) {
  const request = axios.post(
    global.api.dev +
      '/academy/discussion/' +
      data.discussionId +
      '/response/' +
      data.responseId +
      '/helpful',
    {
      lessonId: data.lessonId,
      userId: userData.id,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status === 200) {
        dispatch(
          showMessage({message: 'Success to mark as a helpful response.'})
        )

        dispatch(
          getCourseDiscussion(data.courseId, data.commentPage, 10, 'desc')
        )
      }

      dispatch({
        type: SAVE_HELPFUL_RESPONSE,
        payload: response.data,
      })
    })
}

export function createUnhelpfulResponse(data) {
  const request = axios.delete(
    global.api.dev +
      '/academy/discussion/' +
      data.discussionId +
      '/response/' +
      data.responseId +
      '/helpful/' +
      data.helpfulId +
      '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      //console.log('response', response)
      if (response.status === 204) {
        dispatch(
          showMessage({
            message: 'Success to mark as an unhelpful response.',
          })
        )

        dispatch(
          getCourseDiscussion(data.courseId, data.commentPage, 10, 'desc')
        )
      }

      dispatch({
        type: SAVE_UNHELPFUL_RESPONSE,
        payload: response.data,
      })
    })
}

export function updateCourseProgress(data) {
  const request = axios.post(
    global.api.dev + '/academy/enrollment/' + data.enrollmentId + '/progress',
    {
      courseId: data.courseId,
      lessonId: data.lessonId,
      grade: data.grade,
      status: data.status,
    },
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
        'Content-Type': 'application/json',
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      /* dispatch(showMessage({message: 'Success to mark as a complete lesson.'}))
       setTimeout(function() {
        window.location.reload()
      }, 3000) */

      if (data.slug) {
        dispatch(getCourseBySlug(data.slug))
      }
      return dispatch({
        type: UPDATE_COURSE_PROGRESS,
        payload: response.data,
      })
    })
}

export function updateCourse(data) {
  const request = axios.patch(
    global.api.dev + '/academy/course/' + data.id + '/update/',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Course Updated'}))
      /* setTimeout(function() {
        window.location.href = '/instructor/mycourse'
      }, 2000) */

      return dispatch({
        type: UPDATE_COURSE,
        payload: response.data,
      })
    })
}

//Fuse Demo

export function saveCourse(data) {
  const request = axios.post('/api/academy-app/course/save', data,{
    withCredentials: true
  })

  return dispatch =>
    request.then(response => {
      dispatch(showMessage({message: 'Course Saved'}))

      return dispatch({
        type: SAVE_COURSE,
        payload: response.data,
      })
    })
}

export function getWidgets() {
  const request = axios.get('/api/project-dashboard-app/widgets',{
    withCredentials: true
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_WIDGETS,
        payload: response.data,
      })
    )
}

export function deleteDiscussionCourse(courseId, discussionId) {
  const request = axios.delete(
    global.api.dev +
      '/academy/course/' +
      courseId +
      '/discussion/' +
      discussionId +
      '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status === 200) {
        dispatch(getCourseDiscussion(courseId, 0, 0, 'desc'))
        dispatch(
          showMessage({
            message: 'Delete comment success',
          })
        )
      }
    })
}

export function deleteDiscussionResponseCourse(
  courseId,
  discussionId,
  responseId
) {
  const request = axios.delete(
    global.api.dev +
      '/academy/course/' +
      courseId +
      '/discussion/' +
      discussionId +
      '/responses/' +
      responseId +
      '/remove',
    {
      headers: {
        Authorization: 'Bearer ' + global.access_token,
      },
      withCredentials:true,
    }
  )

  return dispatch =>
    request.then(response => {
      if (response.status === 200) {
        dispatch(
          showMessage({
            message: 'Delete comment success',
          })
        )
      }
    })
}
