import MicroLearningManage from './MicroLearningManage'

export const ManageMicroLearningConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/instructor/micro-learnings/manage/:id',
      component: MicroLearningManage,
    },
  ],
}
