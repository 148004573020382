import gql from 'graphql-tag'

export const SUBMIT_REVIEW_MICROLEARNING = gql`
  mutation($microlearningId: String!, $description: String!, $star: Int!) {
    setMicrolearningReview(
      microlearningId: $microlearningId
      star: $star
      description: $description
    ) {
      success
    }
  }
`
