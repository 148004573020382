import React, {Component} from 'react'
import {
  Icon,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
} from '@material-ui/core'
import {connect} from 'react-redux'
import * as authActions from 'app/auth/store/actions'
import {bindActionCreators} from 'redux'
import {Link, NavLink} from 'react-router-dom'
import _ from '@lodash'
import history from 'history.js'
import global from 'app/global-variable'

class ToolbarMenuSet extends Component {
  state = {
    userMenu: null,
  }

  userMenuClick = event => {
    this.setState({userMenu: event.currentTarget})
  }

  userMenuClose = () => {
    this.setState({userMenu: null})
  }

  componentWillMount() {
    const userData = global.data_user
    if (_.isEmpty(userData)) {
      // history.push('/learning')
    } else {
      //this.getCategories()
    }
  }

  render() {
    const {user, logout} = this.props
    const {userMenu} = this.state

    return (
      <React.Fragment>
        <div className="ml-12 h-64 pt-20 flex flex-1 justify-end">
          <a href={`${global.new_learning_url}`}>
            <Icon color="action" className="mr-36" style={{color: '#014a62'}}>
              home
            </Icon>
          </a>
          <a href={`${global.sosmed_url}/followers/${global.user_id}/`}>
            <Icon color="action" className="mr-36" style={{color: '#014a62'}}>
              group
            </Icon>
          </a>
          {/* <NavLink to="/apps/chat"> */}
          <a href={`${global.chat}`} target="_blank">
            <Icon color="action" className="mr-36" style={{color: '#014a62'}}>
              mail
            </Icon>
          </a>
          {/* </NavLink> */}
          <a href={`${global.sosmed_url}/notification/all`}>
            <Icon color="action" className="mr-24" style={{color: '#014a62'}}>
              notifications
            </Icon>
          </a>
        </div>

        {/*<div className="hidden md:flex flex-col ml-12 items-start">
                    <Typography component="span" className="normal-case font-600 flex">
                        {user.data.displayName}
                    </Typography>
                    <Typography className="text-11 capitalize" color="textSecondary">
                        {user.role}
                    </Typography>
                </div>

                <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
                */}

        <Popover
          open={Boolean(userMenu)}
          anchorEl={userMenu}
          onClose={this.userMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: 'py-8',
          }}
        >
          {user.role === 'guest' ? (
            <React.Fragment>
              <MenuItem component={Link} to="/login">
                <ListItemIcon>
                  <Icon>lock</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="Login" />
              </MenuItem>
              <MenuItem component={Link} to="/register">
                <ListItemIcon>
                  <Icon>person_add</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="Register" />
              </MenuItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MenuItem
                component={Link}
                to="/pages/profile"
                onClick={this.userMenuClose}
              >
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="My Profile" />
              </MenuItem>
              <MenuItem
                component={Link}
                to="/apps/mail"
                onClick={this.userMenuClose}
              >
                <ListItemIcon>
                  <Icon>mail</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="Inbox" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout()
                  this.userMenuClose()
                }}
              >
                <ListItemIcon>
                  <Icon>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="Logout" />
              </MenuItem>
            </React.Fragment>
          )}
        </Popover>
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logoutUser,
    },
    dispatch
  )
}

function mapStateToProps({auth}) {
  return {
    user: auth.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarMenuSet)
