import gql from 'graphql-tag'

export const OVERALL_RATINGS = gql`
  query($userId: String!) {
    instructorRating(userId: $userId) {
      overall_rating
      rating_count
    }
  }
`
