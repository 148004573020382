import React from 'react'
import {Divider, Paper, Typography, Icon} from '@material-ui/core'

const styles = {
  tealFont: {
    color: '#003d51',
  },
}

const iconStyle = {
  fontSize: 180,
  color: '#147718',
}

const DashboardEnrollments = props => {
  const {course} = props

  // const handleDelete = () => {
  //   console.info('You clicked the delete icon.')
  // }

  return (
    <Paper>
      <div className="flex flex-col">
        <Typography
          className="my-20 mx-16 font-bold text-blue-dark text-16"
          style={styles}
        >
          Pendaftaran
        </Typography>
        <Divider className="mx-16" />
        <div className="p-16">
          <Typography className="font-bold text-48 my-12">
            {
              props.location.state.microLearning.microlearning_members_aggregate
                .aggregate.count
            }
          </Typography>
          <div className="w-full flex justify-center">
            <Icon style={iconStyle}>bar_chart</Icon>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default DashboardEnrollments
