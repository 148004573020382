import gql from 'graphql-tag'

export const GET_SOCIAL_LEARNING_BY_OWNER = gql`
  query(
    $isFinished: Boolean
    $isIndividual: Boolean
    $keyword: String
    $price: Int_comparison_exp
    $categoryIds: [Int!]
    $orderBy: [classrooms_order_by!]
    $isNotCrossMentoring: Boolean
    $owner: uuid
  ) {
    classrooms(
      order_by: $orderBy
      where: {
        price: $price
        is_finished: {_eq: $isFinished}
        name: {_ilike: $keyword}
        category_id: {_in: $categoryIds}
        is_individual: {_eq: $isIndividual}
        versioning_id: {_is_null: $isNotCrossMentoring}
        owner_user_id: {_eq: $owner}
      }
    ) {
      id
      avatar_url
      name
      description
      price
      rules
      is_finished
      privacy
      academy_course_category {
        code
        name
      }
      #Classroom Owner
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            # Average Review
            star
          }
        }
      }
    }
  }
`
