import gql from 'graphql-tag'

export const GET_GLOBAL_LANGUAGES = gql`
  query {
    global_languages {
      id
      language_code
      name
      native_name
    }
  }
`

export const GET_SUB_AND_CATEGORIES = gql`
  query {
    academy_course_categories(where: {parent: {_is_null: true}}) {
      id
      name
      code
      subcategory: academy_course_categories {
        id
        name
        code
      }
    }
  }
`

export const GET_COUNT_SLUG = gql`
  query($slug: String!, $id: uuid!) {
    microlearnings_aggregate(where: {id: {_neq: $id}, slug: {_eq: $slug}}) {
      aggregate {
        count
      }
    }
  }
`

export const GET_QUESTIONS_BY_MICROLEARNING_ID = gql`
  query($id: uuid!, $offset: Int, $limit: Int) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      microlearning_questions_aggregate {
        aggregate {
          count
        }
      }
      microlearning_questions(
        offset: $offset
        limit: $limit
        order_by: {date_added: asc_nulls_last}
      ) {
        id
        question
        date_added
        question_type
        microlearning_question_answers {
          answer
          is_correct
        }
      }
    }
  }
`
