import React from 'react'
import EarningChart from './EarningChart'
import RecentTransaction from './RecentTransaction'

const EarningComponent = (props) => {
  return (
    <div>
      <EarningChart className="mb-28" />
      <RecentTransaction />
    </div>
  )
}

export default EarningComponent
