import React, {Component} from 'react'
import {
  withStyles,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Icon,
  Typography,
} from '@material-ui/core'

import withReducer from 'app/store/withReducer'
import {bindActionCreators} from 'redux'
import connect from 'react-redux/es/connect/connect'
import _ from '@lodash'
import {Link, NavLink} from 'react-router-dom'
import * as Actions from '../../store/actions'
import reducer from '../../store/reducers'
import axios from 'axios'
import global from 'app/global-variable'
import {convertToRupiah} from 'app/utils/helpers'
import ReactJWPlayer from 'react-jw-player'
import moment from 'moment'
import LinesEllipsis from 'react-lines-ellipsis'
import {SearchFilter} from './filter'

const styles = (theme) => ({
  header: {
    backgroundImage: 'url("assets/images/logos/learning-catalogue-banner.svg")',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    width: '100%',
  },
  headerIcon: {
    position: 'absolute',
    top: -64,
    left: 0,
    opacity: 0.04,
    fontSize: 512,
    width: 512,
    height: 512,
    pointerEvents: 'none',
  },
  media: {
    height: 140,
  },
  card: {
    height: 300,
    position: 'relative',
  },
  cardExpanded: {
    height: 400,
    position: 'absolute',
    zIndex: 999,
  },
  skyBlueColor: {
    color: '#039be5',
  },
})

class LearningSearch extends Component {
  constructor(props) {
    super(props)

    this.handlePriceMin = this.handlePriceMin.bind(this)
    this.handlePriceMax = this.handlePriceMax.bind(this)
  }

  state = {
    courses: [],
    promoCourses: [],

    userData: global.data_user,
    expanded: false,
    expandedCardContent: null,
    filterByLanguage: [],
    filterByLevel: [],
    filterByType: [],
    filterByTopic: [],
    filterByRatings: [],
    filterByDuration: [],

    filterByPrice: [],
    filterPriceMin: '',
    filterPriceMax: '',
  }

  componentWillMount() {
    this.props.getCart()
    this.props.getMyCourses()
    this.props.getWishlist()
  }

  componentDidMount() {
    this.props.getCategories()
    this.props.getCourses()
    this.props.getCourseExpert(this.props.keyword)
    // this.getCourses();
    // this.getPromoCourses();
    document.title = 'SmartASN - Learning Search'
  }

  getPromoCourses() {
    this.openProgresDialog()
    axios
      .get(global.api.dev + '/academy/course/promo', {})
      .then((response) => {
        const courses = response.data
        this.setState({promoCourses: courses})
        setTimeout(() => this.closeProgresDialog(), 2000)
      })
      .catch((error) => {
        this.setState({progressMessage: error})
        //console.error("error: "+JSON.stringify(error))
        setTimeout(() => this.closeProgresDialog(), 2000)
      })
  }

  getCourses() {
    this.openProgresDialog()
    axios
      .get(global.api.dev + '/academy/course/get', {})
      .then((response) => {
        console.log('getCourses: ' + JSON.stringify(response))
        const courses = response.data
        this.setState({courses: courses})
        setTimeout(() => this.closeProgresDialog(), 2000)
      })
      .catch((error) => {
        this.setState({progressMessage: error})
        //console.error("error: "+JSON.stringify(error))
        setTimeout(() => this.closeProgresDialog(), 2000)
      })
  }

  openProgresDialog() {
    this.setState({progressMessage: 'Mohon tunggu sedang diproses...'})
    this.setState({progressDialog: true})
  }

  closeProgresDialog() {
    this.setState({progressDialog: false})
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(this.props.courses, prevProps.courses) ||
      !_.isEqual(this.props.searchText, prevProps.searchText) ||
      !_.isEqual(this.props.categoryFilter, prevProps.categoryFilter)
    ) {
      const data = this.getFilteredArray(
        this.props.courses,
        this.props.searchText,
        this.props.categoryFilter
      )
      this.setState({data})
    }
  }

  getFilteredArray = (data, searchText, categoryFilter) => {
    if (searchText.length === 0 && categoryFilter === 'all') {
      return data
    }

    return _.filter(data, (item) => {
      if (categoryFilter !== 'all' && item.category !== categoryFilter) {
        return false
      }
      return item.title.toLowerCase().includes(searchText.toLowerCase())
    })
  }

  filterOnLevel(level) {
    const levels = this.state.filterLevel
    const setLevel = levels.find(function (l) {
      return l.level === level
    })
    setLevel.checked = !setLevel.checked
    this.setState({filterLevel: levels})
  }

  filterOnLang(lang) {
    const langs = this.state.filterLang
    const setLang = langs.find(function (l) {
      return l.lang === lang
    })
    setLang.checked = !setLang.checked
    this.setState({filterLang: langs})
  }

  handlePriceMin(e, max) {
    this.setState({filterPriceMin: e.target.value})
    // this.filterOnPrice('paid', true, e.target.value, max)
    // this.filterOnPrice()
  }

  handlePriceMax(e, min) {
    this.setState({filterPriceMax: e.target.value})
    // this.filterOnPrice('paid', true, min, e.target.value)
  }

  filterOnPrice(price, isCheck, min, max) {
    const prices = this.state.filterPrice
    const setPrice = prices.find((p) => {
      return p.price === price
    })
    setPrice.checked = isCheck ? isCheck : !setPrice.checked
    this.setState({filterPrice: prices})
  }

  filterOnRating(rating) {
    const ratings = this.state.filterRating
    const setRating = ratings.find(function (r) {
      return r.rating === rating
    })
    setRating.checked = !setRating.checked
    this.setState({filterRating: ratings})
    const filtered = this.props.courseExperts.filter((course) => {
      for (let rating of ratings) {
        if (rating.checked) {
          if (
            course.averageRating.toFixed(1) >= rating.rating &&
            course.averageRating.toFixed(1) <= rating.max
          )
            return true
        }
      }
      return false
    })
    console.log(filtered)
  }

  showDetail = (e, courseId) => {
    if (this.state.expandedCardContent !== courseId) {
      this.setState({
        expandedCardContent: courseId,
      })
    } else {
      console.log('Course ', courseId, ' aleardy expanded')
    }
  }

  hideDetail = () => {
    this.setState({
      expandedCardContent: null,
    })
  }

  handlerChangeType = (e) => {
    let _filterByType = this.state.filterByType
    let removedType = _filterByType.filter(
      (byType) => String(byType) !== String(e.target.value)
    )

    _filterByType.includes(e.target.value)
      ? (_filterByType = removedType)
      : _filterByType.push(e.target.value)

    return this.setState({
      filterByType: _filterByType,
    })
  }

  handlerChangeTopic = (e) => {
    let _filterByTopic = this.state.filterByTopic
    let removedTopic = _filterByTopic.filter(
      (byType) => String(byType) !== String(e.target.value)
    )

    _filterByTopic.includes(e.target.value)
      ? (_filterByTopic = removedTopic)
      : _filterByTopic.push(e.target.value)

    return this.setState({
      filterByTopic: _filterByTopic,
    })
  }

  handlerChangeLevel = (e) => {
    let _filterByLevel = this.state.filterByLevel
    let removedLevel = _filterByLevel.filter(
      (byLevel) => String(byLevel) !== String(e.target.value)
    )

    _filterByLevel.includes(e.target.value)
      ? (_filterByLevel = removedLevel)
      : _filterByLevel.push(e.target.value)

    return this.setState({
      filterByLevel: _filterByLevel,
    })
  }

  handlerChangeLanguage = (e) => {
    let _filterByLanguage = this.state.filterByLanguage
    let removedLanguage = _filterByLanguage.filter(
      (byLanguage) => byLanguage !== e.target.value
    )

    _filterByLanguage.includes(e.target.value)
      ? (_filterByLanguage = removedLanguage)
      : _filterByLanguage.push(e.target.value)

    return this.setState({
      filterByLanguage: _filterByLanguage,
    })
  }

  handlerChangePrice = (e) => {
    const value = e.target.value === '0' ? 'free' : 'paid'
    let _filterByPrice = this.state.filterByPrice
    let removedPrice = _filterByPrice.filter((byPrice) => byPrice !== value)

    _filterByPrice.includes(value)
      ? (_filterByPrice = removedPrice)
      : _filterByPrice.push(value)

    return this.setState({
      filterByPrice: _filterByPrice,
    })
  }

  handlerChangeRatings = (e) => {
    let _filterByRatings = [...this.state.filterByRatings]
    const value = Number(e.target.value)
    let removedRatings = _filterByRatings.filter(
      (byRatings) => Number(byRatings) !== value
    )

    _filterByRatings.includes(value)
      ? (_filterByRatings = removedRatings)
      : _filterByRatings.push(value)

    const converted = _filterByRatings.map(Number)

    return this.setState({filterByRatings: converted})
  }

  handlerChangeDuration = (e) => {
    let _filterByDuration = [...this.state.filterByDuration]
    let removedDuration = _filterByDuration.filter(
      (byDuration) => String(byDuration) !== String(e.target.value)
    )

    _filterByDuration.includes(e.target.value)
      ? (_filterByDuration = removedDuration)
      : _filterByDuration.push(e.target.value)

    return this.setState({
      filterByDuration: _filterByDuration,
    })
  }

  render() {
    const {
      classes,
      courseExperts = [],
      cart,
      wishlist,
      myCourses,
      keyword,
      categories,
      searchGlobalValue,
      location,
      searchText,
    } = this.props
    const {
      filterByLanguage,
      filterByLevel,
      filterByType,
      filterByPrice,
      filterByTopic,
      filterByRatings,
      filterByDuration,
      filterPriceMin,
      filterPriceMax,
      userData,
    } = this.state

    const durationCheck = (course) => {
      const convertedCourseSec = Number(course.durationSeconds) / 60
      const courseMinute = convertedCourseSec.toFixed(0)
      const selectedDurations = []

      filterByDuration.forEach((item) => {
        const minute = Number(item) * 60
        const obj = {}
        switch (minute) {
          case 120:
            obj.min = 0
            obj.max = minute
            break
          case 360:
            obj.min = 121
            obj.max = minute
            break
          case 960:
            obj.min = 361
            obj.max = minute
            break
          case 1020:
            obj.min = 961
            break
          default:
            obj = {}
        }
        selectedDurations.push(obj)
      })
      let result = false

      selectedDurations.forEach((item) => {
        if (!item.max && item.min <= courseMinute) result = true
        if (item.min <= courseMinute && item.max >= courseMinute) {
          result = true
        }
      })

      if (selectedDurations.length === 0) return course
      return result
    }

    const courseExpertFilter = courseExperts
      .filter((course) =>
        filterByType.length > 0
          ? filterByType.includes(String(course.type))
          : course
      )
      .filter((course) =>
        filterByTopic.length > 0
          ? filterByTopic.includes(String(course.category))
          : course
      )
      .filter((course) =>
        filterByLevel.length > 0
          ? filterByLevel.includes(String(course.level))
          : course
      )
      .filter((course) =>
        filterByLanguage.length > 0
          ? filterByLanguage.includes(String(course.language))
          : course
      )
      .filter((course) =>
        filterByPrice.length > 0
          ? filterByPrice.includes('paid')
            ? course.Price.name.toLowerCase() !== 'free'
            : course.Price.name.toLowerCase() === 'free'
          : course
      )
      .filter((course) =>
        filterPriceMin !== ''
          ? Number(course.Price.pricing.IDR) >= Number(filterPriceMin)
          : course
      )
      .filter((course) =>
        filterPriceMax !== ''
          ? Number(course.Price.pricing.IDR) <= Number(filterPriceMax)
          : course
      )
      .filter((course) =>
        filterByRatings.length > 0
          ? Number(course.averageRating) >= Math.min(...filterByRatings)
          : course
      )
      .filter(durationCheck)

    if (!courseExperts) {
      return <CircularProgress />
    }

    const foundDataLength = (panel, id) => {
      const courseNumbers = courseExperts.filter((course) => {
        if (panel === 'category') {
          return course.type === id
        }
        if (panel === 'topic') {
          return course.category === id
        }
        if (panel === 'price') {
          if (id === 'free') return course.Price.name.toLowerCase() === id
          else return course.Price.name.toLowerCase() !== 'free'
        }
        if (panel === 'ratings') {
          return Number(course.averageRating) >= id
        } else {
          return course[panel] === id
        }
      })
      return courseNumbers.length
    }

    const categoryOptions = [
      {
        label: 'Course',
        value: 1,
        foundLength: foundDataLength('category', 1),
      },
      {
        label: 'Practice Test',
        value: 2,
        foundLength: foundDataLength('category', 2),
      },
    ]

    const topicOptions = Array.isArray(categories)
      ? categories.map((category) => {
          return {
            label: category.name,
            value: category.id,
            foundLength: foundDataLength('topic', category.id),
          }
        })
      : []

    const levelOptions = [
      {
        label: 'Beginner',
        value: 1,
        foundLength: foundDataLength('level', 1),
      },
      {
        label: 'Intermediate',
        value: 2,
        foundLength: foundDataLength('level', 2),
      },
      {
        label: 'Advance',
        value: 3,
        foundLength: foundDataLength('level', 3),
      },
    ]

    const ratingOptions = [
      {
        label: '4.5 & up',
        value: 4.5,
        foundLength: foundDataLength('ratings', 4.5),
      },
      {
        label: '4.0 & up',
        value: 4.0,
        foundLength: foundDataLength('ratings', 4.0),
      },
      {
        label: '3.0 & up',
        value: 3.0,
        foundLength: foundDataLength('ratings', 3.0),
      },
      {
        label: '2.0 & up',
        value: 2.0,
        foundLength: foundDataLength('ratings', 2.0),
      },
      {
        label: '1.0 & up',
        value: 1.0,
        foundLength: foundDataLength('ratings', 1.0),
      },
    ]

    const languageOptions = [
      {
        label: 'English',
        value: 'eng',
        foundLength: foundDataLength('language', 'eng'),
      },
      {
        label: 'Bahasa Indonesia',
        value: 'ind',
        foundLength: foundDataLength('language', 'ind'),
      },
    ]

    const priceOptions = [
      {
        label: 'Free',
        value: 0,
        foundLength: foundDataLength('price', 'free'),
      },
      {
        label: 'Paid',
        value: 1,
        foundLength: foundDataLength('price', 'paid'),
      },
    ]

    const durationOptions = [
      {
        label: '0-2 hours',
        value: 2,
        foundLength: foundDataLength('duration', 2),
      },
      {
        label: '>2-6 hours',
        value: 6,
        foundLength: foundDataLength('duration', 6),
      },
      {
        label: '>6-16 hours',
        value: 16,
        foundLength: foundDataLength('duration', 16),
      },
      {
        label: '>16',
        value: 17,
        foundLength: foundDataLength('duration', 17),
      },
    ]

    return (
      <div className="w-full">
        <div className="flex flex-1 flex-row">
          <div className="w-1/4 mx-auto pl-8 pr-4 sm:pl-32 sm:pl:18 py-24">
            <SearchFilter
              categories={categoryOptions}
              topics={topicOptions}
              levels={levelOptions}
              languages={languageOptions}
              ratings={ratingOptions}
              prices={priceOptions}
              durations={durationOptions}
              onChange={{
                category: this.handlerChangeType,
                topic: this.handlerChangeTopic,
                level: this.handlerChangeLevel,
                language: this.handlerChangeLanguage,
                price: this.handlerChangePrice,
                priceMin: this.handlePriceMin,
                priceMax: this.handlePriceMax,
                ratings: this.handlerChangeRatings,
                duration: this.handlerChangeDuration,
              }}
              value={{
                filterByPrice: this.state.filterByPrice,
                filterPriceMin: this.state.filterPriceMin,
                filterPriceMax: this.state.filterPriceMax,
              }}
            />
          </div>
          <div className="w-3/4 mx-auto px-8 sm:px-16 py-24">
            <div className="max-w-2xl w-full mx-auto px-8 sm:px-16 py-8">
              <div className="flex flex-1 flex-row">
                <Typography variant="body1">
                  {courseExpertFilter.length} Result for "{searchGlobalValue}"
                </Typography>
              </div>
            </div>
            <div
              enter={{
                animation: 'transition.slideUpBigIn',
              }}
              className="flex flex-wrap py-20"
            >
              {courseExpertFilter.length === 0 && (
                <div className="flex flex-1 items-center justify-center">
                  <Typography color="textSecondary" className="text-24 my-24">
                    No courses found!
                  </Typography>
                </div>
              )}
              {courseExperts &&
                courseExpertFilter.map((course) => {
                  return (
                    <div
                      className="w-full pb-24 sm:w-1/2 lg:w-1/4 sm:p-16"
                      key={course.id}
                    >
                      <Card
                        className={
                          this.state.expandedCardContent ===
                          'trending-' + course.id
                            ? classes.cardExpanded
                            : classes.card
                        }
                      >
                        {/* onMouseEnter={e => this.showDetail(e, 'trending-' + course.id)}
                            onMouseLeave={e => this.hideDetail(e)} */}
                        {this.state.expandedCardContent !==
                          'trending-' + course.id && (
                          <CardMedia
                            className={classes.media}
                            image={course.thumbnail}
                            title={course.slug}
                          />
                        )}

                        {this.state.expandedCardContent ===
                        'trending-' + course.id
                          ? course.video
                            ? this.state.medias.some(
                                (media) => media.id === course.video
                              ) === false
                              ? (function (course, context) {
                                  context
                                    .getVideoCourse(course.video)
                                    .then(() => {
                                      return (
                                        <ReactJWPlayer
                                          playerId={context.state.playerId}
                                          playerScript={global.jw.player_id}
                                          playlist={context.state.playlist}
                                          className={classes.media}
                                        />
                                      )
                                    })
                                })(course, this)
                              : (function (course, context) {
                                  return (
                                    <ReactJWPlayer
                                      playerId={context.state.playerId}
                                      playerScript={global.jw.player_id}
                                      playlist={
                                        context.state.medias.filter(
                                          (media) => media.id === course.video
                                        )[0].media.url.playlist
                                      }
                                      className={classes.media}
                                    />
                                  )
                                })(course, this)
                            : (function (course, context) {
                                //context.getVideoCourse(course.video);
                                return (
                                  <CardMedia
                                    className={classes.media}
                                    image={course.thumbnail}
                                    title={course.slug}
                                  />
                                )
                              })(course, this)
                          : ''}

                        <CardContent>
                          {this.state.expandedCardContent ===
                            'trending-' + course.id && (
                            <Typography
                              className="text-12 font-400 mt-4"
                              color="textSecondary"
                            >
                              Last update:{' '}
                              {moment(
                                this.state.selectedCourse.lastModified
                              ).format('l')}
                            </Typography>
                          )}
                          <Link to={'/learning_course/' + course.slug}>
                            {this.state.expandedCardContent !==
                            'trending-' + course.id ? (
                              <Typography className="text-16 font-400 mt-16">
                                {(function (course) {
                                  let course_title = course.title
                                  let course_title_splitter = course_title.split(
                                    ' '
                                  )
                                  return (
                                    <LinesEllipsis
                                      text={
                                        course_title_splitter[0] +
                                        ' ' +
                                        course_title_splitter[1]
                                      }
                                      maxLine="2"
                                      ellipsis="..."
                                      trimRight
                                      basedOn="letters"
                                    />
                                  )
                                })(course)}
                              </Typography>
                            ) : (
                              <Typography className="text-16 font-400 mt-16">
                                {course.title}
                              </Typography>
                            )}
                          </Link>
                          {this.state.expandedCardContent !==
                          'trending-' + course.id ? (
                            <Typography
                              className="text-13 font-600 mt-4"
                              color="textSecondary"
                            >
                              {course.CreatorUser
                                ? course.CreatorUser.name
                                : 'author unknown'}
                            </Typography>
                          ) : (
                            <div className="flex flex-1 flex-row mt-16">
                              <Icon className="text-12">
                                play_circle_filled
                              </Icon>
                              <Typography
                                className="text-10 font-400 ml-4"
                                color="textSecondary"
                              >
                                {course.countLesson > 1
                                  ? course.countLesson + ' lectures'
                                  : course.countLesson + ' lecture'}
                              </Typography>
                              <Icon className="ml-8 text-12">access_time</Icon>
                              <Typography
                                className="text-10 font-400 ml-4"
                                color="textSecondary"
                              >
                                {course.estimatedDuration
                                  ? course.estimatedDuration.substr(
                                      1,
                                      course.estimatedDuration.length
                                    )
                                  : '-'}
                              </Typography>
                              <Icon className="ml-8 text-12">tune</Icon>
                              <Typography
                                className="text-10 font-400 ml-4"
                                color="textSecondary"
                              >
                                {course.level === 1
                                  ? 'Beginner'.substr(0, 6) + '...'
                                  : course.level === 2
                                  ? 'Intermediate'.substr(0, 6) + '...'
                                  : 'Advanced'.substr(0, 6) + '...'}
                              </Typography>
                              <Icon className="ml-8 text-12">
                                closed_caption
                              </Icon>
                            </div>
                          )}

                          {this.state.expandedCardContent !==
                            'trending-' + course.id && (
                            <div className="flex flex-1 flex-row">
                              {(function (course) {
                                let starArray = []
                                const fullStars = parseInt(course.averageRating)
                                for (let i = 0; i < fullStars; i++) {
                                  starArray.push('star')
                                }

                                const halfStars =
                                  course.averageRating % 1 > 0 ? 1 : 0
                                if (halfStars) {
                                  starArray.push('star_half')
                                }

                                for (
                                  let i = 0;
                                  i < 5 - (fullStars + halfStars);
                                  i++
                                ) {
                                  starArray.push('star_border')
                                }

                                return (
                                  <div
                                    className={
                                      'flex flex-1 flex-row content-start justify-start items-center mt-16'
                                    }
                                  >
                                    {starArray.map((star, index) => {
                                      return (
                                        <Icon
                                          key={index}
                                          className={classes.skyBlueColor}
                                        >
                                          {star}
                                        </Icon>
                                      )
                                    })}
                                  </div>
                                )
                              })(course)}

                              <div className="flex flex-1 flex-row content-end justify-end items-center mt-16">
                                <Typography variant="body1" fontWeight={600}>
                                  &nbsp;
                                  <b>{course.averageRating.toFixed(2)}</b>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="text-grey-darker"
                                >
                                  &nbsp;({course.countEnrollment})
                                </Typography>
                              </div>
                            </div>
                          )}
                          {this.state.expandedCardContent !==
                            'trending-' + course.id && (
                            <div className="flex flex-row mt-8 mb-12">
                              <Typography
                                className="text-16 font-400"
                                variant="h6"
                              >
                                <b>
                                  {course.Price.pricing.IDR > 0
                                    ? convertToRupiah(course.Price.pricing.IDR)
                                    : 'FREE'}
                                </b>
                              </Typography>
                            </div>
                          )}
                          <div
                            style={
                              this.state.expandedCardContent ===
                              'trending-' + course.id
                                ? {height: 400}
                                : {height: 300}
                            }
                          >
                            <Typography
                              className="text-16 font-400 mt-16"
                              color="textSecondary"
                            >
                              {course.subTitle}
                            </Typography>
                            <ul className="mt-8">
                              {course.goals &&
                                course.goals.map(function (goal, index) {
                                  return (
                                    <li key={index}>
                                      <Typography
                                        className="text-12 font-400"
                                        color="textSecondary"
                                      >
                                        {goal}
                                      </Typography>
                                    </li>
                                  )
                                })}
                            </ul>
                            <div className="flex flex-1 flex-row mt-12">
                              {(function (course, context) {
                                const carts = cart.filter((item) => {
                                  return item.courseId === course.id
                                })

                                const myCourse = myCourses.filter((item) => {
                                  let Course = {}

                                  //temporary hack for dev server/local difference in response
                                  if (item.Course) {
                                    Course = item.Course
                                  } else {
                                    Course = item.AcademyCourse
                                  }

                                  return Course.id === course.id
                                })

                                if (course.CreatorUser.id === userData.id) {
                                  //yang memiliki course/instruktur dalam course
                                  return (
                                    <div
                                      className={
                                        'flex flex-1 flex-col items-center justify-center'
                                      }
                                    >
                                      <NavLink
                                        to={'/learning_course/' + course.slug}
                                      >
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          color="primary"
                                        >
                                          View Course
                                        </Button>
                                      </NavLink>
                                    </div>
                                  )
                                } else if (myCourse.length > 0) {
                                  return (
                                    <div
                                      className={
                                        'flex flex-1 flex-col items-center justify-center'
                                      }
                                    >
                                      <NavLink
                                        to={
                                          '/learning_my_course/' + course.slug
                                        }
                                      >
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          className={classes.blueButton}
                                        >
                                          Continue Course
                                        </Button>
                                      </NavLink>
                                    </div>
                                  )
                                } else if (carts.length > 0) {
                                  return (
                                    <div
                                      className={
                                        'flex flex-1 flex-col items-center justify-center'
                                      }
                                    >
                                      <NavLink to="/learning_cart">
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          className={classes.blueButton}
                                        >
                                          Go to Cart
                                        </Button>
                                      </NavLink>
                                    </div>
                                  )
                                } else {
                                  return (
                                    <div
                                      className={
                                        'flex flex-1 flex-col items-start justify-start'
                                      }
                                    >
                                      <Button
                                        variant="contained"
                                        size="medium"
                                        className={classes.blueButton}
                                        onClick={() =>
                                          context.props.addToCart(course.id)
                                        }
                                      >
                                        Add to cart
                                      </Button>
                                    </div>
                                  )
                                }
                              })(course, this)}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: Actions.getGlobalCategories,
      getCourses: Actions.getCourses,
      setCategoryFilter: Actions.setCategoryFilter,
      setSearchText: Actions.setCoursesSearchText,
      getCourseExpert: Actions.getCourseExpert,
      getCart: Actions.getCart,
      getWishlist: Actions.getWishlist,
      getMyCourses: Actions.getMyCourses,
      searchGlobal: Actions.searchGlobalValue,
    },
    dispatch
  )
}

function mapStateToProps({globalSearch}) {
  return {
    cart: globalSearch.courses.cart,
    wishlist: globalSearch.courses.wishlist,
    myCourses: globalSearch.courses.myCourses,
    courses: globalSearch.courses.data,
    searchText: globalSearch.courses.searchText,
    categories: globalSearch.category.categories,
    categoryFilter: globalSearch.courses.categoryFilter,
    courseExperts: globalSearch.courses.courseExperts,
    searchGlobalValue: globalSearch.courses.searchGlobalValue,
  }
}

export default withReducer(
  'globalSearch',
  reducer
)(
  withStyles(styles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(LearningSearch)
  )
)
