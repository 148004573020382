//import '@fake-db'
import {ApolloProvider} from '@apollo/react-hooks'
import React from 'react'
import AppContainer from './AppContainer'
import ErrorBoundary from './ErrorBoundary'
import client from './graphql/connector'

import 'moment/locale/id'

const App = () => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <AppContainer />
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default App
