import gql from 'graphql-tag'

export const UPDATE_SCHEDULE_MICROLEARNING = gql`
  mutation(
    $userId: uuid
    $microlearningId: uuid
    $dailyQuestion: Int
    $schedule: String
    $scheduleTime: time
  ) {
    update_microlearning_members(
      where: {
        user_id: {_eq: $userId}
        microlearning_id: {_eq: $microlearningId}
      }
      _set: {
        daily_question: $dailyQuestion
        schedule: $schedule
        schedule_time: $scheduleTime
      }
    ) {
      affected_rows
    }
  }
`
