import './styles/index.css'
import 'babel-polyfill'
import 'typeface-muli'
import './react-table-defaults'
import './react-chartjs-2-defaults'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './app/App'
import * as Sentry from '@sentry/browser'
import global, {userData} from './app/global-variable'
import './styles/customCKEditor.css'
import {getCookie} from 'app/utils/helpers'

require('dotenv').config()

Sentry.init({
  dsn: global.sentry_url,
  environment: global.sentry_env,
})

Sentry.configureScope(scope => scope.setExtra('userData', userData))

if (process.env.NODE_ENV !== 'development' && !getCookie('userData')) {
  window.location = global.sso_url
} else {
  ReactDOM.render(<App />, document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
