import {FuseLoadable} from '@fuse'

export const Login2PageConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
          folded: false,
          position: 'left',
        },
        toolbar: {
          display: false,
          style: 'fixed',
          position: 'below',
        },
        footer: {
          display: false,
          style: 'fixed',
          position: 'below',
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    //src/app/main/learnings/dashboard/ShareCourse.js
    {
      path: '/pages/auth/login',
      component: FuseLoadable({
        loader: () => import('./Login2Page'),
      }),
    },
  ],
}
